import React, {ReactNode} from "react";
import PerfectScrollbar from "perfect-scrollbar";
import ScreenSidebar from "../../components/screen/ScreenSidebar";
import ScreenHeader from "../../components/screen/ScreenHeader";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import AppInfoReducer from "../../components/screen/AppInfoReducer";
import {connect} from "react-redux";
import LoadingView from "../../base/loading/LoadingView";
import AuthManager from "../../utils/AuthManager";
import {RouteComponentProps, withRouter} from "react-router";
import ActionReducer from "./ActionReducer";
import ActionActions from "./ActionActions";
import TaskGetAppInfo from "../../ws/app/TaskGetAppInfo";
import {Action, Entity} from "../../model/AppInfo";
import EntityActions from "../Entity/EntityActions";
import RelatedEntities from "../Entity/List/RelatedEntities";
import ActionForm from "./ActionForm";
import TaskGetContentAction from "../../ws/action/TaskGetContentAction";
import { CustomAction } from '../../model/CustomAction';

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(ActionReducer.autoMapToProps(), AppInfoReducer.autoMapToProps());

interface ActionProps {
    action: string,
}

type ActionScreenProps = {
    customActions?: CustomAction[]
}

type Props = RouteComponentProps<ActionProps> & typeof mapStateToProps & ActionScreenProps;

let ps;

class ActionScreen extends React.Component<Props> {

    public state = {waiting: true, canRender: false, error: '', notfound: false, path: '', contentScreen: '', errorContentScreen: ''};

    public readonly mainPanel;

    constructor(props) {
        super(props);
        this.mainPanel = React.createRef();
    }

    public componentWillMount(): void {
        if (!AuthManager.isLogged()) {
            this.props.history.push('/');
        }
        else if (!this.props.appInfo  || !this.props.appInfo.actions || this.props.appInfo.actions.length === 0) {
            new TaskGetAppInfo()
                .onSuccess((result: any) => {
                    this.setCurrentAction(result.actions);
                    this.setState({canRender: true});
                    document.title = result.name;
                })
                .onFail((error) => {
                    this.setState({error})
                })
                .execute();
        }
        else {
            this.setCurrentAction(this.props.appInfo.actions);
        }
    }

    public setCurrentAction(actions: Action[]): void {
        if (this.props.match.params.action === this.props.currentAction.name) {
            this.setState({waiting: false, canRender: true});
        }
        else {
            const currentAction = actions.find((action) => action.slug === this.props.match.params.action);
            if (currentAction) {
                ActionActions.setCurrentAction.asConnectedAction(currentAction);
                EntityActions.setCurrentEntity.asConnectedAction({} as Entity);
                this.setContentScreen(currentAction);
                this.setState({waiting: false, canRender: true});
            } else {
                this.setState({canRender: false, notfound: true});
            }
        }
    }

    public setContentScreen(currentAction: Action) {
        if (currentAction.urlGetContent) {
            new TaskGetContentAction(currentAction.urlGetContent)
                .onSuccess((result) => {this.setState({contentScreen: result})})
                .onFail((result) => {this.setState({errorContentScreen: result})})
                .execute();
        }
        else {
            this.setState({contentScreen: '', errorContentScreen: ''});
        }
    }

    public componentWillUnmount() {
        if (ps && navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.body.classList.toggle("perfect-scrollbar-on");
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            this.props.history.location.pathname &&
            this.props.history.location.pathname !== this.state.path &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            this.mainPanel.current.scrollTop = 0;
            this.setState({path: this.props.history.location.pathname})
        }
        if (this.props.match.params.action !== prevProps.match.params.action) {
            this.setCurrentAction(this.props.appInfo.actions);
        }

        if (this.state.canRender && !ps && this.mainPanel.current) {
            if (navigator.platform.indexOf("Win") > -1) {
                ps = new PerfectScrollbar(this.mainPanel.current);
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        }
    }

    public getActionForm(): ReactNode {
        if (this.props.currentAction.urlSubmitForm) {
            return (
                <ActionForm
                    action={this.props.currentAction}
                    primaryColor={this.props.appInfo.appStyles.primaryColor}
                    loading={this.props.loadingAction}
                />
            );
        }
        return <></>;
    }

    public getContentScreen(): ReactNode {
        if (this.state.contentScreen) {
            return <div className="content_screen" dangerouslySetInnerHTML={{__html: this.state.contentScreen}}/>
        }
        else if (this.state.errorContentScreen) {
            return (
                <div className='col-md-12'>
                    <div className="alert alert-danger fade show">{this.state.errorContentScreen}</div>
                </div>
            );
        }
        return <></>;
    }

    public getCustomView(): ReactNode {
        const customAction = this.props.customActions
            && this.props.customActions
                .find((customAction) => this.props.currentAction.name === customAction.name)
        if (customAction) {
            return customAction.element;
        }
        return null;
    }


    public render(): ReactNode {
        if (this.state.notfound) {
            this.props.history.push('/');
        }

        const {currentAction} = this.props;
        return (
            <div className="wrapper">
                {
                    !this.state.canRender || !currentAction.fields || !this.props.appInfo.appStyles ?
                        <LoadingView loading={true}/> :
                        <>
                            <ScreenSidebar/>
                            <div className="main-panel" ref={this.mainPanel}>
                                <ScreenHeader title={currentAction.label}  buttonCreate={false} buttonImport={false}/>
                                <LoadingView loading={this.props.loadingContentScreen}/>
                                <div className="content">
                                    <RelatedEntities/>
                                    {this.getContentScreen()}
                                    {this.getActionForm()}
                                    {this.getCustomView()}
                                </div>
                            </div>
                        </>
                }
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(ActionScreen) as any) as React.ComponentType<ActionScreenProps>;
