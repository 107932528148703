import * as React from 'react';
import {Component, ReactNode} from 'react';
import {ClipLoader} from "react-spinners";
import AppInfoReducer from "../../components/screen/AppInfoReducer";
import {connect} from "react-redux";

export interface LoadingProps {
    loading: boolean;
}

const mapStateToProps = AppInfoReducer.autoMapToProps();

class LoadingView extends Component<LoadingProps & typeof mapStateToProps> {

    public render(): ReactNode {
        if (!this.props.loading) {
            return null;
        }
        return (
            <div className="loadingView">
                <div className={'align-middle text-costcenter'}>
                    <ClipLoader
                        sizeUnit={"px"}
                        size={50}
                        color={
                            this.props.appInfo && this.props.appInfo.appStyles && this.props.appInfo.appStyles.loadingColor ?
                                this.props.appInfo.appStyles.loadingColor
                                : '#000'
                        }
                        loading={this.props.loading}
                    />
                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps)(LoadingView) as unknown as React.ComponentType<LoadingProps>
