import React, {ReactNode} from "react";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_BUTTON_DELETE,
    TR_BUTTON_EDIT,
    TR_BUTTON_VIEW,
} from "../../../I18n/constants";
import '@fortawesome/fontawesome-free/css/all.css'
import {Link} from "react-router-dom";
import {generateRoute} from "../../../utils/Router";
import {ROUTE_ENTITY_EDIT, ROUTE_ENTITY_VIEW} from "../../../routing/Routes";
import ShowField from "../../../components/field/ShowField";
import {StructureEntity} from "../../../model/StructureEntity";
import {ResultsListField} from "../../../model/ResultsList";
import EntityActions from "../EntityActions";

interface GalleryProps {
    structure: StructureEntity,
    results: ResultsListField[],
    iconsColor: string,
    entity: string,
    galleryView: boolean,
    galleryViewPaddingTop: number,
    galleryViewBackgroundSize: string,
    galleryViewColumnClasses: string
}

class Gallery extends React.Component<GalleryProps> {

    public renderContentGallery(): ReactNode {
        const data = this.props.results;
        const structureList = this.props.structure.list;
        const fieldImageInGallery = Object.keys(structureList).filter((column) => structureList[column].showInGalleryView && structureList[column].type === 'IMAGE');

        if (fieldImageInGallery) {
            const fieldsInGallery = Object.keys(structureList).filter((column) => structureList[column].showInGalleryView && structureList[column].type !== 'IMAGE');

            return Object.keys(data).map((indexData) => {
                return (
                    <div className={`mb-4 ${this.props.galleryViewColumnClasses}`} key={indexData}>
                        <div className="shadow p-3">
                            <Link to={generateRoute(ROUTE_ENTITY_VIEW, {entity: this.props.entity.toLowerCase(), id: data[indexData].oid})} title={I18nUtils.tr(TR_BUTTON_VIEW)}>
                                <div className="image-gallery" style={{backgroundSize: this.props.galleryViewBackgroundSize, paddingTop: this.props.galleryViewPaddingTop + '%', backgroundImage: `url('${data[indexData][fieldImageInGallery[0]]}')`}}/>
                            </Link>
                            {
                                fieldsInGallery.map((field) => {
                                    const fieldValue = data[indexData][field] ? data[indexData][field] : '';
                                    return (
                                        <p key={field + indexData}>
                                            {ShowField(fieldValue, this.props.structure.list[field].type, this.props.structure.list[field].fieldsToShow)}
                                        </p>
                                    )
                                })
                            }
                            {this.renderActionsColumn(data[indexData])}
                        </div>
                    </div>
                )
            });
        }
        return <></>;
    }

    public renderActionsColumn(row: any): ReactNode {

        const id = row.oid;
        const color = this.props.iconsColor;

        return (
            <div className='actions'>
                <Link to={generateRoute(ROUTE_ENTITY_VIEW, {entity: this.props.entity.toLowerCase(), id})} title={I18nUtils.tr(TR_BUTTON_VIEW)}>
                    <i className='fa fa-eye' style={{color}}/>
                </Link>
                {
                    this.props.structure.moreInfo.canEdit ?
                        <Link to={generateRoute(ROUTE_ENTITY_EDIT, {entity: this.props.entity.toLowerCase(), id})} title={I18nUtils.tr(TR_BUTTON_EDIT)}>
                            <i className='fa fa-edit' style={{color}}/>
                        </Link> : <></>
                }
                {
                    this.props.structure.moreInfo.canDelete ?
                        <i className='fa fa-trash' style={{color}} onClick={() => EntityActions.showDeleteModal.asConnectedAction(this.props.entity, id)} title={I18nUtils.tr(TR_BUTTON_DELETE)}/>
                        : <></>
                }

            </div>
        );

    }

    public render(): ReactNode {

        if (!this.props.structure.list || !this.props.results || Object.keys(this.props.results).length === 0) {
            return <></>
        }

        return (
            <div className={`grid-list row ${this.props.galleryView ? '' : 'd-none'}`}>
                {this.renderContentGallery()}
            </div>
        )

    }
}

export default Gallery;