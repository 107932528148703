import React, {ReactNode} from "react";
import {Link} from "react-router-dom";

export interface InputCheckboxFieldProps {
    value: string,
    disabled?: boolean,
    label?: string,
    linkTo?: string,
    iconTo?: string,
    onChange: (value, description?) => void
}

class InputCheckboxField extends React.Component<InputCheckboxFieldProps> {

    public render(): ReactNode {

        return (
            <div className="form-check">
                <label className="form-check-label">
                    <input type="checkbox"
                           className="form-check-input"
                           checked={!!this.props.value}
                           onChange={() => this.props.onChange(!this.props.value)}
                           disabled={this.props.disabled}
                    />
                    {this.props.label}
                    <span className="form-check-sign"><span className="check"/></span>
                </label>
                {
                    this.props.linkTo && this.props.linkTo !== '' ?
                        <Link className="link-check-option ml-2" to={this.props.linkTo}><i className={this.props.iconTo}/></Link> : null
                }
            </div>
        );
    }
}

export default InputCheckboxField;