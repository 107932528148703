import React, {ReactNode} from "react";
import {StructureEntity} from "../../../model/StructureEntity";
import ShowFieldForm from "../../../components/field/ShowFieldForm";
import {Tab, Tabs} from "react-bootstrap";

interface FormTranslatableFieldsProps {
    formStructure: StructureEntity['form'],
    initialDescriptionAutoSuggest: string[],
    entity: string,
    disabled?: boolean,
    changeField: (value: string, field: string, lang: string) => void,
    addFieldError: (error: boolean, field: string, lang: string) => void,
    fields: any,
    languages: string[],
    renderRequiredErrors: boolean,
    renderErrors: boolean,
    isEditScreen: boolean
}

class FormTranslatableFields extends React.Component<FormTranslatableFieldsProps> {

    public state = {tab: ''};

    public componentWillMount(): void {
        this.setState({tab: this.props.languages[0]})
    }

    public render(): ReactNode {
        const {formStructure} = this.props;
        const structureForm = Object.keys(formStructure).filter((field) => formStructure[field].isTranslatable);

        if (structureForm.length > 0) {
            return (
                <div className="col-md-12" id="languages-tabs">
                    <Tabs id="languages" activeKey={this.state.tab} onSelect={(tab) => this.setState({tab})}>
                        {
                            this.props.languages.map((lang) => {
                                return (
                                    <Tab key={lang} eventKey={lang} title={lang.toUpperCase()}>
                                        <div className="row">
                                            {
                                                structureForm.map((key) => {
                                                    const field = formStructure[key];
                                                    let value = '';
                                                    if (this.props.fields.translations && this.props.fields.translations[lang] && this.props.fields.translations[lang][key]) {
                                                        value = this.props.fields.translations[lang][key];
                                                    }

                                                    const colClasses = (field.colClasses ? field.colClasses + ' ' : '') + (field.colCustomClasses ? field.colCustomClasses : '');

                                                    return (
                                                        <>
                                                        { this.renderSeparator(field.separator) }
                                                        <div key={key}
                                                             className={colClasses}
                                                             style={field.style}>
                                                            <div className="form-group">
                                                                <label>{field.label}{field.required ? '*' : ''}</label>
                                                                <ShowFieldForm
                                                                    entity={this.props.entity}
                                                                    value={value ? value : ''}
                                                                    initialDescriptionAutoSuggest={this.props.initialDescriptionAutoSuggest[key] ? this.props.initialDescriptionAutoSuggest[key] : ''}
                                                                    field={key}
                                                                    size={field.size}
                                                                    type={field.type}
                                                                    changeField={(val) => {
                                                                        this.props.changeField(val, key, lang)
                                                                    }}
                                                                    addFieldError={(error) => {
                                                                        this.props.addFieldError(error, key, lang)
                                                                    }}
                                                                    required={field.required}
                                                                    renderRequiredErrors={this.props.renderRequiredErrors}
                                                                    renderErrors={this.props.renderErrors}
                                                                    values={field.values}
                                                                    disabled={this.props.disabled ? true : field.disabled}
                                                                    isEditScreen={this.props.isEditScreen}
                                                                />
                                                            </div>
                                                        </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Tab>
                                )
                            })
                        }
                    </Tabs>
                </div>
            );
        }
        return <></>
    }

    public renderSeparator(separator: string): ReactNode
    {
        if (separator) {
            return <div className="form-separator col-12">{separator}</div>
        }

        return <></>;
    }
}

export default FormTranslatableFields;