import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";

interface TaskExecuteActionResponse {
    success: boolean,
    message: string
}

export default class TaskExecuteAction extends BaseGenericRequest<{}, TaskExecuteActionResponse> {

    private readonly data;

    public constructor(data: any, slug: string) {
        super(METHOD.METHOD_POST, generateRoute(Urls.getUrlExecuteAction(), {slug}));
        this.data = data;
    }

    protected getRequest(): {} {
        return this.data;
    }
}