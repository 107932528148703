import React, { ReactNode } from "react";
import EntityReducer from "../EntityReducer";
import { connect } from "react-redux";
import TaskGetEntity from "../../../ws/entity/TaskGetEntity";
import I18nUtils from "../../../I18n/I18nUtils";
import { TR_ERROR_EDIT_FORM, TR_GENERIC_ERROR_ENTITY, TR_LIST_BACK, TR_OK_EDIT_FORM_FEMALE, TR_OK_EDIT_FORM_MALE } from "../../../I18n/constants";
import { Link } from "react-router-dom";
import { generateRoute } from "../../../utils/Router";
import { ROUTE_ENTITY_LIST } from "../../../routing/Routes";
import LoadingView from "../../../base/loading/LoadingView";
import EntityForm from "./EntityForm";
import { Card, CardBody, Col, Row } from "reactstrap";
import TaskEditEntity from "../../../ws/entity/TaskEditEntity";
import { RelationsValuesInterface } from "../../../model/StructureEntity";
import { FormRenderData } from '../../../Module';
import DeleteModal from "../Delete/DeleteModal";
import EntityActions from "../EntityActions";

const mapStateToProps = EntityReducer.autoMapToProps();

interface EditScreenProps {
    id: number,
    languages: string[],
    primaryColor: string,
    iconsColor: string,
    beforeFormView?: (entity: string, data: FormRenderData) => React.ReactNode,
    afterFormView?: (entity: string, data: FormRenderData) => React.ReactNode,
}

class EditScreen extends React.Component<EditScreenProps & typeof mapStateToProps> {

    public state = { render: false, modified: false, error: false, errors: {} as any };

    public componentWillMount(): void {
        new TaskGetEntity(this.props.currentEntity.entity, this.props.id, this.props.currentEntity.needsTranslations)
            .onSuccess(() => this.setState({ render: true }))
            .onFail(() => this.setState({ render: true }))
            .execute();
    }

    public renderDeleteModal(): ReactNode {

        return (
            <DeleteModal
                entity={this.props.deletingEntity}
                onClose={() => {
                    EntityActions.closeDeleteModal.asConnectedAction()
                }}
                onDelete={() => {
                    EntityActions.closeDeleteModal.asConnectedAction();
                    this.updateEntity();
                }}
                show={this.props.showDeleteModal}
                id={this.props.deletingItem}
                name={this.props.structure.moreInfo.label}
                genre={this.props.structure.moreInfo.genre}
                primaryColor={this.props.primaryColor}
            />
        );
    }

    public updateEntity() {
        this.setState({ render: false })
        new TaskGetEntity(this.props.currentEntity.entity, this.props.id, this.props.currentEntity.needsTranslations)
            .onSuccess(() => this.setState({ render: true }))
            .onFail(() => this.setState({ render: true }))
            .execute();
    }

    public onSubmitForm(data: any, relations: RelationsValuesInterface) {
        this.setState({ modified: false, error: false });
        delete data.oid;
        if (data.translations) {
            Object.keys(data.translations).map((index) => delete data.translations[index].id);
        }
        new TaskEditEntity(this.props.currentEntity.entity, this.props.id, data, relations)
            .onSuccess(() => {
                this.setState({ modified: true });
                document.getElementsByClassName("main-panel")[0].scrollTop = 0;
                window.scrollTo(0, 0);
            })
            .onFail((response) => {
                try {
                    const errors = JSON.parse(response);
                    this.setState({ error: true, errors });
                } catch (e) {
                    this.setState({ error: true, errors: {} as any });
                }

                document.getElementsByClassName("main-panel")[0].scrollTop = 0;
            })
            .execute();
    }

    public renderMessages(): ReactNode {
        return (
            <>
                <div className={`col-md-12 ${this.state.error ? '' : 'd-none'}`}>
                    <div className="alert alert-danger fade show">
                        {
                            Object.keys(this.state.errors).length > 0 ?
                                Object.keys(this.state.errors).map((field) => {
                                    if (this.props.structure.form[field]) {
                                        return <p key={field}>{this.props.structure.form[field].label + ': ' + this.state.errors[field]}</p>;
                                    }
                                    return <p key={field}>{this.state.errors[field]}</p>;
                                }) :
                                I18nUtils.tr(TR_ERROR_EDIT_FORM)
                        }
                    </div>
                </div>
                <div className={`col-md-12 ${this.state.modified ? '' : 'd-none'}`}>
                    <div className="alert alert-success fade show">
                        {
                            I18nUtils.tr(this.props.structure.moreInfo.genre === 'female' ? TR_OK_EDIT_FORM_FEMALE : TR_OK_EDIT_FORM_MALE)
                                .replace(':entity', this.props.structure.moreInfo.label.toLowerCase())
                        }
                    </div>
                </div>
            </>
        );
    }

    public renderButtonsTop(): ReactNode {

        return (
            this.props.currentEntity.singleton ?
                null :
                <Link to={generateRoute(ROUTE_ENTITY_LIST, { entity: this.props.currentEntity.entity })}>
                    <div className="btn btn-light button-back">
                        <i className="fa fa-arrow-left pr-2"/>
                        {I18nUtils.tr(TR_LIST_BACK)}
                    </div>
                </Link>
        );

    }

    public renderViewContent(): ReactNode {

        if (this.props.errorGetEntity || !this.props.entity.__permissions.edit) {
            return <div className="alert alert-danger fade show">{I18nUtils.tr(TR_GENERIC_ERROR_ENTITY)}</div>
        }

        return (
            <div>
                <Row>
                    {this.renderMessages()}
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <LoadingView loading={this.props.loadingEdit}/>
                                <EntityForm
                                    entity={this.props.currentEntity.entity}
                                    structure={this.props.structure}
                                    data={this.props.entity}
                                    name={this.props.currentEntity.name}
                                    languages={this.props.languages}
                                    primaryColor={this.props.primaryColor}
                                    iconsColor={this.props.iconsColor}
                                    onSubmit={(data, relations: RelationsValuesInterface) => this.onSubmitForm(data, relations)}
                                    isEditScreen={true}
                                    oid={this.props.id}
                                    beforeFormView={this.props.beforeFormView}
                                    afterFormView={this.props.afterFormView}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );

    }

    public render(): ReactNode {

        return this.props.loadingGetEntity || !this.state.render ?
            <LoadingView loading={true}/> :
            <>
                { this.renderButtonsTop() }
                { this.renderViewContent() }
                { this.renderDeleteModal() }
            </>

    }
}

export default connect(mapStateToProps)(EditScreen) as React.ComponentType<EditScreenProps>;
