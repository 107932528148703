import * as React from 'react';
import {ReactNode} from 'react';
import I18nUtils from "../../I18n/I18nUtils";
import {TR_FORGOT_PASSWORD, TR_LOGIN, TR_LOGIN_SAML_INCORRECTO, TR_USER_WITHOUT_ENTITIES} from "../../I18n/constants";
import AuthManager from "../../utils/AuthManager";
import {connect} from "react-redux";
import LoadingView from "../../base/loading/LoadingView";
import ConfigModule from "../../ConfigModule";
import TaskGetAppInfo from "../../ws/app/TaskGetAppInfo";
import {ROUTE_ACTION, ROUTE_ENTITY_LIST, ROUTE_RESET_PASSWORD_REQUEST} from "../../routing/Routes";
import {RouteComponentProps, withRouter} from "react-router";
import {generateRoute} from "../../utils/Router";
import {AppInfo} from "../../model/AppInfo";
import {Link} from "react-router-dom";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import AppInfoReducer from "../../components/screen/AppInfoReducer";
import LoginGoogle from "./LoginGoogle";
import LoginForm from "./LoginForm";
import iconWindows from "../../res/icons/microsoft.png";
import TaskLoginSaml from "../../ws/login/TaskLoginSaml";

interface LoginScreenProps {
    afterLoginButton?: () => React.ReactNode
}


const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(AppInfoReducer.autoMapToProps());

class LoginScreen extends React.Component<LoginScreenProps & RouteComponentProps & typeof mapStateToProps> {

    public state = {waiting: true, error: ''};

    public componentDidMount(): void {

        const tokenSaml = new URLSearchParams(this.props.location.search).get("tokenSaml");
        const loginSaml = new URLSearchParams(this.props.location.search).get("loginSaml");
        if (loginSaml === 'failed') {
            this.setState({error: I18nUtils.tr(TR_LOGIN_SAML_INCORRECTO)})
        }

        if (AuthManager.isLogged()) {
            this.onLogin();
        } else if (tokenSaml) {
            new TaskLoginSaml(tokenSaml)
                .onSuccess((response) => {
                    AuthManager.login(response.token, response.refresh_token, response.user?.username || '');
                    this.onLogin()
                })
                .onFail(() => this.setState({error: I18nUtils.tr(TR_LOGIN_SAML_INCORRECTO)}))
                .execute();

        } else if (this.props.loadingInfo) {
            new TaskGetAppInfo()
                .onSuccess((result: AppInfo) => {
                    document.title = result.name;
                    this.setState({waiting: false});
                })
                .execute();
        } else {
            document.title = this.props.appInfo.name;
            this.setState({waiting: false});
        }

    }

    public onLogin() {
        new TaskGetAppInfo()
            .onSuccess((result: any) => {
                const actionFirstToShow = result.actions.find((action) => action.showInMenu && action.isFirstItemMenu);
                if (actionFirstToShow) {
                    this.props.history.push(generateRoute(ROUTE_ACTION, {action: actionFirstToShow.slug}));
                } else {
                    const entitiesToShow = result.entities.find((entity) => entity.showInMenu);
                    if (entitiesToShow) {
                        this.props.history.push(generateRoute(ROUTE_ENTITY_LIST, {entity: entitiesToShow.entity}));
                    } else {
                        this.setState({error: I18nUtils.tr(TR_USER_WITHOUT_ENTITIES), waiting: false})
                    }
                }
            })
            .onFail(() => AuthManager.logout())
            .execute();
    }

    public renderNormalLogin(): ReactNode {
        if (this.props.appInfo.allowNormalLogin) {
            return (
                <>
                    <LoginForm
                        onLogin={() => this.onLogin()}
                        primaryColor={this.props.appInfo.appStyles.primaryColor}
                        setError={(error) => this.setState({error})}
                        setWaiting={(waiting) => this.setState({waiting})}
                    />
                    {
                        this.props.appInfo.allowResetPassword ?
                            <div className="lost-password-link">
                                <Link to={ROUTE_RESET_PASSWORD_REQUEST}>
                                    {I18nUtils.tr(TR_FORGOT_PASSWORD)}
                                </Link>
                            </div> : <></>
                    }
                </>
            );
        }
        return <></>;
    }

    public renderGoogleLogin(): ReactNode {
        if (this.props.appInfo.allowGoogleLogin) {
            return (
                <LoginGoogle
                    onLogin={() => this.onLogin()}
                    primaryColor={this.props.appInfo.appStyles.primaryColor}
                    setError={(error) => this.setState({error})}
                    setWaiting={(waiting) => this.setState({waiting})}
                />
            );
        }
        return <></>;
    }

    public renderSamlLogin(): ReactNode {
        if (this.props.appInfo.allowSamlLogin) {
            return (
                <div className="d-flex justify-content-center mt-2">
                    <a href={ConfigModule.SSO_URL} className="btn btn-lg btn-custom" style={{backgroundColor: this.props.appInfo.appStyles.primaryColor}}>
                        <img className="mr-1" src={iconWindows}/>
                        {I18nUtils.tr(TR_LOGIN)}
                    </a>
                </div>
            );
        }
        return <></>;
    }

    public render(): ReactNode {

        if ((AuthManager.isLogged() && this.state.waiting) || !this.props.appInfo.appStyles) {
            return <LoadingView loading={true}/>
        }

        return (
            <div className="wrapper">
                <div className="d-flex justify-content-center h-100 align-items-center">
                    <div className="card login-card">
                        <div className="card-body">
                            <div className="d-flex justify-content-center">
                                <div className="brand_logo_container">
                                    <img
                                        src={ConfigModule.BASE_IMAGENES + this.props.appInfo.logo}
                                        className="brand_logo" alt="Logo"/>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center form_container">
                                <div>
                                    <div className={`error-message mb-3 ${this.state.error ? '' : 'd-none'}`}>
                                        {this.state.error}
                                    </div>
                                    <LoadingView loading={this.state.waiting}/>
                                    <div>
                                        {this.renderNormalLogin()}
                                        {this.renderGoogleLogin()}
                                        {this.renderSamlLogin()}{
                                        this.props.afterLoginButton &&
                                            this.props.afterLoginButton()
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps)(withRouter(LoginScreen)) as React.ComponentType<LoginScreenProps>;

