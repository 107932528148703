import * as React from 'react';
import {Component, ReactNode} from 'react';
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_FORM_PASSWORD,
    TR_REQUEST, TR_OK_PASSWORD_CHANGED, TR_GENERIC_ERROR, TR_OK_PASSWORD_NOT_EQUAL, TR_REPEAT_PASSWORD, TR_LOGIN,
} from "../../I18n/constants";
import AuthManager from "../../utils/AuthManager";
import TaskGetAppInfo from "../../ws/app/TaskGetAppInfo";
import {AppInfo} from "../../model/AppInfo";
import AppInfoReducer from "../../components/screen/AppInfoReducer";
import {connect} from "react-redux";
import LoadingView from "../../base/loading/LoadingView";
import TaskResetPassword from "../../ws/login/TaskResetPassword";
import ConfigModule from "../../ConfigModule";

const mapStateToProps = AppInfoReducer.autoMapToProps();

interface ChangePasswordScreenProps {
    token: string
}

type ChangePasswordScreenRouterProps = RouteComponentProps<ChangePasswordScreenProps> & typeof mapStateToProps;

class ChangePasswordScreen extends Component<ChangePasswordScreenRouterProps> {

    public state = { password: '', passwordRepeat: '', error: '', waiting: true, passwordChanged: false };

    public componentDidMount(): void {

        if (AuthManager.isLogged()) {
            this.props.history.push('/');
        }
        else if (this.props.loadingInfo) {
            new TaskGetAppInfo()
                .onSuccess((result: AppInfo) => {
                    if (result.allowResetPassword) {
                        document.title = result.name;
                        this.setState({waiting: false});
                    }
                    else {
                        this.props.history.push('/');
                    }
                })
                .execute();
        }
        else {
            if (this.props.appInfo.allowResetPassword) {
                this.setState({waiting: false});
            }
            else {
                this.props.history.push('/');
            }
        }

    }

    public changePassword() {
        if (this.state.password !== '') {
            if (this.state.password !== this.state.passwordRepeat) {
                this.setState({error: I18nUtils.tr(TR_OK_PASSWORD_NOT_EQUAL), waiting: false});
            }
            else {
                this.setState({waiting: true});
                new TaskResetPassword(this.props.match.params.token, this.state.password)
                    .onSuccess(() => {
                        this.setState({
                            waiting: false,
                            passwordChanged: true
                        })
                        const redirect = new URLSearchParams(this.props.location.search).get("redirect");
                        if (redirect) {
                            setTimeout(() => {
                                window.location.href = redirect;
                            }, 3000)
                        }
                    })
                    .onFail((response) => {
                        try {
                            const errors = JSON.parse(response);
                            this.setState({error: errors[0], waiting: false});
                        } catch (e) {
                            this.setState({error: I18nUtils.tr(TR_GENERIC_ERROR), waiting: false});
                        }
                    })
                    .execute();
            }
        }
    }

    public render(): ReactNode {

        if (!this.props.appInfo.appStyles) {
            return <div className="wrapper"><LoadingView loading={true}/></div>
        }

        return (
            <div className="wrapper" id="change-password">
                <div className="d-flex justify-content-center h-100 align-items-center">
                    <div className="card login-card">
                        <div className="card-body">
                            <div className="d-flex justify-content-center">
                                <div className="brand_logo_container">
                                    <img
                                        src={ConfigModule.BASE_IMAGENES + this.props.appInfo.logo}
                                        className="brand_logo" alt="Logo"/>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center form_container">
                                <div>
                                    {
                                        this.state.passwordChanged ?
                                            <>
                                                <div className="success-message mb-3">
                                                    { I18nUtils.tr(TR_OK_PASSWORD_CHANGED) }
                                                </div>
                                                <div className="lost-password-link">
                                                    <Link to="">
                                                        <button className="btn btn-lg btn-custom" style={{backgroundColor: this.props.appInfo.appStyles.primaryColor}}>
                                                            { I18nUtils.tr(TR_LOGIN) }
                                                        </button>
                                                    </Link>
                                                </div>
                                            </> :
                                            <>
                                                <div className={`error-message mb-3 ${this.state.error ? '' : 'd-none'}`}>
                                                    { this.state.error }
                                                </div>
                                                <LoadingView loading={this.state.waiting}/>
                                                <form onSubmit={ (e) => { e.preventDefault(); this.changePassword() } }>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label htmlFor="mail">{ I18nUtils.tr(TR_FORM_PASSWORD) }</label>
                                                                <input type="password"
                                                                       className="form-control"
                                                                       value={this.state.password}
                                                                       onChange={(e) => {this.setState({password: e.target.value, error: ''})}}
                                                                       onKeyPress={(e) => { e.key === 'Enter' ? this.changePassword() : {} }}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="mail">{ I18nUtils.tr(TR_REPEAT_PASSWORD) }</label>
                                                                <input type="password"
                                                                       className="form-control"
                                                                       value={this.state.passwordRepeat}
                                                                       onChange={(e) => {this.setState({passwordRepeat: e.target.value, error: ''})}}
                                                                       onKeyPress={(e) => { e.key === 'Enter' ? this.changePassword() : {} }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <button type="submit" className="btn btn-lg btn-custom" style={{backgroundColor: this.props.appInfo.appStyles.primaryColor}}>
                                                            { I18nUtils.tr(TR_REQUEST) }
                                                        </button>
                                                    </div>
                                                </form>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(connect(mapStateToProps)(ChangePasswordScreen) as any) as React.ComponentType<{}>;

