import React, {ReactNode} from "react";
import {
    FieldForm,
    FieldFormValue,
    FormRelationsInterface,
    RelationsValuesInterface,
    StructureEntity
} from "../../../model/StructureEntity";
import FormFields from "./FormFields";
import FormTranslatableFields from "./FormTranslatableFields";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_ADD, TR_ERROR_FORM, TR_SAVE} from "../../../I18n/constants";
import validate from "../../../components/field/validate";
import validateRequired from "../../../components/field/validateRequired";
import FormRelations from "./FormRelations";
import {getValueToShow} from "../../../components/field/ShowField";
import FormRelationsForm from "./FormRelationsForm";
import TaskGetCustomFormEntity from "../../../ws/entity/TaskGetCustomFormEntity";
import {CustomStylesFields, CustomValuesFields} from "../../../model/FieldToModify";
import LoadingView from "../../../base/loading/LoadingView";
import { FormRenderData } from '../../../Module';

interface EntityFormProps {
    structure: StructureEntity,
    data: any,
    name: string,
    entity: string,
    disabled?: boolean,
    languages: string[],
    primaryColor?: string,
    iconsColor?: string,
    onSubmit?: (data, relations: RelationsValuesInterface) => void,
    isEditScreen: boolean,
    oid?: number,
    beforeFormView?: (entity: string, data: FormRenderData) => React.ReactNode,
    afterFormView?: (entity: string, data: FormRenderData) => React.ReactNode,
}

interface EntityFormState {
    data: any;
    loading: boolean,
    fields: any,
    relationsValues: RelationsValuesInterface,
    relationFormValues: any,
    tab: string,
    renderRequiredErrors: boolean,
    errors: any,
    showMessageError: boolean,
    submit: boolean,
    customStylesFields: CustomStylesFields,
    customValuesFields: CustomValuesFields
}

class EntityForm extends React.Component<EntityFormProps> {

    public state: EntityFormState = {
        data: this.props.data,
        loading: false,
        fields: {} as any,
        relationsValues: {} as RelationsValuesInterface,
        relationFormValues: {} as any,
        tab: '',
        renderRequiredErrors: false,
        errors: {} as any,
        showMessageError: false,
        submit: false,
        customStylesFields: {} as CustomStylesFields,
        customValuesFields: {} as CustomValuesFields
    };

    private lastModifiedFieldValue = '';
    private modifiedFields = {} as any;

    private lastModifiedFormFieldValue = {} as any;
    private modifiedFormFields = {} as any;

    public componentWillMount(): void {
        const initialData = this.getInitialData();
        const initialRelationsValues = this.getInitialRelationsValues();
        const initialFormRelationsValues = this.getInitialFormRelationsValues();
        this.setState({
            fields: initialData,
            relationsValues: initialRelationsValues,
            relationFormValues: initialFormRelationsValues,
            errors: this.getInitialErrors(),
            tab: this.props.languages[0]}
        );
        this.getInitialCustomChangesForm(initialData, initialRelationsValues, initialFormRelationsValues);
    }

    public componentDidUpdate(_: Readonly<EntityFormProps>, prevState: Readonly<{ errors: any, data: any }>) {
        if(this.props.data != prevState.data) {
            const relationsValues = this.getInitialRelationsValues();
            const relationFormValues = this.getInitialFormRelationsValues();
            this.setState({relationsValues: relationsValues, relationFormValues: relationFormValues, data: this.props.data});
        }
        if (this.state.errors !== prevState.errors) {
            if (this.state.showMessageError) {
                this.checkErrors(this.state.errors);
            }
        }
    }

    public getInitialData() {

        const data: any = {};
        const fields = this.props.data;
        Object.keys(fields).map((field) => {
            if (!this.props.structure.formRelations[field]) {
                if (typeof fields[field] === 'object' && field !== 'translations') {
                    if (this.props.structure.form[field]) {
                        data[field] = fields[field] && fields[field].oid ? parseInt(fields[field].oid, 0) : null;
                    }
                } else if (field === 'translations' || this.props.structure.form[field]) {
                    data[field] = fields[field];
                }
            }
        });

        return data
    }

    /**
     * para componer el nombre de los objetos relacionados
     */
    public getInitialFieldsDescriptionAutoSuggest(): string[] {
        const {form} = this.props.structure;
        const fields = this.props.data;

        return this.getDescriptionAutoSuggestFormFields(fields, form);
    }

    public getInitialFormFieldsDescriptionAutoSuggest(): {[key: string]: any[]} {
        const {formRelations} = this.props.structure;
        const descriptionAutoSuggest = {} as any;

        Object.keys(formRelations).forEach((relationKey) => {
            if (formRelations[relationKey].type === 'FORM') {
                descriptionAutoSuggest[relationKey] = [];
                (this.props.data[relationKey] || []).forEach((data, index) => {
                    const form = this.props.structure.formRelations[relationKey].formStructure;
                    descriptionAutoSuggest[relationKey][index] = this.getDescriptionAutoSuggestFormFields(data, form);
                })
            }
        });

        return descriptionAutoSuggest;
    }

    private getDescriptionAutoSuggestFormFields(fields: any, form: { [key: string]: FieldForm }): string[] {
        const data: any = {};
        if (fields) {
            Object.keys(fields).forEach((field) => {
                if (form[field] && form[field].type === 'AUTOSUGGEST' && typeof fields[field] === 'object') {
                    const dataField = {} as any;
                    if (fields[field]) {
                        Object.keys(fields[field]).forEach((fieldName) => {
                            if (fieldName === 'translations') {
                                const translations = fields[field][fieldName][I18nUtils.getLocale()];
                                Object.keys(translations).forEach((fieldNameTranslation) => {
                                    dataField[fieldNameTranslation] = translations[fieldNameTranslation];
                                });
                            } else {
                                dataField[fieldName] = fields[field][fieldName];
                            }
                        });
                    }
                    data[field] = '';
                    form[field].fieldsToShow.forEach((fieldToShow) => {
                        if (dataField[fieldToShow]) {
                            data[field] += dataField[fieldToShow] + ' ';
                        }
                    });
                    data[field] = data[field].trim();
                }
            });
        }
        return data
    }

    public getInitialRelationsValues() {

        const {formRelations} = this.props.structure;
        const fields = this.state.data;
        const relationsValues = {} as RelationsValuesInterface;
        if (fields) {
            Object.keys(fields).map((index) => {
                if (formRelations[index] && fields[index] && typeof fields[index] === 'object' && formRelations[index].type != 'TABLE') {
                    if (formRelations[index].relationType === 'manyToMany') {
                        Object.keys(fields[index]).map((indexRelation) => {
                            Object.keys(fields[index][indexRelation]).map((nameRelation) => {
                                if (nameRelation !== 'oid') {
                                    fields[index][indexRelation] = fields[index][indexRelation][nameRelation];
                                }
                            });
                        });
                    }
                    Object.keys(fields[index]).map((indexRelation) => {
                        const relation = fields[index][indexRelation];
                        let description = '';
                        formRelations[index].fieldsToShow.map((fieldToShow) => {
                            const fieldsForValueToShow = fieldToShow.split('.');
                            if (relation[fieldsForValueToShow[0]] !== null) {
                                description = description + getValueToShow(fieldsForValueToShow, relation) + ' ';
                            } else if (relation.translations && relation.translations[I18nUtils.getLocale()] && relation.translations[I18nUtils.getLocale()][fieldToShow]) {
                                description = description + getValueToShow([fieldToShow], relation.translations[I18nUtils.getLocale()]) + ' ';
                            }
                        });
                        if (!relationsValues[index]) {
                            relationsValues[index] = {};
                        }
                        relationsValues[index][relation.oid] = {
                            description: description.trim(),
                            checked: true,
                            order: indexRelation
                        };
                    });
                }
            });
        }

        return relationsValues
    }

    public getInitialFormRelationsValues() {

        const {formRelations} = this.props.structure;
        const formRelationsValues = {} as RelationsValuesInterface;

        Object.keys(formRelations).forEach((relationKey) => {
            if (formRelations[relationKey].type === 'FORM') {
                const needTranslation = Object.keys(formRelations[relationKey].formStructure).filter((index) => formRelations[relationKey].formStructure[index].isTranslatable).length > 0;
                formRelationsValues[relationKey] = (this.state.data[relationKey] || []).map((relationValue) => {
                    const values = {};
                    Object.keys(relationValue).forEach((field) => {
                        if (field === 'oid') {
                            values[field] = relationValue[field];
                        } else if (typeof relationValue[field] === 'object' && relationValue[field] !== 'translations') {
                            if (formRelations[relationKey].formStructure[field]) {
                                values[field] = relationValue[field] && relationValue[field].oid ? parseInt(relationValue[field].oid, 0) : null;
                            }
                        } else if (field === 'translations' || formRelations[relationKey].formStructure[field]) {
                            values[field] = relationValue[field];
                        }
                    });

                    if (needTranslation) {
                        const translations = (relationValue.translations || {});
                        Object.keys(translations).forEach((lang) => {
                            translations[lang].id = undefined;
                        });

                        return {
                            ...values,
                            translations,
                        }
                    }

                    return values;
                });
            }
        });

        return formRelationsValues
    }

    public getInitialErrors() {
        const {form} = this.props.structure;
        return this.getFormInitialErrors(form);
    }

    private getFormInitialErrors(form: { [key: string]: FieldForm }, values:any = null): any {
        const errors: any = {};
        Object.keys(form).map((field) => {
            if (form[field].isTranslatable) {
                this.props.languages.map((lang) => {
                    if (!errors.translations) {
                        errors.translations = {};
                    }
                    if (!errors.translations[lang]) {
                        errors.translations[lang] = {};
                    }
                    errors.translations[lang][field] = !validateRequired(form[field].required, form[field].type, this.props.data.translations && this.props.data.translations[lang] ? this.props.data.translations[lang][field] : '', true) || !validate(form[field].type, this.props.data[field]);
                });
            }
            else {
                errors[field] = !validateRequired(form[field].required, form[field].type, (values ? values[field] : this.props.data[field]), true) || !validate(form[field].type, this.props.data[field]);
            }
        });
        return errors;
    }

    public changeField(value, field: string, lang?: string): void {
        if (!this.props.disabled) {
            if (this.props.structure.form[field].type === 'BOOLEAN' && value === '') {
                value = false;
            }
            if (!lang) {
                if (
                    this.props.structure.form[field].dependent &&
                    (this.state.fields[field] !== value && !(!this.state.fields[field] && value === ''))
                ) {
                    this.getCustomChangesForm(value, field);
                }
                this.setState({fields: {...this.state.fields, [field]: value}});
            } else {
                let translations;
                if (!this.state.fields.translations || this.state.fields.translations.constructor === Array) {
                    translations = {};
                } else {
                    translations = this.state.fields.translations;
                }
                if (!translations[lang]) {
                    translations[lang] = {};
                    translations[lang].locale = lang;
                }
                translations[lang][field] = value;

                this.setState({fields: {...this.state.fields, translations}});
            }
        }
    }

    public removeFile(field: string): void {
        let currentFields = {...this.state.fields};
        if (field in currentFields) {
            delete currentFields[field];
            this.setState({fields: currentFields});
        } else {
            let currentFieldsForms = {...this.state.relationFormValues};
            let [relationKey, fieldFile, index] = field.split('.');
            currentFieldsForms[relationKey][parseInt(index)][fieldFile] = null
            this.setState({relationFormValues: currentFieldsForms})
        }
    }

    public addFieldError(error: boolean, field: string, lang?: string): void {
        if (!this.props.disabled) {
            if (!lang) {
                this.setState({errors: {...this.state.errors, [field]: error}});
            } else {
                const translationsErrors = this.state.errors.translations || {};
                if (!translationsErrors[lang]) {
                    translationsErrors[lang] = {};
                }
                translationsErrors[lang][field] = error;

                this.setState({errors: {...this.state.errors, translations: translationsErrors}});
            }
        }
    }

    public changeRelation(relation: string, idrelation: number, checked: boolean, description: string): void {
        if (!this.props.disabled && idrelation && this.props.structure.formRelations[relation].canEdit) {
            if (this.state.relationsValues[relation]) {
                this.setState({
                    relationsValues: {
                        ...this.state.relationsValues,
                        [relation]: {
                            ...this.state.relationsValues[relation],
                            [idrelation]: {
                                description,
                                checked,
                                order: this.state.relationsValues[relation][idrelation] ?
                                    this.state.relationsValues[relation][idrelation].order :
                                    new Date().getTime()
                            }
                        }
                    }
                });
            } else {
                this.setState({
                    relationsValues: {
                        ...this.state.relationsValues,
                        [relation]: {[idrelation]: {description, checked, order: new Date().getTime()}}
                    }
                });
            }
            if(this.props.structure.formRelations[relation].dependent) {
                this.getCustomChangesForm(idrelation, relation);
            }
        }
    }

    public onSubmit(): void {
        if (!this.props.disabled) {
            this.setState({renderRequiredErrors: true, submit: true});
            if (this.checkErrors(this.state.errors)) {
                if (this.props.onSubmit) {
                    const data = Object.assign({}, this.state.fields);
                    const {form} = this.props.structure;
                    Object.keys(data).map((index) => {
                        if (form[index] && form[index].virtualColumn) {
                            delete data[index];
                        }
                    });

                    const relationsData = {
                        ...this.state.relationsValues,
                        ...this.state.relationFormValues,
                    };
                    this.props.onSubmit(data, relationsData);
                    this.setState({renderRequiredErrors: false});
                }
            }
        }
    }

    public getInitialCustomChangesForm(
        initialData,
        initialRelationsValues,
        initialFormRelationsValues
    ) {
        const structure = this.props.structure.form;

        Object.keys(structure).map((key) => {
            const field = structure[key];
            if (field.dependent) {
                let value = '';
                if (typeof initialData[key] !== 'undefined' && initialData[key] !== null) {
                    value = initialData[key];
                }
                this.modifiedFields[key] = value;
            }
        });

        this.executeTaskGetCustomFormEntity(initialData, initialRelationsValues, initialFormRelationsValues);
    }

    public isLastModifiedFormFieldValueEqual(prev, current) {
        const keys1 = Object.keys(prev);
        const keys2 = Object.keys(current);
        if (keys1.length !== keys2.length) {
          return false;
        }
        for (const key of keys1) {
          const val1 = prev[key];
          const val2 = current[key];
          if (val1 !== val2) {
            return false;
          }
        }
        return true;
      }

    public getCustomChangesForm(value, field: string) {
        if (value.relationKey) {
            this.lastModifiedFormFieldValue = value;
            this.modifiedFormFields[field] = value;
            setTimeout(() => {
                if (this.isLastModifiedFormFieldValueEqual(this.lastModifiedFormFieldValue, value)) {
                    this.executeTaskGetCustomFormEntity(
                        this.state.fields,
                        this.state.relationsValues,
                        this.state.relationFormValues
                    );
                }
            }, 500);
        }
        else {
            this.lastModifiedFieldValue = value + '_' + field;
            this.modifiedFields[field] = value;
            setTimeout(() => {
                if (this.lastModifiedFieldValue === value + '_' + field) {
                    this.executeTaskGetCustomFormEntity(
                        this.state.fields,
                        this.state.relationsValues,
                        this.state.relationFormValues
                    );
                }
            }, 500);
        }
    }

    public executeTaskGetCustomFormEntity(
        initialData,
        initialRelationsValues,
        initialFormRelationsValues
    ) {
        this.setState({loading: true});
        new TaskGetCustomFormEntity(
            this.props.entity,
            initialData,
            initialRelationsValues,
            initialFormRelationsValues,
            this.modifiedFields,
            this.modifiedFormFields,
            this.props.oid
        )
            .onSuccess((fieldsToModify) => {
                this.modifiedFields = {} as any;
                const customStylesFields = {} as CustomStylesFields;
                const customValuesFields = {} as CustomValuesFields;
                Object.keys(fieldsToModify).map((fieldToModify) => {
                    const custom = fieldsToModify[fieldToModify];
                    if (custom.style !== null) {
                        customStylesFields[fieldToModify] = custom.style;
                    }
                    if (custom.value !== null) {
                        if (typeof custom.value === 'object' && 'relationKey' in custom.value) {
                            let newValue = custom.value as FieldFormValue
                            let relationFormValues = {...this.state.relationFormValues}
                            relationFormValues[newValue.relationKey][newValue.index][fieldToModify] = newValue.value
                            this.setState({relationFormValues: relationFormValues})
                        } else {
                            this.setState({fields: {...this.state.fields, [fieldToModify]: custom.value}});
                        }
                    }
                    if (custom.options) {
                        customValuesFields[fieldToModify] = custom.options;
                    }
                });
                this.setState({customStylesFields, customValuesFields, loading: false});
            })
            .execute();
    }

    public checkErrors(errors): boolean {
        let showMessageError = false;
        Object.keys(errors).map((index) => {
            if (index === 'translations') {
                Object.keys(errors[index]).map((lang) => {
                    Object.keys(errors[index][lang]).map((indexTranslation) => {
                        if (errors[index][lang][indexTranslation] === true) {
                            showMessageError = true;
                        }
                    })
                })
            } else if (index === 'formRelations') {
                Object.keys(errors[index]).forEach((relationName) => {
                    errors[index][relationName].forEach((relation) => {
                        Object.keys(relation).forEach((relationField) => {
                            if (relationField === 'translations') {
                                Object.keys(relation[relationField]).forEach((lang) => {
                                    Object.keys(relation[relationField][lang]).map((indexTranslation) => {
                                        if (relation[relationField][lang][indexTranslation] === true) {
                                            showMessageError = true;
                                        }
                                    })
                                })
                            } else if (relation[relationField] === true) {
                                showMessageError = true;
                            }
                        })
                    })
                })
            } else if (errors[index] === true) {
                showMessageError = true;
            }
        });
        this.setState({showMessageError});
        return !showMessageError;
    }

    public addRelationFormValue(relationKey: string) {
        const relationFormValuesRelation = this.state.relationFormValues[relationKey] ? this.state.relationFormValues[relationKey] : [];
        relationFormValuesRelation.push({});
        const initialErrors = this.getFormInitialErrors(this.props.structure.formRelations[relationKey].formStructure);
        const relationFormValuesErrors = this.state.errors.formRelations && this.state.errors.formRelations[relationKey] ? this.state.errors.formRelations[relationKey] : [];
        relationFormValuesErrors.push(initialErrors);
        this.setState({
            ...this.state,
            relationFormValues: {
                ...this.state.relationFormValues,
                [relationKey]: relationFormValuesRelation,
            },
            errors: {
                ...this.state.errors,
                formRelations: {
                    ...this.state.errors.formRelations || {},
                    [relationKey]: relationFormValuesErrors
                }
            }
        })
    }

    public addTableRelationFormValue(relationKey: string, value: any) {
        let currentRelationFormValues = [] as any[];
        if (relationKey in this.state.relationFormValues) {
            currentRelationFormValues = [...this.state.relationFormValues[relationKey]]
        }
        currentRelationFormValues.push(value)
        const initialErrors = this.getFormInitialErrors(this.props.structure.formRelations[relationKey].formStructure, value);
        const relationFormValuesErrors = this.state.errors.formRelations && this.state.errors.formRelations[relationKey] ? this.state.errors.formRelations[relationKey] : [];
        relationFormValuesErrors.push(initialErrors);
        this.setState({
            ...this.state,
            relationFormValues: {
                ...this.state.relationFormValues,
                [relationKey]: currentRelationFormValues,
            },
            errors: {
                ...this.state.errors,
                formRelations: {
                    ...this.state.errors.formRelations || {},
                    [relationKey]: relationFormValuesErrors
                }
            }
        })
    }

    public removeRelationFormValue(relationKey: string, index: number) {
        if (this.state.relationFormValues[relationKey][index]) {
            let relationKeyError = [];
            if (this.state.errors.formRelations && this.state.errors.formRelations[relationKey]) {
                relationKeyError = this.state.errors.formRelations[relationKey].filter((_, filterIndex: number) => filterIndex !== index);
            }
            this.setState({
                ...this.state,
                relationFormValues: {
                    ...this.state.relationFormValues,
                    [relationKey]: this.state.relationFormValues[relationKey].filter((_, filterIndex: number) => filterIndex !== index),
                },
                errors: {
                    ...this.state.errors,
                    formRelations: {
                        ...this.state.errors.formRelations || {},
                        [relationKey]: relationKeyError,
                    }
                }
            })
        }
    }

    public onChangeRelationFormValue = (relationKey: string, index: number, field: string, value: any, lang?: string) => {
        if (!this.props.disabled) {
            if (this.props.structure.formRelations[relationKey].formStructure[field].type === 'BOOLEAN' && value === '') {
                value = false;
            }
            if (!lang) {
                if (
                    this.props.structure.formRelations[relationKey].formStructure[field].dependent &&
                    (this.state.fields[field] !== value && !(!this.state.fields[field] && value === ''))
                ) {
                    let formValue = {
                        relationKey: relationKey,
                        index: index,
                        value: value
                    }
                    this.getCustomChangesForm(formValue, field);
                }
                let updatedRelationFormValues = {...this.state.relationFormValues}
                updatedRelationFormValues[relationKey][index][field] = value
                this.setState({relationFormValues: updatedRelationFormValues})
            } else {
                let translations;
                if (!this.state.relationFormValues[relationKey]![index].translations || this.state.relationFormValues[relationKey]![index].constructor === Array) {
                    translations = {};
                } else {
                    translations = this.state.relationFormValues[relationKey][index].translations;
                }

                if (!translations[lang]) {
                    translations[lang] = {};
                    translations[lang].locale = lang;
                }
                translations[lang][field] = value;

                this.setState({
                    ...this.state,
                    relationFormValues: {
                        ...this.state.relationFormValues,
                        [relationKey]: this.state.relationFormValues[relationKey].map((_element, indexMap) => {
                            if (indexMap !== index) {
                                return this.state.relationFormValues[relationKey][indexMap]
                            } else {
                                return {
                                    ...this.state.relationFormValues[relationKey][indexMap],
                                    translations,
                                }
                            }
                        })
                    }
                });
            }
            if(this.props.structure.formRelations[relationKey].dependent) {
                this.getCustomChangesForm(value, field);
            }
        }
    };

    public addErrorRelationFormValue = (relationKey: string, index: number, field: string, error: boolean, lang?: string) => {
        if (!this.props.disabled) {
            if (!lang) {
                const formRelationsErrors = this.state.errors.formRelations || {};
                if (!formRelationsErrors[relationKey]) {
                    formRelationsErrors[relationKey] = []
                }
                if (!formRelationsErrors[relationKey][index]) {
                    formRelationsErrors[relationKey][index] = {}
                }
                formRelationsErrors[relationKey][index][field] = error;

                this.setState({
                    ...this.state,
                    errors: {
                        ...this.state.errors,
                        formRelations: formRelationsErrors,
                    }
                });
            } else {
                // ERRORS
                const formRelationsErrors = this.state.errors.formRelations || {};
                if (!formRelationsErrors[relationKey]) {
                    formRelationsErrors[relationKey] = []
                }
                if (!formRelationsErrors[relationKey][index]) {
                    formRelationsErrors[relationKey][index] = {}
                }
                if (!formRelationsErrors[relationKey][index]['translations']) {
                    formRelationsErrors[relationKey][index]['translations'] = {}
                }
                if (!formRelationsErrors[relationKey][index]['translations'][lang]) {
                    formRelationsErrors[relationKey][index]['translations'][lang] = {}
                }
                formRelationsErrors[relationKey][index]['translations'][lang][field] = error;

                this.setState({
                    ...this.state,
                    errors: {
                        ...this.state.errors,
                        formRelations: formRelationsErrors,
                    }
                });
            }
        }
    };

    public getStructureApplyingCustomFields() {
        const structureForm = {...this.props.structure.form};
        Object.keys(this.state.customStylesFields).map((customField) => {
            if (structureForm[customField]) {
                structureForm[customField].colCustomClasses = this.state.customStylesFields[customField];
            }
        });
        Object.keys(this.state.customValuesFields).map((customField) => {
            if (structureForm[customField]) {
                structureForm[customField].values = this.state.customValuesFields[customField];
            }
        });
        return this.props.structure.form;
    }

    public getStructureFormApplyingCustomFields() {
        const structureRelationForm = {...this.props.structure};
        Object.keys(this.state.customValuesFields).map((customField) => {
            if (structureRelationForm.formRelations[customField]) {
                structureRelationForm.formRelations[customField].values = this.state.customValuesFields[customField];
            }
            else {
                const [formField, changeField] = customField.split('.')
                if (structureRelationForm.formRelations[formField]) {
                    structureRelationForm.formRelations[formField].formStructure[changeField].values = this.state.customValuesFields[customField];
                }
            }
        });
        Object.keys(this.state.customStylesFields).map((customField) => {
            if (structureRelationForm.formRelations[customField]) {
                structureRelationForm.formRelations[customField].customClasses = this.state.customStylesFields[customField];
            }
            else {
                const [formField, changeField] = customField.split('.')
                if (structureRelationForm.formRelations[formField]) {
                    structureRelationForm.formRelations[formField].formStructure[changeField].colCustomClasses = this.state.customStylesFields[customField];
                }
            }
        });
        return structureRelationForm;
    }

    public getFormRelationsApplyingCustomFields(): [FormRelationsInterface, RelationsValuesInterface] {
        const formRelations = {...this.props.structure.formRelations};
        const relationsValues = {...this.state.relationsValues};
        Object.keys(this.state.customStylesFields).map((customField) => {
            if (formRelations[customField]) {
                formRelations[customField].customClasses = this.state.customStylesFields[customField];
            }
        });
        Object.keys(this.state.customValuesFields).map((customField) => {
            if (formRelations[customField]) {
                const idsActive = this.state.customValuesFields[customField].map((value) => value.id);
                formRelations[customField].values.map((field, id) => {
                    const inactive = !idsActive.includes(field.id);
                    formRelations[customField].values[id].hide = inactive;
                    if (relationsValues[customField] && relationsValues[customField][field.id]) {
                        relationsValues[customField][field.id].hide = inactive;
                    }
                });
            }
        });
        return [formRelations, relationsValues];
    }

    public render(): ReactNode {
        const initialDescriptionAutoSuggest = this.getInitialFieldsDescriptionAutoSuggest();
        const initialFormFieldsDescriptionAutoSuggest = this.getInitialFormFieldsDescriptionAutoSuggest();
        const structureForm = this.getStructureApplyingCustomFields();
        const [formRelations, relationsValues] = this.getFormRelationsApplyingCustomFields();
        const structureRelationForm = this.getStructureFormApplyingCustomFields()

        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                this.onSubmit()
            }} className="entity-form">
                <LoadingView loading={this.state.loading}/>
                <div className="row">
                    {
                        this.props.beforeFormView &&
                        <div className={`col-md-12`}>
                            {this.props.beforeFormView(this.props.entity, {
                                fields: this.state.fields,
                                relationsValues: this.state.relationsValues,
                                relationFormValues: this.state.relationFormValues,
                            })}
                        </div>
                    }
                    <div className={`col-md-12 ${this.state.showMessageError ? '' : 'd-none'}`}>
                        <div className="alert alert-danger fade show">{I18nUtils.tr(TR_ERROR_FORM)}</div>
                    </div>
                    <FormFields
                        entity={this.props.entity}
                        formStructure={structureForm}
                        initialDescriptionAutoSuggest={initialDescriptionAutoSuggest}
                        changeField={(value, field) => this.changeField(value, field)}
                        removeFile={(field) => this.removeFile(field)}
                        addFieldError={(error, field) => this.addFieldError(error, field)}
                        fields={this.state.fields}
                        disabled={this.props.disabled}
                        renderRequiredErrors={this.state.renderRequiredErrors}
                        renderErrors={true}
                        isEditScreen={this.props.isEditScreen}
                    />
                    <FormTranslatableFields
                        entity={this.props.entity}
                        formStructure={structureForm}
                        initialDescriptionAutoSuggest={initialDescriptionAutoSuggest}
                        changeField={(value, field, lang) => this.changeField(value, field, lang)}
                        addFieldError={(error, field, lang) => this.addFieldError(error, field, lang)}
                        fields={this.state.fields}
                        languages={this.props.languages}
                        disabled={this.props.disabled}
                        renderRequiredErrors={this.state.renderRequiredErrors}
                        renderErrors={true}
                        isEditScreen={this.props.isEditScreen}
                    />
                    <FormRelations
                        formRelations={formRelations}
                        entity={this.props.entity}
                        changeRelation={(relation, idrelation, checked, description) => this.changeRelation(relation, idrelation, checked, description)}
                        relationsValues={relationsValues}
                        isEditScreen={this.props.isEditScreen}
                        disabled={this.props.disabled}
                    />
                    <FormRelationsForm
                        structure={structureRelationForm}
                        entity={this.props.entity}
                        data={this.state.data}
                        oid={this.props.oid}
                        relationFormValues={this.state.relationFormValues}
                        initialDescriptionAutoSuggest={initialFormFieldsDescriptionAutoSuggest}
                        onChangeRelationFormValue={this.onChangeRelationFormValue}
                        addFieldError={this.addErrorRelationFormValue}
                        addRelationFormValue={this.addRelationFormValue.bind(this)}
                        addTableRelationFormValue={this.addTableRelationFormValue.bind(this)}
                        removeRelationFormValue={this.removeRelationFormValue.bind(this)}
                        isEditScreen={this.props.isEditScreen}
                        disabled={this.props.disabled}
                        languages={this.props.languages}
                        renderRequiredErrors={this.state.renderRequiredErrors}
                        primaryColor={this.props.primaryColor}
                        iconsColor={this.props.iconsColor}
                        removeFile={(field) => this.removeFile(field)}
                    />
                    {
                        this.props.afterFormView &&
                        <div className={`col-md-12`}>
                            {this.props.afterFormView(this.props.entity, {
                                fields: this.state.fields,
                                relationsValues: this.state.relationsValues,
                                relationFormValues: this.state.relationFormValues,
                            })}
                        </div>
                    }
                    {
                        this.props.disabled ?
                            <></> :
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-custom" style={{backgroundColor: this.props.primaryColor}}>
                                    {I18nUtils.tr(this.props.data && this.props.data.oid ? TR_SAVE : TR_ADD)}
                                </button>
                            </div>
                    }
                </div>
            </form>
        )
    }
}

export default EntityForm;