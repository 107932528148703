import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import TaskGetEntityList from "../../ws/entity/TaskGetEntityList";
import TaskGetEntity from "../../ws/entity/TaskGetEntity";
import TaskEditEntity from "../../ws/entity/TaskEditEntity";
import TaskGetStructureEntity from "../../ws/entity/TaskGetStructureEntity";
import {StructureEntity} from "../../model/StructureEntity";
import {Entity} from "../../model/AppInfo";
import {ResultsList} from "../../model/ResultsList";
import EntityActions from "./EntityActions";
import TaskGetEntityListUploaded from "../../ws/entity/TaskGetEntityListUploaded";
import {FiltersState} from "../../model/FiltersState";
import TaskCreateEntity from "../../ws/entity/TaskCreateEntity";
import TaskExportList from "../../ws/entity/TaskExportList";

const INITAL_STATE = {
    loadingStructure: false,
    loadingList: false,
    uploadingList: false,
    loadingEdit: false,
    loadingCreate: false,
    loadingGetEntity: false,
    exporting: false,
    errorList: '',
    errorGetEntity: '',
    errorStructure: '',
    errorEdit: {},
    list: {} as ResultsList,
    entity: {} as any,
    structure: {} as StructureEntity,
    currentEntity: {} as Entity,
    filters: {} as FiltersState,
    showDeleteModal: false,
    deletingItem: 0,
    deletingEntity: '',
};

const buildState = (state: any) => {
    return state;
};

export default ReducerBuilder.newBuilder(INITAL_STATE)

    .onEvent(EntityActions.setCurrentEntity, (oldState, payload) => buildState({ ...oldState, currentEntity: payload.entity }))
    .onEvent(EntityActions.setFilter, (oldState, payload) => buildState({
        ...oldState,
        filters: { ...oldState.filters, [payload.entity]: payload.filter }
    }))
    .onEvent(EntityActions.setExtendedResults, (oldState, payload) => buildState({
        ...oldState,
        filters: { ...oldState.filters, [payload.entity]: { ...oldState.filters[payload.entity], extendedResults: payload.active } }
    }))
    .onEvent(EntityActions.setGalleryView, (oldState, payload) => buildState({
        ...oldState,
        filters: {
            ...oldState.filters,
            [payload.entity]: {
                ...oldState.filters[payload.entity],
                galleryView: payload.active,
                haveToUpdate: oldState.filters[payload.entity].maxPerPageList !== oldState.filters[payload.entity].maxPerPageGallery,
                page: oldState.filters[payload.entity].maxPerPageList !== oldState.filters[payload.entity].maxPerPageGallery ? 1 : oldState.filters[payload.entity].page
            }
        }
    }))
    .onEvent(EntityActions.changeHiddenColumn, (oldState, payload) => buildState({
        ...oldState,
        filters: {
            ...oldState.filters,
            [payload.entity]: {
                ...oldState.filters[payload.entity],
                columns: {
                    ...oldState.filters[payload.entity].columns,
                    [payload.column] : {
                        ...oldState.filters[payload.entity].columns[payload.column],
                        showByDefault: !oldState.filters[payload.entity].columns[payload.column].showByDefault
                    }
                }
            }
        }
    }))
    .onEvent(EntityActions.showDeleteModal, (oldState, payload) => buildState({ ...oldState, showDeleteModal: true, deletingEntity: payload.entity, deletingItem: payload.id }))
    .onEvent(EntityActions.closeDeleteModal, (oldState) => buildState({ ...oldState, showDeleteModal: false }))

    .onEvent(TaskGetEntityList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => buildState({...oldState, errorList: payload, loadingList: false}))
    .onEvent(TaskGetEntityList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({...oldState, list: {} as any, errorList: '', loadingList: true}))
    .onEvent(TaskGetEntityList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({...oldState, loadingList: false}))
    .onEvent(TaskGetEntityList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => buildState({...oldState, errorList: '', list: payload}))

    .onEvent(TaskGetEntityListUploaded.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => buildState({...oldState, errorList: payload, uploadingList: false}))
    .onEvent(TaskGetEntityListUploaded.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({...oldState, errorList: '', uploadingList: true}))
    .onEvent(TaskGetEntityListUploaded.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({...oldState, uploadingList: false}))
    .onEvent(TaskGetEntityListUploaded.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => buildState({...oldState, list: payload}))

    .onEvent(TaskGetEntity.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => buildState({...oldState, errorGetEntity: payload, loadingGetEntity: false}))
    .onEvent(TaskGetEntity.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({...oldState, errorGetEntity: '', entity: {} as any, loadingGetEntity: true}))
    .onEvent(TaskGetEntity.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({...oldState, loadingGetEntity: false}))
    .onEvent(TaskGetEntity.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => buildState({...oldState, entity: payload}))

    .onEvent(TaskEditEntity.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => buildState({...oldState, errorEdit: payload, loadingEdit: false}))
    .onEvent(TaskEditEntity.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({...oldState, errorEdit: {}, loadingEdit: true}))
    .onEvent(TaskEditEntity.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({...oldState, loadingEdit: false}))
    .onEvent(TaskEditEntity.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => buildState({...oldState, entity: payload}))

    .onEvent(TaskCreateEntity.REDUX_ACTION_OBJECT_ON_FAIL, (oldState) => buildState({...oldState, loadingCreate: false}))
    .onEvent(TaskCreateEntity.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({...oldState, loadingCreate: true}))
    .onEvent(TaskCreateEntity.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({...oldState, loadingCreate: false}))
    .onEvent(TaskCreateEntity.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => buildState({...oldState, entity: payload}))

    .onEvent(TaskGetStructureEntity.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => buildState({...oldState, errorStructure: payload, loadingStructure: false}))
    .onEvent(TaskGetStructureEntity.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({...oldState, structure: {} as StructureEntity, loadingStructure: true}))
    .onEvent(TaskGetStructureEntity.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({...oldState, loadingStructure: false}))
    .onEvent(TaskGetStructureEntity.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => buildState({...oldState, structure: payload}))

    .onEvent(TaskExportList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({...oldState, exporting: true}))
    .onEvent(TaskExportList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({...oldState, exporting: false}))

    .build();
