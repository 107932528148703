const base = "";

export const ROUTE_ENTITY_LIST = base + "/:entity/list";
export const ROUTE_ENTITY_VIEW = base + "/:entity/view/:id";
export const ROUTE_ENTITY_EDIT = base + "/:entity/edit/:id";
export const ROUTE_ENTITY_CREATE = base + "/:entity/create";
export const ROUTE_ENTITY_IMPORT = base + "/:entity/import";

export const ROUTE_ACTION = base + "/action/:action";

export const ROUTE_RESET_PASSWORD_REQUEST = base + "/requestResetPassword";
export const ROUTE_RESET_PASSWORD = base + "/resetPassword/:token";