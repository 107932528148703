import React, { ReactNode } from "react";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ACTIONS,
    TR_BUTTON_DELETE,
    TR_BUTTON_EDIT,
    TR_BUTTON_REMOVE,
    TR_BUTTON_VIEW,
    TR_NO_RESULTS_FOUND
} from "../../../I18n/constants";
import '@fortawesome/fontawesome-free/css/all.css'
import { FieldList, FieldRelationForm } from "../../../model/StructureEntity";
import { Link } from "react-router-dom";
import { generateRoute } from "../../../utils/Router";
import { ROUTE_ENTITY_EDIT, ROUTE_ENTITY_VIEW } from "../../../routing/Routes";
import ShowField from "../../../components/field/ShowField";
import EntityActions from "../EntityActions";
import { ResultsList } from "../../../model/ResultsList";
import Paginator from "../List/Paginator";
import TaskGetEntityListUploaded from "../../../ws/entity/TaskGetEntityListUploaded";
import LoadingView from "../../../base/loading/LoadingView";
import { Col, Container, Row } from "reactstrap";

interface FormRelationsTableProps {
    structure: FieldRelationForm,
    data: any,
    relationFormValues: any,
    primaryColor?: string
    iconsColor?: string,
    entityRelation: string,
    entityRelationCamelcase: string,
    entity: string,
    parentEntity: string,
    oid?: number,
    relationKey: string,
    removeRelationFormValue: (relationKey: string, index: number) => void,
    addTableRelationFormValue: (relationKey: string, value: any) => void
    isEditScreen: boolean
}

interface StructureList {
    [dynamic: string]: FieldList
}

class FormRelationsTable extends React.Component<FormRelationsTableProps> {

    public state = {
        currentResults: {} as ResultsList,
        currentFilters: {} as any,
        loadingResults: true,
        data: this.props.data,
        relationFormValues: this.props.relationFormValues,
        removedEntities: false
    };

    public componentWillMount(): void {
        if (this.props.oid) {
            const currentFilterValues = {
                page: 1,
                orderBy: '',
                orderAsc: false,
                filters: this.getInitialFilter(),
                maxPerPageEdit: 10
            }
            this.setState({ currentFilters: currentFilterValues })
        }
    }

    private getInitialFilter(): any {
        if (this.props.structure.relationType == 'manyToMany') {
            return {
                [this.getFilterName()]: "",
                [this.props.relationKey + '.' + this.props.entity + '___array']: this.props.oid
            };
        }

        return {[this.props.parentEntity]: this.props.oid};
    }

    public componentDidMount(): void {
        if (this.props.oid) {
            this.getEntityResults();
        } else {
            this.setState({ loadingResults: false })
        }
    }

    public componentDidUpdate(_: Readonly<FormRelationsTableProps>) {
        if(this.props.data != this.state.data || this.state.removedEntities) {
            if (this.props.oid) {
                if(!this.state.removedEntities) {
                    this.setState({currentFilters: {...this.state.currentFilters, filters: this.getInitialFilter()}});
                }
                this.setState({
                    loadingResults: true,
                    data: this.props.data,
                    relationFormValues: this.props.relationFormValues,
                    removedEntities: false
                })
                this.getEntityResults();
            } else {
                this.setState({
                    loadingResults: false,
                    data: this.props.data,
                    relationFormValues: this.props.relationFormValues,
                    removedEntities: false
                });
            }
        }
    }

    public getEntityResults(): void {
        this.setState({ loadingResults: true })
        new TaskGetEntityListUploaded(
            this.props.entityRelation.toLowerCase(),
            this.state.currentFilters.page,
            this.state.currentFilters.filters,
            this.state.currentFilters.orderBy,
            this.state.currentFilters.orderAsc ? 'ASC' : 'DESC',
            this.state.currentFilters.maxPerPageEdit
        ).onSuccess((results) => {
            this.setState({ currentResults: results, loadingResults: false });
        })
            .execute();
    }

    public updateEntityResults(filters: any): void {
        this.setState({ loadingResults: true })
        new TaskGetEntityListUploaded(
            this.props.entityRelation.toLowerCase(),
            filters.page,
            filters.filters,
            filters.orderBy,
            filters.orderAsc ? 'ASC' : 'DESC',
            filters.maxPerPageEdit
        ).onSuccess((results) => {
            this.setState({ currentResults: results, currentFilters: filters, loadingResults: false });
        })
            .execute();
    }

    public render(): ReactNode {

        if (!this.state.currentResults.results || this.state.loadingResults) {
            return <LoadingView loading={this.state.loadingResults} />
        }

        if (this.state.currentResults.results.length > 0) {
            const structureList = this.getStructureList();
            const color = this.props.primaryColor ? this.props.primaryColor : ''

            return (
                <Container fluid={true}>
                    <Row>
                        <Col md="12">
                            <div className="table-responsive">
                                <table className="table">
                                    {this.renderHeader(structureList)}
                                    {this.renderContentTable(structureList)}
                                </table>
                                <Paginator
                                    currentPage={this.state.currentFilters.page}
                                    changePage={(page) => this.changePage(page)}
                                    primaryColor={color}
                                    pages={this.state.currentResults.pages}
                                    loading={false}
                                    numResults={this.state.currentResults.total}
                                    maxPerPage={this.state.currentFilters.maxPerPageEdit}
                                    changeMaxPerPage={(maxPerPageEdit) => this.changeMaxPerPage(maxPerPageEdit)} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
        }

        return (
            <div className={"col-md-12"}>
                <span>{I18nUtils.tr(TR_NO_RESULTS_FOUND)}</span>
            </div>
        );
    }

    public getStructureList(): StructureList {
        const structureList = {} as StructureList;
        if (this.props.structure.listStructure) {
            Object.keys(this.props.structure.listStructure).map((field) => {
                if (this.props.structure.listStructure![field].listableInExtended && this.props.structure.fieldsToShow.includes(field)) {
                    structureList[field] = this.props.structure.listStructure![field];
                }
            });
        }

        return structureList;
    }

    public renderHeader(structureList: StructureList): ReactNode {
        return (
            <thead className="text-primary">
                <tr style={{ color: this.props.primaryColor }}>
                    {
                        Object.keys(structureList).map((field) => {
                            const { orderBy } = structureList[field];
                            return (
                                <th key={field} className={structureList[field].colClassesTable}>
                                    <span style={{ cursor: orderBy === '' ? 'default' : 'pointer' }} onClick={() => this.changeOrder(orderBy)}>
                                        {structureList[field].label}
                                        {
                                            this.state.currentFilters.orderBy === orderBy ?
                                                <i className={`fa fa-caret-${this.state.currentFilters.orderAsc ? 'down' : 'up'}`} />
                                                : ''
                                        }
                                    </span>
                                </th>)
                        })
                    }
                    {this.headerActions()}
                </tr>
            </thead>
        );
    }

    public headerActions(): ReactNode {
        return <th className="list-actions-column">{I18nUtils.tr(TR_ACTIONS)}</th>;
    }

    public changeOrder(field: string) {
        if (field !== '') {
            const orderAsc = this.state.currentFilters.orderBy === field ? !this.state.currentFilters.orderAsc : true
            const currentFilters = {
                ...this.state.currentFilters,
                orderBy: field,
                orderAsc: orderAsc
            }
            this.updateEntityResults(currentFilters);
        }
    }

    public changePage(page: number) {
        const currentFilters = {
            ...this.state.currentFilters,
            page: page
        }
        this.updateEntityResults(currentFilters);
    }

    public changeMaxPerPage(maxPerPageEdit: number) {
        const currentFilters = {
            ...this.state.currentFilters,
            maxPerPageEdit: maxPerPageEdit,
            page: 1
        }
        this.updateEntityResults(currentFilters);
    }

    public renderContentTable(structureList: StructureList): ReactNode {
        const data = this.state.currentResults.results;
        return (
            <tbody>
                {
                    data.map((value: any, indexData: number) => {
                        return (
                            <tr key={indexData}>
                                {
                                    Object.keys(structureList).map((field) => {
                                        const fieldValue = value[field] || value[field] === 0 ? value[field] : '';
                                        return (
                                            <td key={field + indexData} className={structureList[field].colClassesTable + ' ' + 'column-field-' + structureList[field].type.toLowerCase()}>
                                                {ShowField(fieldValue, structureList[field].type, structureList[field].fieldsToShow)}
                                            </td>
                                        )
                                    })
                                }
                                {this.renderActionsColumn(value)}
                            </tr>
                        )
                    })
                }
            </tbody>
        )
    }

    public renderActionsColumn(entity: any): ReactNode {

        const id = entity.oid;
        const color = this.props.iconsColor;

        return (
            <td className='list-actions-column'>
                {
                    this.props.structure.canShowEntityRelation && id &&
                    <Link to={generateRoute(ROUTE_ENTITY_VIEW, { entity: this.props.entityRelation.toLowerCase(), id })} title={I18nUtils.tr(TR_BUTTON_VIEW)}>
                        <i className='fa fa-eye' style={{ color }} />
                    </Link>
                }
                {
                    this.props.structure.canEditEntityRelation && id &&
                    <Link to={generateRoute(ROUTE_ENTITY_EDIT, { entity: this.props.entityRelation.toLowerCase(), id })} title={I18nUtils.tr(TR_BUTTON_EDIT)}>
                        <i className='fa fa-edit' style={{ color }} />
                    </Link>
                }
                {
                    this.props.structure.canDeleteEntityRelation &&
                    <i className='fa fa-trash' style={{ color }} onClick={() => EntityActions.showDeleteModal.asConnectedAction(this.props.entityRelation.toLowerCase(), id)} title={I18nUtils.tr(TR_BUTTON_DELETE)} />
                }
                {
                    this.props.isEditScreen && this.props.structure.relationType == 'manyToMany' &&
                    <i className='fa fa-minus' style={{ color }} onClick={() => this.removeFromRelation(entity)} title={I18nUtils.tr(TR_BUTTON_REMOVE)} />
                }

            </td>
        );
    }

    private removeFromRelation(entity: any) {
        //Se añade a las relaciones para que se le envíe al backed y sea el quien elimine la relación
        this.props.addTableRelationFormValue(this.props.relationKey, entity);
        //se añade al filtro para que no se muestren las relaciones que se han quitado
        const filterName = this.getFilterName();
        let entitiesIds = this.state.currentFilters.filters[filterName];
        if (entitiesIds !== "") {
            entitiesIds = entitiesIds + ",";
        }
        const currentFilterValues = {
            ...this.state.currentFilters,
            filters: {
                ...this.state.currentFilters.filters,
                [filterName]: entitiesIds + entity.oid
            }
        }
        this.setState({ currentFilters: currentFilterValues, removedEntities: true})
    }

    private getFilterName(): string {
        return this.props.entityRelationCamelcase + ".oid___notinarray";
    }
}

export default FormRelationsTable;
