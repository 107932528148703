import React, {ReactNode} from "react";

export interface TextareaFieldProps {
    value: string,
    disabled?: boolean,
    onChange: (value, description?) => void
}

class TextareaField extends React.Component<TextareaFieldProps> {

    public render(): ReactNode {

        return (
            <textarea
                className="form-control"
                value={this.props.value}
                onChange={(e) => this.props.onChange(e.target.value)}
                disabled={this.props.disabled}
            />
        );
    }
}

export default TextareaField;