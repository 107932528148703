import {METHOD, ReduxAwareGenericRequest} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {ReduxStorageUtils} from "@cuatroochenta/co-redux-builders";

export interface TaskRefreshTokenResponse {
    token: string,
    refresh_token: string
}

export default class TaskRefreshToken extends ReduxAwareGenericRequest<FormData, TaskRefreshTokenResponse> {

    private readonly refreshToken: string;

    public constructor(refreshToken: string) {
        super(METHOD.METHOD_POST, Urls.getUrlRegenerateToken(), 'error', ReduxStorageUtils.dispatch);
        this.refreshToken = refreshToken;
    }

    protected getRequest(): FormData {
        const data: FormData = new FormData();
        data.append('refresh_token', this.refreshToken);
        return data;
    }
}