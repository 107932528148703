import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import {AppInfo} from "../../model/AppInfo";
import TaskGetAppInfo from "../../ws/app/TaskGetAppInfo";

const INITAL_STATE = {
    loadingInfo: true,
    appInfo: {} as AppInfo
};

const buildState = (state: any) => {
    return state;
};

export default ReducerBuilder.newBuilder(INITAL_STATE)
    .onEvent(TaskGetAppInfo.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => buildState({...oldState, error: payload, loadingInfo: false}))
    .onEvent(TaskGetAppInfo.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({...oldState, loadingInfo: true}))
    .onEvent(TaskGetAppInfo.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({...oldState, loadingInfo: false}))
    .onEvent(TaskGetAppInfo.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => buildState({...oldState, loadingInfo: false, appInfo: payload}))

    .build();
