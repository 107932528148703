import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";
import {RelationsValuesInterface} from "../../model/StructureEntity";

export default class TaskEditEntity extends BaseGenericRequest<{}> {

    private readonly data;
    private readonly relations;

    public constructor(entity: string, id: number, data: any, relations: RelationsValuesInterface) {
        super(METHOD.METHOD_POST, generateRoute(Urls.getUrlGetEntityEdit(), {entity, id}));
        this.data = data;
        this.relations = relations;
    }

    protected getRequest(): {} {
        return {entity: this.data, relations: this.relations};
    }
}