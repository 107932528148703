import React, {ReactNode} from "react";
import AutoSuggest from "react-autosuggest";
import TaskGetAutoSuggestOptions, {AutoSuggestExclude} from "../../../ws/entity/TaskGetAutoSuggestOptions";
import {FieldFValue} from "../../../model/StructureEntity";
import InputTextField from "./InputTextField";
import preventEnterSubmit from "../preventEnterSubmit";

interface AutoSuggestProps {
    value: string,
    initialDescriptionAutoSuggest: string,
    entity: string,
    field: string,
    allFields?: any,
    fieldKey?: string,
    disabled?: boolean,
    exclude?: AutoSuggestExclude,
    onEnterSubmit?: () => void,
    emptyWhenSelected?: boolean,
    onChange: (value, description?, object?) => void
}

class AutoSuggestField extends React.Component<AutoSuggestProps> {

    public state = {value: '', id: '', suggestions: [] as FieldFValue[], modified: false, typing: false};

    public componentWillMount(): void {
        this.setState({id: this.props.value, value: this.props.initialDescriptionAutoSuggest ? this.props.initialDescriptionAutoSuggest : ''});
    }

    public componentDidUpdate(prevProps: Readonly<AutoSuggestProps>, prevState: Readonly<any>, _snapshot?: any): void {
        if (this.props.value !== prevProps.value && this.props.onEnterSubmit) {
            this.props.onEnterSubmit();
        }

        if (!this.props.value && this.state.id && !(this.state.id !== prevState.id)) {
            // se ha vaciado en TaskGetCustomFormEntity
            this.setState({id: '', value: ''});
        }
    }

    public onSuggestionsFetchRequested = ({ value }) => {
        if (this.state.value !== value && value.trim() !== '') {
            this.setState({typing: true, modified: true});
            this.onChangeField('', value, '');
            setTimeout(() => {
                if (this.state.value === value) {
                    new TaskGetAutoSuggestOptions(
                        this.props.entity,
                        this.props.fieldKey ? this.props.fieldKey : this.props.field,
                        value,
                        this.props.exclude ? this.props.exclude : {} as AutoSuggestExclude,
                        this.props.allFields
                    )
                        .onSuccess((options: FieldFValue[]) => {
                            this.setState({suggestions: options, typing: false})
                        })
                        .execute();
                }
            }, 500);
        }
    };

    public onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    public onChange = (_event, { newValue }) => {
        if (!this.props.disabled) {
            if (newValue.trim() === '') {
                this.setState({value: '', suggestions: [], modified: false});
                this.onChangeField('', '', '');
            }
            else {
                this.setState({value: newValue});
            }
        }
    };

    public onChangeField(id, description, object) {
        this.setState({id: id, value: description});
        this.props.onChange(id, description, object);
    }

    public getSuggestionValue = (suggestion: FieldFValue) => {
        this.onChangeField(suggestion.id, suggestion.description, suggestion.object);
        this.setState({modified: false, value: ''});

        return suggestion.description;
    };

    public render(): ReactNode {

        const inputProps = {
            value: this.props.emptyWhenSelected && !this.state.modified ? '' : this.state.value,
            onChange: this.onChange,
            onKeyDown: preventEnterSubmit,
        };

        if (this.props.disabled) {
            return <InputTextField {...this.props} type="text" size={255} value={this.state.value}/>;
        }

        return (
            <>
            <div className={this.state.typing ? 'typing' : this.state.modified ? 'error-autosuggest' : ''}/>
            <AutoSuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={(suggestion: FieldFValue) => <div>{suggestion.description}</div>}
                inputProps={inputProps}
            />
            </>
        );
    }
}

export default AutoSuggestField;