import React, {ReactNode} from "react";
import {FieldFValue, FieldRelationForm, RelationsValuesInterface, FormRelationsInterface} from "../../../model/StructureEntity";
import ShowFieldForm from "../../../components/field/ShowFieldForm";
import {Tab, Tabs} from "react-bootstrap";
import AutoSuggestField from "../../../components/field/types/AutoSuggestField";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_ADD_RELATION_ENTITY} from "../../../I18n/constants";
import SelectorField from "../../../components/field/types/SelectorField";
import {generateRoute} from "../../../utils/Router";
import {ROUTE_ENTITY_EDIT, ROUTE_ENTITY_VIEW} from "../../../routing/Routes";
import {Link} from "react-router-dom";
import {AutoSuggestExclude} from "../../../ws/entity/TaskGetAutoSuggestOptions";

interface FormRelationsProps {
    formRelations: FormRelationsInterface,
    entity: string,
    disabled?: boolean,
    changeRelation: (relation: string, idRelation: number, checked: boolean, description: string) => void,
    relationsValues: RelationsValuesInterface,
    isEditScreen: boolean,
}


class FormRelations extends React.Component<FormRelationsProps> {

    public state = {tab: '', canRender: false, relationsTabs: [] as string[]};

    public componentWillMount(): void {
        const {formRelations} = this.props;
        const relationsTabs = Object.keys(formRelations).filter((key) => formRelations[key].type !== 'FORM');
        this.setState({
            relationsTabs,
            canRender: !!relationsTabs,
            tab: relationsTabs ? this.props.formRelations[0] : ''
        });
    }

    public renderCheckBoxOptions(relation: string, _label: string, values: FieldFValue[], entityRelation: string, canEditEntityRelation: boolean, canShowEntityRelation: boolean): ReactNode {
        return (
            <div className="col-md-12">
                <div className="list-checkboxes row">
                    {
                        values.map((value) => {
                            let checked = this.props.relationsValues[relation] && this.props.relationsValues[relation][value.id] && this.props.relationsValues[relation][value.id].checked;
                            if (!!value.hide && checked) {
                                checked = false;
                                this.props.changeRelation(relation, value.id, false, value.description);
                            }
                            return (
                                <div key={value.id} className={"col-xs-12 col-sm-6 col-md-4 col-lg-3" + (!!value.hide ? ' d-none' : '')}>
                                    <ShowFieldForm
                                        entity={this.props.entity}
                                        value={checked ? '1' : ''}
                                        initialDescriptionAutoSuggest=""
                                        field={relation}
                                        size={0}
                                        type="CHECKBOX"
                                        changeField={(val) => {
                                            this.props.changeRelation(relation, value.id, val, value.description)
                                        }}
                                        required={false}
                                        renderRequiredErrors={false}
                                        renderErrors={false}
                                        values={[]}
                                        disabled={this.props.disabled}
                                        isEditScreen={this.props.isEditScreen}
                                        label={value.description}
                                        linkTo={
                                            canEditEntityRelation ? generateRoute(ROUTE_ENTITY_EDIT, {entity: entityRelation.toLowerCase(), id: value.id}) :
                                                canShowEntityRelation ? generateRoute(ROUTE_ENTITY_VIEW, {entity: entityRelation.toLowerCase(), id: value.id}) : ''
                                        }
                                        iconTo={
                                            canEditEntityRelation ? 'fa fa-edit' :
                                                canShowEntityRelation ? 'fa fa-eye' : ''
                                        }
                                    />
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        )
    }

    public getExcludeIds(relation: string): number[] {
        const exclude: number[] = [];

        const relations = this.props.relationsValues[relation];
        if (relations) {
            Object.keys(relations).map((idRelation) => {
                if (relations[idRelation].checked) {
                    exclude.push(parseInt(idRelation, 0));
                }
            });
        }

        return exclude;
    }

    public renderAutoSuggestOptions(relation: string, label: string): ReactNode {
        return (
            <div key={relation}
                 className="col-md-12">
                <div className="form-group">
                    <label>{I18nUtils.tr(TR_ADD_RELATION_ENTITY).replace(':entity', label)}</label>
                    <AutoSuggestField
                        entity={this.props.entity}
                        value={''}
                        initialDescriptionAutoSuggest=""
                        field={relation}
                        onChange={(val, description) => {
                            this.props.changeRelation(relation, val, true, description)
                        }}
                        disabled={this.props.disabled}
                        exclude={{excludeIds: this.getExcludeIds(relation)} as AutoSuggestExclude}
                        emptyWhenSelected={true}
                    />
                </div>
            </div>
        )
    }

    public renderSelectOption(relation: string, label: string, values: FieldFValue[]): ReactNode {

        const relations = this.props.relationsValues[relation];
        const valuesToShow: FieldFValue[] = [];

        values.map((value) => {
            const checked = relations && relations[value.id] && relations[value.id].checked;
            if (!value.hide && !checked) {
                valuesToShow.push(value);
            }

            if (!!value.hide && checked) {
                this.props.changeRelation(relation, value.id, false, value.description);
            }
        });

        return (
            <div key={relation}
                 className="col-md-12">
                <div className="form-group">
                    <label>{I18nUtils.tr(TR_ADD_RELATION_ENTITY).replace(':entity', label)}</label>
                    <SelectorField
                        value={''}
                        onChange={(val: number) => {
                            const fieldValue: FieldFValue = values.find((f) => f.id === val) || {} as FieldFValue;
                            this.props.changeRelation(relation, val, true, fieldValue.description)
                        }}
                        values={valuesToShow}
                        disabled={this.props.disabled}
                    />
                </div>
            </div>
        )
    }

    public renderListActiveOptions(relationKey: string, canEdit: boolean, entityRelation: string, canEditEntityRelation: boolean, canShowEntityRelation: boolean): ReactNode {

        const relations = this.props.relationsValues[relationKey];

        if (relations) {
            const arrayRelations = [] as any;
            Object.keys(relations).map((idRelation) => {
                const relation = relations[idRelation];
                arrayRelations[relation.order] = {
                    checked: relation.checked && !relation.hide,
                    description: relation.description,
                    id: idRelation
                };
            });

            return (
                <div className="col-md-12">
                    <div className="list-active-relations row">
                        {
                            Object.keys(arrayRelations).map((idRelation) => {
                                const relation = arrayRelations[idRelation];
                                if (relation.checked) {
                                    return (
                                        <div className="col-md-6 col-sm-12" key={relation.id}>
                                            <div className="shadow mb-3 rounded">
                                                {relation.description}
                                                {
                                                    canEditEntityRelation ?
                                                        <Link to={generateRoute(ROUTE_ENTITY_EDIT, {entity: entityRelation.toLowerCase(), id: relation.id})}><i className="fa fa-edit"/></Link> :
                                                        canShowEntityRelation ?
                                                            <Link to={generateRoute(ROUTE_ENTITY_VIEW, {entity: entityRelation.toLowerCase(), id: relation.id})}><i className="fa fa-eye"/></Link> : ''
                                                }
                                                {
                                                    canEdit ?
                                                        <span onClick={() => this.props.changeRelation(relationKey, relation.id, false, relation.description)}>X</span> :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                return null;
                            })
                        }
                    </div>
                </div>
            )
        }
        return <></>
    }

    public renderContentRelations(relationKey: string, relation: FieldRelationForm): ReactNode {
        if (this.props.disabled || !relation.canEdit) {
            return this.renderListActiveOptions(relationKey, false, relation.entityRelation, relation.canEditEntityRelation, relation.canShowEntityRelation);
        }
        switch (relation.type) {
            case 'AUTOSUGGEST':
                return (
                    <>
                        {this.renderListActiveOptions(relationKey, true, relation.entityRelation, relation.canEditEntityRelation, relation.canShowEntityRelation)}
                        {this.renderAutoSuggestOptions(relationKey, relation.label)}
                    </>
                );
            case 'CHECKBOX':
                return this.renderCheckBoxOptions(relationKey, relation.label, relation.values, relation.entityRelation, relation.canEditEntityRelation, relation.canShowEntityRelation);
            case 'SELECT':
                return (
                    <>
                        {this.renderListActiveOptions(relationKey, true, relation.entityRelation, relation.canEditEntityRelation, relation.canShowEntityRelation)}
                        {this.renderSelectOption(relationKey, relation.label, relation.values)}
                    </>
                );
            default:
                return null;
        }
    }

    public render(): ReactNode {

        if (!this.state.canRender) {
            return <></>
        }

        const formRelations = this.props.formRelations;

        return (
            <div className="col-md-12" id="relations-tabs">
                <Tabs id="relations" activeKey={this.state.tab} onSelect={(tab) => this.setState({tab})}>
                    {
                        this.state.relationsTabs.map((relationKey) => {
                            const relation = formRelations[relationKey];
                            if (relation.type === 'FORM' || relation.type === 'TABLE') {
                                return <></>;
                            }
                            const classRelation = relation.customClasses ? relation.customClasses : '';
                            return (
                                <Tab
                                    key={relationKey}
                                    eventKey={relationKey}
                                    title={relation.pluralLabel}
                                    tabClassName={classRelation}
                                >
                                    <div className={"row " + classRelation}>
                                        {this.renderContentRelations(relationKey, relation)}
                                    </div>
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            </div>
        );

    }
}

export default FormRelations;
