import React, {ReactNode} from "react";
import { ChromePicker } from 'react-color'

import 'react-datepicker/dist/react-datepicker.css';
import preventEnterSubmit from "../preventEnterSubmit";

export interface ColorFieldProps {
    value: string,
    disabled?: boolean,
    onEnterSubmit?: () => void,
    onChange: (value, description?) => void
}

class ColorField extends React.Component<ColorFieldProps> {

    public state = {opened: false};

    public isDark() {
        if (this.props.value) {
            const r = parseInt(this.props.value.substr(1, 2), 0);
            const g = parseInt(this.props.value.substr(3, 2), 0);
            const b = parseInt(this.props.value.substr(5, 2), 0);
            if (Math.sqrt( 0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b) ) < 127.5) {
                return true
            }
        }
        return false;
    }


    public render(): ReactNode {

        const {value} = this.props;

        return (
            <div>
                <input type='text'
                       className="form-control input-color"
                       value={this.props.value}
                       onClick={() => this.setState({opened: !this.props.disabled})}
                       style={{backgroundColor: value ? value : '#fff', color: this.isDark() ? '#fff' : '#000'}}
                       onChange={(e) => this.props.onChange(e.target.value)}
                       disabled={this.props.disabled}
                       onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => preventEnterSubmit(event, this.props.onEnterSubmit)}
                />
                <div className={`wrapper-color-picker ${this.state.opened ? '' : 'd-none'}`} onMouseLeave={() => this.setState({opened: false})}>
                    <ChromePicker onChange={(color) => this.props.onChange(color.hex)} disableAlpha={true} color={value}/>
                </div>
            </div>
        );
    }
}

export default ColorField;