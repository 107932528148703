import React, { ReactNode } from "react";
import EntityReducer from "../EntityReducer";
import { connect } from "react-redux";
import TaskGetEntity from "../../../ws/entity/TaskGetEntity";
import I18nUtils from "../../../I18n/I18nUtils";
import { TR_BUTTON_EDIT, TR_GENERIC_ERROR_ENTITY, TR_LIST_BACK } from "../../../I18n/constants";
import { Link } from "react-router-dom";
import { generateRoute } from "../../../utils/Router";
import { ROUTE_ENTITY_EDIT, ROUTE_ENTITY_LIST } from "../../../routing/Routes";
import { Card, CardBody, Col, Row } from "reactstrap";
import LoadingView from "../../../base/loading/LoadingView";
import EntityForm from "../Edit/EntityForm";
import { FormRenderData } from '../../../Module';
import DeleteModal from "../Delete/DeleteModal";
import EntityActions from "../EntityActions";

const mapStateToProps = EntityReducer.autoMapToProps();

interface ViewScreenProps {
    id: number,
    languages: string[],
    primaryColor: string,
    iconsColor: string,
    beforeFormView?: (entity: string, data: FormRenderData) => React.ReactNode,
    afterFormView?: (entity: string, data: FormRenderData) => React.ReactNode,
}

class ViewScreen extends React.Component<ViewScreenProps & typeof mapStateToProps> {

    public state = { render: false };

    public componentWillMount(): void {
        new TaskGetEntity(this.props.currentEntity.entity, this.props.id, this.props.currentEntity.needsTranslations)
            .onSuccess(() => this.setState({ render: true }))
            .onFail(() => this.setState({ render: true }))
            .execute();
    }

    public renderDeleteModal(): ReactNode {

        return (
            <DeleteModal
                entity={this.props.deletingEntity}
                onClose={() => {
                    EntityActions.closeDeleteModal.asConnectedAction()
                }}
                onDelete={() => {
                    EntityActions.closeDeleteModal.asConnectedAction();
                    this.updateEntity();
                }}
                show={this.props.showDeleteModal}
                id={this.props.deletingItem}
                name={this.props.structure.moreInfo.label}
                genre={this.props.structure.moreInfo.genre}
                primaryColor={this.props.primaryColor}
            />
        );
    }

    public updateEntity() {
        this.setState({ render: false })
        new TaskGetEntity(this.props.currentEntity.entity, this.props.id, this.props.currentEntity.needsTranslations)
            .onSuccess(() => this.setState({ render: true }))
            .onFail(() => this.setState({ render: true }))
            .execute();
    }

    public renderButtonsTop(): ReactNode {
        return (
            <div className="clearfix">
                {
                    this.props.currentEntity.singleton ?
                        null :
                        <Link to={generateRoute(ROUTE_ENTITY_LIST, { entity: this.props.currentEntity.entity })}>
                            <div className="btn btn-light button-back">
                                <i className="fa fa-arrow-left pr-2"/>
                                {I18nUtils.tr(TR_LIST_BACK)}
                            </div>
                        </Link>
                }
                {
                    !this.props.errorGetEntity && this.props.entity.__permissions.edit ?
                        <Link to={generateRoute(ROUTE_ENTITY_EDIT, { entity: this.props.currentEntity.entity.toLowerCase(), id: this.props.id })}>
                            <div className="btn btn-light button-back pull-right">
                                <i className="fa fa-crog pr-2 pull-right"/>
                                {I18nUtils.tr(TR_BUTTON_EDIT)}
                            </div>
                        </Link> : null
                }
            </div>
        );
    }

    public renderViewContent(): ReactNode {

        if (this.props.errorGetEntity || !this.props.entity.__permissions.show) {
            return <div className="alert alert-danger fade show">{I18nUtils.tr(TR_GENERIC_ERROR_ENTITY)}</div>
        }

        return (
            <div>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <LoadingView loading={this.props.loadingEdit}/>
                                <EntityForm
                                    entity={this.props.currentEntity.entity}
                                    structure={this.props.structure}
                                    data={this.props.entity}
                                    name={this.props.currentEntity.name}
                                    disabled={true}
                                    languages={this.props.languages}
                                    isEditScreen={false}
                                    oid={this.props.id}
                                    beforeFormView={this.props.beforeFormView}
                                    afterFormView={this.props.afterFormView}
                                    primaryColor={this.props.primaryColor}
                                    iconsColor={this.props.iconsColor}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

    public render(): ReactNode {

        return this.props.loadingGetEntity || !this.state.render ?
            <LoadingView loading={true}/> :
            <>
                { this.renderButtonsTop() }
                { this.renderViewContent() }
                { this.renderDeleteModal() }
            </>

    }
}

export default connect(mapStateToProps)(ViewScreen) as React.ComponentType<ViewScreenProps>;
