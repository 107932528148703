import React, {ReactNode} from "react";
import {
    TR_ERROR_FIELD, TR_ERROR_FIELD_COLOR,
    TR_ERROR_FIELD_EMAIL,
    TR_ERROR_FIELD_INTEGER, TR_ERROR_FIELD_PASSWORD,
    TR_ERROR_FIELD_PHONE,
    TR_REQUIRED_FIELD, TR_REQUIRED_FIELD_FILE, TR_REQUIRED_FIELD_IMAGE
} from "../../I18n/constants";
import I18nUtils from "../../I18n/I18nUtils";
import validateRequired from "./validateRequired";

interface FieldErrorProps {
    value: string,
    type: string,
    error: boolean,
    required: boolean,
    renderErrors: boolean,
    renderRequiredErrors: boolean,
    modified: boolean,
    isEditScreen: boolean
}

class RenderErrorsField extends React.Component<FieldErrorProps> {

    public renderErrorText() {
        switch (this.props.type) {
            case 'PHONE':
                return TR_ERROR_FIELD_PHONE;
            case 'EMAIL':
                return TR_ERROR_FIELD_EMAIL;
            case 'COLOR':
                return TR_ERROR_FIELD_COLOR;
            case 'INTEGER':
                return TR_ERROR_FIELD_INTEGER;
            case 'PASSWORD':
                return TR_ERROR_FIELD_PASSWORD;
        }
        return TR_ERROR_FIELD;
    }

    public renderRequiredErrorText() {
        switch (this.props.type) {
            case 'IMAGE':
                return TR_REQUIRED_FIELD_IMAGE;
            case 'FILE':
                return TR_REQUIRED_FIELD_FILE;
        }
        return TR_REQUIRED_FIELD;
    }

    public render(): ReactNode {

        const errorRequired = !this.props.error && !validateRequired(this.props.required, this.props.type, this.props.value, this.props.isEditScreen)
            && (this.props.renderRequiredErrors || (this.props.renderErrors && this.props.modified));

        return (
            <>
                <div className={`invalid-tooltip ${this.props.error && this.props.renderErrors ? '' : 'd-none'}`}>
                    {I18nUtils.tr(this.renderErrorText())}
                </div>
                <div className={`invalid-tooltip ${errorRequired ? '' : 'd-none'}`}>
                    {I18nUtils.tr(this.renderRequiredErrorText())}
                </div>
            </>
        );

    }

}

export default RenderErrorsField