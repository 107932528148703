import {METHOD, ReduxAwareGenericRequest} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import {ReduxStorageUtils} from "@cuatroochenta/co-redux-builders";

interface Login {
    username: string,
    password: string
}

export interface TaskLoginResponse {
    token: string,
    refresh_token: string,
    user: {
        id: number,
        username: string,
    }
}

export default class TaskLogin extends ReduxAwareGenericRequest<Login, TaskLoginResponse> {

    private readonly username: string;
    private readonly password: string;

    public constructor(username: string, password: string) {
        super(METHOD.METHOD_POST, Urls.getUrlLogin(), 'error', ReduxStorageUtils.dispatch);
        this.username = username;
        this.password = password;
        this.addHeader("Accept", "application/json");
        this.addHeader("Content-type", "application/json");
    }

    protected getRequest(): Login {
        return {username: this.username, password: this.password};
    }
}
