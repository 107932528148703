import React, {ReactNode} from "react";
import {Action} from "../../model/AppInfo";
import validateRequired from "../../components/field/validateRequired";
import validate from "../../components/field/validate";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_ERROR_FORM} from "../../I18n/constants";
import ShowFieldForm from "../../components/field/ShowFieldForm";
import TaskExecuteAction from "../../ws/action/TaskExecuteAction";
import LoadingView from "../../base/loading/LoadingView";

interface EntityFormProps {
    action: Action,
    primaryColor?: string,
    loading: boolean
}

class ActionForm extends React.Component<EntityFormProps> {

    public state = {fields: {} as any, renderErrors: true, renderRequiredErrors: false, errors: {} as any, showMessageError: false, submit: false, messageSuccess: '', messageError: '', keyForm: 1};
    
    public componentWillMount(): void {
        this.setState({fields: this.getInitialData(), errors: this.getInitialErrors()});
    }

    public getInitialData() {
        const data: any = {};
        const fields = this.props.action.fields;
        fields.map((field) => {
            data[field.name] = field.defaultValue;
        });

        return data
    }

    public getInitialErrors() {

        const errors: any = {};
        const {fields} = this.props.action;
        fields.map((field) => {
            errors[field.name] = !validateRequired(field.required, field.type, field.defaultValue, true) || !validate(field.type, this.state.fields[field.name]);
        });

        return errors
    }

    public changeField(value, field: string): void {
        this.setState({fields: {...this.state.fields, [field]: value}});
    }

    public addFieldError(error: boolean, field: string, _lang?: string): void {
        this.setState({errors: {...this.state.errors, [field]: error}});
        if (this.state.showMessageError) {
            this.checkErrors({...this.state.errors, [field]: error});
        }
    }

    public onSubmit(): void {
        this.setState({renderRequiredErrors: true, submit: true});
        if (this.checkErrors(this.state.errors)) {
            new TaskExecuteAction(this.state.fields, this.props.action.urlSubmitForm)
                .onSuccess((response) => {
                    this.setState({
                        fields: this.getInitialData(),
                        errors: this.getInitialErrors(),
                        renderRequiredErrors: false,
                        showMessageError: false,
                        submit: false,
                        messageSuccess: response.message,
                        messageError: '',
                        keyForm: this.state.keyForm + 1
                    });
                    document.getElementsByClassName("main-panel")[0].scrollTop = 0;
                    window.scrollTo(0, 0);
                    setTimeout(() => this.setState({messageSuccess: ''}), 10000);
                })
                .onFail((messageError) => {
                    this.setState({error: true, submitError: {} as any, messageSuccess: '', messageError});
                    document.getElementsByClassName("main-panel")[0].scrollTop = 0;
                })
                .execute();
        }
    }

    public checkErrors(errors): boolean {
        let showMessageError = false;
        Object.keys(errors).map((index) => {
            if (errors[index] === true) {
                showMessageError = true;
            }
        });
        this.setState({showMessageError});
        return !showMessageError;
    }

    public renderFieldsForm(): ReactNode {

        return this.props.action.fields.map((field, key) => {

            return (
                <div key={key} className={field.colClasses ? field.colClasses : 'col-md-12'}>
                    <div className="form-group">
                        <label>{field.label}{field.required ? '*' : ''}</label>
                        <ShowFieldForm
                            key={field.name + this.state.keyForm}
                            value={this.state.fields[field.name]}
                            initialDescriptionAutoSuggest={''}
                            field={field.name}
                            entity={''}
                            size={field.size}
                            type={field.type}
                            changeField={(val) => { this.changeField(val, field.name) }}
                            addFieldError={(error) => { this.addFieldError(error, field.name) }}
                            required={field.required}
                            renderRequiredErrors={this.state.renderRequiredErrors}
                            renderErrors={true}
                            values={field.values}
                            disabled={false}
                            isEditScreen={true}
                        />
                    </div>
                </div>
            )
        })
    }


    public renderMessages(): ReactNode {
        if (this.state.showMessageError) {
            return (
                <div className='col-md-12'>
                    <div className="alert alert-danger fade show">{I18nUtils.tr(TR_ERROR_FORM)}</div>
                </div>
            );
        }
        if (this.state.messageSuccess) {
            return (
                <div className='col-md-12'>
                    <div className="alert alert-success fade show">{this.state.messageSuccess}</div>
                </div>
            );
        }
        if (this.state.messageError) {
            return (
                <div className='col-md-12'>
                    <div className="alert alert-danger fade show">{this.state.messageError}</div>
                </div>
            );
        }
        return null;
    }


    public render(): ReactNode {
        return (
            <form onSubmit={(e) => {e.preventDefault(); this.onSubmit()}} className="entity-form action-form">
                <LoadingView loading={this.props.loading}/>
                <div className="row content-action-form">
                    {this.renderMessages()}
                    {this.renderFieldsForm()}
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-custom" style={{backgroundColor: this.props.primaryColor}}>
                            {this.props.action.button}
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}

export default ActionForm;