import ConfigModule from "../ConfigModule";
import I18nUtils from "../I18n/I18nUtils";

const BASE_URL = ConfigModule.BASE_URL;

const getUrlEndpoint = (url: string) => {
    return BASE_URL + I18nUtils.getLocale() + '/' + 'api/' + url;
}

const getUrlEndpointPrivate = (url: string) => {
    return getUrlEndpoint('private/' + url);
}

const getUrlEndpointPublic = (url: string) => {
    return getUrlEndpoint('public/' + url);
}

export default class {
    static getUrlAppInfo = (isPrivate: boolean) => {
        return isPrivate ? getUrlEndpointPrivate('appinfo') : getUrlEndpointPublic('appinfo')
    }

    static getUrlLogin = () => { return getUrlEndpoint('login_check') }
    static getUrlLoginGoogle = () => { return getUrlEndpointPublic('user/googlelogin') }
    static getUrlLoginSaml = () => { return getUrlEndpointPublic('user/saml/login') }
    static getUrlRegenerateToken = () => { return getUrlEndpoint('token/refresh') }
    static getUrlLogout = () => { return getUrlEndpoint('logout') }

    static getUrlResetPasswordRequest = () => { return getUrlEndpointPublic('user/password/reset/request') }
    static getUrlResetPassword = () => { return getUrlEndpointPublic('user/password/reset/action') }

    static getUrlGetEntity = () => { return getUrlEndpointPrivate(':entity/:id/view/extended') }
    static getUrlGetEntityTranslations = () => { return getUrlEndpointPrivate(':entity/:id/view/extended/translations') }
    static getUrlGetEntityList = () => { return getUrlEndpointPrivate(':entity/list/extended/:page') }
    static getUrlGetEntityStructure = () => { return getUrlEndpointPrivate(':entity/structure') }

    static getUrlGetEntityEdit = () => { return getUrlEndpointPrivate(':entity/:id/edit') }
    static getUrlGetEntityCreate = () => { return getUrlEndpointPrivate(':entity/new') }
    static getUrlGetEntityDelete = () => { return getUrlEndpointPrivate(':entity/:id/delete') }

    static getUrlGetEntityExport = () => { return getUrlEndpointPrivate(':entity/list/export') }
    static getUrlGetEntityImport = () => { return getUrlEndpointPrivate(':entity/import') }

    static getUrlGetAutosuggestOptions = () => { return getUrlEndpointPrivate(':entity/autosuggest') }
    static getUrlGetCustomOptions = () => { return getUrlEndpointPrivate(':entity/form_custom') }

    static getUrlExecuteAction = () => { return getUrlEndpoint(':slug') }

    static getUrlUploadFile = () => { return getUrlEndpointPrivate('utility/upload_media') }
}
