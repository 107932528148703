import {ActionBuilder} from "@cuatroochenta/co-redux-builders";
import {Entity} from "../../model/AppInfo";
import {FilterState} from "../../model/FiltersState";

export default ActionBuilder.build({
    setCurrentEntity: (entity: Entity) => ({entity}),
    setFilter: (entity: string, filter: FilterState) => ({entity, filter}),
    showDeleteModal: (entity: string, id: number) => ({entity, id}),
    closeDeleteModal: () => ({}),
    setExtendedResults: (entity: string, active: boolean) => ({entity, active}),
    changeHiddenColumn: (entity: string, column: string) => ({entity, column}),
    setGalleryView: (entity: string, active: boolean) => ({entity, active}),
});