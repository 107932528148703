import React, {ReactNode} from "react";
import EntityReducer from "../EntityReducer";
import {connect} from "react-redux";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_DELETE,
    TR_DOWNLOAD_IMPORT_EXAMPLE,
    TR_ERROR_PERMISSION_IMPORT,
    TR_ERROR_UPLOAD_FILE,
    TR_FILES_FOR_COLUMN,
    TR_FIRST_IMPORT_FILES_CSV,
    TR_IMAGES_FOR_COLUMN,
    TR_IMPORT,
    TR_IMPORT_ERROR_EXT,
    TR_IMPORTANT,
    TR_LIST_BACK,
    TR_OK_IMPORT_FEMALE,
    TR_OK_IMPORT_MALE,
    TR_TO_IMPORT_CSV,
    TR_UPLOAD_CSV_FILE,
    TR_UPLOAD_FILES,
    TR_UPLOAD_IMAGES,
    TR_NO_SELECTED_FILE,
} from "../../../I18n/constants";
import {Link} from "react-router-dom";
import {generateRoute} from "../../../utils/Router";
import {ROUTE_ENTITY_LIST} from "../../../routing/Routes";
import {Card, CardBody, Col, Row} from "reactstrap";
import TaskImportDataEntity from "../../../ws/entity/TaskImportDataEntity";
import LoadingView from "../../../base/loading/LoadingView";
import TaskUploadFile from "../../../ws/entity/TaskUploadFile";

const mapStateToProps = EntityReducer.autoMapToProps();

interface ImportScreenProps {
    primaryColor: string
}

interface UploadFiles {
    [dynamic: string]: {
        [dynamic: string]: string
    }
}

interface LoadingFiles {
    [dynamic: string]: number;
}

class ImportScreen extends React.Component<ImportScreenProps & typeof mapStateToProps> {

    public state = {error: '', loading: false, importFile: {} as File, imported: false, canImport: false, uploadedFiles: {} as UploadFiles, errorFiles: {} as UploadFiles, loadingFiles:  {} as LoadingFiles};

    public renderButtonsTop(): ReactNode {
        return (
            <div className="clearfix">
                <Link to={generateRoute(ROUTE_ENTITY_LIST, {entity: this.props.currentEntity.entity})}>
                    <div className="btn btn-light button-back">
                        <i className="fa fa-arrow-left pr-2"/>
                        {I18nUtils.tr(TR_LIST_BACK)}
                    </div>
                </Link>
                <a href={this.props.currentEntity.pdfImport} target="_blank">
                    <div className="btn btn-light button-back pull-right">
                        <i className="fa fa-download pr-2"/>
                        {I18nUtils.tr(TR_DOWNLOAD_IMPORT_EXAMPLE)}
                    </div>
                </a>
            </div>
        );
    }

    public onSubmit(): void {
        if (this.state.importFile) {
            this.setState({loading: true, error: ''});
            new TaskImportDataEntity(this.props.currentEntity.entity, this.state.importFile, this.state.uploadedFiles)
                .onSuccess(() => {
                    document.getElementsByClassName("main-panel")[0].scrollTop = 0;
                    this.setState({error: '', loading: false, imported: true, uploadedFiles: {} as UploadFiles, errorFiles: {} as UploadFiles, loadingFiles:  {} as LoadingFiles});
                })
                .onFail((response) => {
                    try {
                        const error = JSON.parse(response);
                        this.setState({error, loading: false});
                    } catch (e) {
                        this.setState({errorFiles: I18nUtils.tr(TR_ERROR_UPLOAD_FILE), loading: false});
                    }
                })
                .execute()
        }
    }

    public onUploadFile(importFile: File | null): void {
        this.setState({imported: false, importFile});
        if (importFile) {
            const ext = importFile.name.slice(-4).toLowerCase();
            if (ext === '.csv') {
                this.setState({canImport: true, error: ''});
            }
            else {
                this.setState({canImport: false, error: I18nUtils.tr(TR_IMPORT_ERROR_EXT).replace(':ext', ext)});
            }
        }

    }

    public renderMessages(): ReactNode {
        return (
            <>
                <div className={this.state.error !== '' ? '' : 'd-none'}>
                    <div className="alert alert-danger fade show">
                        { this.state.error }
                    </div>
                </div>
                <div className={this.state.imported ? '' : 'd-none'}>
                    <div className="alert alert-success fade show">
                        {
                            I18nUtils.tr(this.props.structure.moreInfo.genre === 'female' ? TR_OK_IMPORT_FEMALE : TR_OK_IMPORT_MALE)
                                .replace(':entity', this.props.structure.moreInfo.pluralLabel.toLowerCase())
                        }
                    </div>
                </div>
            </>
        );
    }

    public onUploadFiles(files: FileList | null, field): void {
        this.setState({errorFiles: ''});
        if (files) {
            this.setState({loadingFiles: {...this.state.loadingFiles, [field]: files.length}});
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < files.length; i++) {
                new TaskUploadFile(this.props.currentEntity.entity, field, files[i])
                    .onSuccess((result) => {
                        this.setState({
                            loadingFiles: {...this.state.loadingFiles, [field]: this.state.loadingFiles[field] - 1},
                            uploadedFiles: {...this.state.uploadedFiles, [field]: {...this.state.uploadedFiles[field], [files[i].name]: result.file}}
                        });
                    })
                    .onFail((response) => {
                        try {
                            this.setErrorFile(files[i].name, field, JSON.parse(response));
                        } catch (e) {
                            this.setErrorFile(files[i].name, field, I18nUtils.tr(TR_ERROR_UPLOAD_FILE));
                        }
                    })
                    .execute()
            }
        }

    }

    public setErrorFile(fileName: string, field: string, error: string) {
        this.setState({
            errorFiles: {
                ...this.state.errorFiles,
                [field]: {
                    ...this.state.errorFiles[field], [fileName]: error
                }
            },
            loadingFiles: {...this.state.loadingFiles, [field]: this.state.loadingFiles[field] - 1}
        });
    }

    public renderErrorsFilesForm(field: string): ReactNode {
        if (this.state.errorFiles[field] && Object.keys(this.state.errorFiles[field]).length > 0) {
            return (
                <div className="alert alert-danger fade show mt-2">
                    {
                        Object.keys(this.state.errorFiles[field]).map((errorField) => {
                            return <p key={errorField}><strong>{errorField}:</strong> {this.state.errorFiles[field][errorField]}</p>
                        })
                    }
                </div>
            );
        }
        return <></>
    }

    public renderListFilesUploaded(field: string): ReactNode {
        if (this.state.uploadedFiles[field]) {
            if (this.props.structure.form[field].type === 'IMAGE') {
                return (
                    <div className="list-uploaded-images">
                        {
                            Object.keys(this.state.uploadedFiles[field]).map((uploadedFile) => {
                                return (
                                    <div key={uploadedFile}>
                                        <img src={this.state.uploadedFiles[field][uploadedFile]} alt={uploadedFile}/>
                                        <p>{uploadedFile} {this.renderDeleteFileIcon(field, uploadedFile)}</p>
                                    </div>
                                );
                            })
                        }
                    </div>
                );
            }
            return (
                <ul className="list-uploaded-files">
                    {
                        Object.keys(this.state.uploadedFiles[field]).map((uploadedFile) => {
                            return <li>{uploadedFile} {this.renderDeleteFileIcon(field, uploadedFile)}</li>;
                        })
                    }
                </ul>
            );
        }
        return <></>
    }

    public renderDeleteFileIcon(field: string, uploadedFile: string): ReactNode {
        return (
            <i onClick={() => this.removeUploadedFile(field, uploadedFile)}
               className="fa fa-trash"
               title={I18nUtils.tr(TR_DELETE)}
               style={{color: this.props.primaryColor}}
            />
        );
    }

    public removeUploadedFile(field: string, uploadedFile: string) {
        const clone = Object.assign({}, this.state.uploadedFiles);
        delete clone[field][uploadedFile];
        this.setState({uploadedFiles: clone})
    }

    public renderFormsFiles(): ReactNode {

        const fieldsFiles: any = Object.keys(this.props.structure.form).filter((field) => this.props.structure.form[field].type === 'IMAGE' || this.props.structure.form[field].type === 'FILE');

        if (fieldsFiles.length > 0) {
            return (
                <div className="containter-import-form-files">
                    <p><strong>{I18nUtils.tr(TR_IMPORTANT)}</strong> {I18nUtils.tr(TR_FIRST_IMPORT_FILES_CSV)}</p>
                    {
                        fieldsFiles.map((field) => {
                            const isImage = this.props.structure.form[field].type === 'IMAGE';
                            return (
                                <div className="card" key={field}>
                                    <LoadingView loading={this.state.loadingFiles[field] > 0}/>
                                    <div className="card-body">
                                        {I18nUtils.tr(isImage ? TR_IMAGES_FOR_COLUMN : TR_FILES_FOR_COLUMN)} <strong>{this.props.structure.form[field].label}</strong>
                                        {this.renderErrorsFilesForm(field)}
                                        {this.renderListFilesUploaded(field)}
                                        <div className="upload-file">
                                            <div className="btn btn-sm">
                                                {I18nUtils.tr(isImage ? TR_UPLOAD_IMAGES : TR_UPLOAD_FILES)}
                                                <i className="fas fa-cloud-upload-alt mr-2" aria-hidden="true"/>
                                                <input type="file" multiple={true} onChange={(e) => this.onUploadFiles( e.target.files, field )}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
        return <></>

    }

    public renderImportContent(): ReactNode {

        if (!this.props.currentEntity.canImport) {
            return <div className="alert alert-danger fade show">{I18nUtils.tr(TR_ERROR_PERMISSION_IMPORT)}</div>
        }

        return (
            <div>
                <Row>
                    <Col md="12">
                        {this.renderMessages()}
                        <Card>
                            <CardBody>
                                <LoadingView loading={this.state.loading}/>
                                <form onSubmit={(e) => {e.preventDefault(); this.onSubmit()}} className="entity-form">
                                    <div className={'col-md-12'}>
                                        <div className="form-group">
                                            <div className="mt-2 explanation-import" dangerouslySetInnerHTML={{ __html: I18nUtils.tr(TR_TO_IMPORT_CSV).replace(':entity', this.props.structure.moreInfo.pluralLabel.toLowerCase())}}/>
                                            {this.renderFormsFiles()}
                                            <div className="upload-file">
                                                <div className="btn btn-sm">
                                                    {I18nUtils.tr(TR_UPLOAD_CSV_FILE)}
                                                    <i className="fas fa-cloud-upload-alt mr-2" aria-hidden="true"/>
                                                    <input type="file"
                                                           className="form-control"
                                                           onChange={(e) => this.onUploadFile( e.target.files ? e.target.files[0] : null )}
                                                           disabled={false}
                                                           title={
                                                            this.state.importFile.name ?
                                                            this.state.importFile.name :
                                                            I18nUtils.tr(TR_NO_SELECTED_FILE)
                                                       }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.state.canImport ? 'col-md-12' : 'd-none'}>
                                        <button type="submit" className="btn btn-custom" style={{backgroundColor: this.props.primaryColor}}>
                                            {I18nUtils.tr(TR_IMPORT)} {this.state.importFile.name}
                                        </button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

    public render(): ReactNode {

        return (
            <>
                { this.renderButtonsTop() }
                { this.renderImportContent() }
            </>
        )

    }
}

export default connect(mapStateToProps)(ImportScreen) as React.ComponentType<ImportScreenProps>;