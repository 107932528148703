import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";
import {ResultsList} from "../../model/ResultsList";

export default class TaskGetEntityList extends BaseGenericRequest<{}, ResultsList> {

    public constructor(entity: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.getUrlGetEntityList(), {entity, page: 1}));
    }
}