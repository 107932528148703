import React, {ReactNode} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export interface HtmlFieldProps {
    value: string,
    disabled?: boolean,
    onChange: (value, description?) => void
}

class HtmlField extends React.Component<HtmlFieldProps> {

    public render(): ReactNode {

        return (
            <div>
                <ReactQuill
                    theme="snow"
                    value={this.props.value}
                    onChange={(value) => this.props.onChange(value)}
                    readOnly={this.props.disabled}
                    modules={{
                        toolbar: {
                            container: [
                                [{ 'header': [1, 2, 3, false] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
                                [{ 'align': []}],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                ['link', 'image', 'video']
                            ],
                        },
                        history: {
                            delay: 2000,
                            maxStack: 500,
                            userOnly: true
                        }
                    }}
                />
            </div>

        );
    }
}

export default HtmlField;
