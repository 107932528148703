import {METHOD, ReduxAwareGenericRequest} from "@cuatroochenta/co-generic-request";
import {ReduxStorageUtils} from "@cuatroochenta/co-redux-builders";
import I18nUtils from "../I18n/I18nUtils";
import {TR_INTERNAL_SERVER_ERROR, TR_NO_NET_MESSAGE, TR_TIMEOUT_MESSAGE, TR_UNAUTHORIZED_ERROR} from "../I18n/constants";
import AuthManager from "../utils/AuthManager";

export default abstract class BaseGenericRequest<Request = {}, Response = {}>
    extends ReduxAwareGenericRequest<Request, Response> {

    protected constructor(method: METHOD, url: string, withoutAuth?: boolean, withoutJson?: boolean) {
        super(method, url, 'error genérico', ReduxStorageUtils.dispatch);
        if (!withoutAuth && AuthManager.isLogged()) {
            this.addHeader("Authorization", `Bearer ${AuthManager.getAuthToken()}`);
        }
        if (!withoutJson) {
            this.addHeader("Accept", "application/json");
            this.addHeader("Content-type", "application/json");
        }

        this.setTimeoutMessage(I18nUtils.tr(TR_TIMEOUT_MESSAGE));
        this.setNoConnectionMessage(I18nUtils.tr(TR_NO_NET_MESSAGE));
        this.setErrorMessage(401, I18nUtils.tr(TR_UNAUTHORIZED_ERROR));
        this.setErrorMessage(500, I18nUtils.tr(TR_INTERNAL_SERVER_ERROR));

        this.enableLogs(false);
    }

    protected isResponseSuccess(response: { status: number; _bodyText?: string }): boolean {
        let success = super.isResponseSuccess(response);
        const responseBody = JSON.parse(response._bodyText || '{}');
        success = success || responseBody.success;
        return success;
    }

    protected parseResponse(responseObj: any): Response {
        if (!responseObj.success) {
            throw new Error(responseObj.error instanceof Object ? JSON.stringify(responseObj.error) : responseObj.error );
        }
        return super.parseResponse(responseObj.data);
    }

    protected parseError(errorObj: string): string {
        try {
            return JSON.parse(errorObj).error || super.parseError(errorObj);
        } catch (ignored) {
            return super.parseError(errorObj);
        }
    }
}
