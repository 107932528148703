import React, {ReactNode} from "react";

export interface BooleanFieldProps {
    value: string,
    primaryColor: string,
    onChange: (value, description?) => void
}

class BooleanField extends React.Component<BooleanFieldProps> {

    public render(): ReactNode {

        return (
            <div className="radio-group">
                <button
                    type="button"
                    className={`btn btn-lg btn-toggle btn-custom ${!!this.props.value ? 'active' : ''}`}
                    style={!!this.props.value ? {backgroundColor: this.props.primaryColor} : {}}
                    data-toggle="button" aria-pressed="false"
                    onClick={() => this.props.onChange(!this.props.value)}
                >
                    <div className="handle"/>
                </button>
            </div>
        );

    }
}

export default BooleanField;