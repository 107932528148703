import React, {ReactNode} from "react";
import preventEnterSubmit from "../preventEnterSubmit";

export interface InputTextFieldProps {
    value: string,
    size: number,
    type: string,
    disabled?: boolean,
    onEnterSubmit?: () => void,
    onChange: (value, description?) => void
}

class InputTextField extends React.Component<InputTextFieldProps> {

    public render(): ReactNode {

        return (
            <input type={this.props.type === 'email' ? 'email' : this.props.type === 'PASSWORD' ? 'password' : 'text'}
                   className="form-control"
                   size={this.props.size}
                   value={this.props.value}
                   onChange={(e) => this.props.onChange(e.target.value)}
                   disabled={this.props.disabled}
                   onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => preventEnterSubmit(event, this.props.onEnterSubmit)}
            />
        );
    }
}

export default InputTextField;