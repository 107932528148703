import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";
import {FiltersType} from "../../model/ResultsList";

export interface TaskExportListRequest {
    filters: FiltersType,
    order: string,
    orderby: string
}

export interface ExportListResponse {
    pdf: string
}

export default class TaskExportList extends BaseGenericRequest<TaskExportListRequest, ExportListResponse> {

    private readonly filters;
    private readonly order;
    private readonly orderby;

    public constructor(entity: string, filters: FiltersType, orderby: string, order: string) {
        super(METHOD.METHOD_POST, generateRoute(Urls.getUrlGetEntityExport(), {entity}));
        this.filters = filters;
        this.orderby = orderby;
        this.order = order;
    }

    protected getRequest(): TaskExportListRequest {
        return {filters: this.filters, orderby: this.orderby, order: this.order};
    }
}