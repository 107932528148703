import React from 'react';
import { History } from "history";
import createHistory from "history/createBrowserHistory";
import { Provider } from "react-redux";
import { routerMiddleware } from "react-router-redux";
import { ReduxStorageUtils } from "@cuatroochenta/co-redux-builders";
import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import CombineReducers from './redux/CombinedReducers';
import Routing from './routing/Routing';
import AuthManager from './utils/AuthManager';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./res/scss/styles.scss";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { CustomAction } from './model/CustomAction';
import { RelationsValuesInterface } from './model/StructureEntity';
import {FiltersType} from "./model/ResultsList";
import I18nUtils from './I18n/I18nUtils';

export type FormRenderData = {
    fields: {[key: string]: string},
    relationsValues: RelationsValuesInterface,
    relationFormValues: any,
}

export type RenderFormView = (entity: string, type: string, data: FormRenderData) => React.ReactNode;
export type RenderCustomButtonsList = (entity: string, filters: FiltersType, orderby: string, order: string) => React.ReactNode;

type ModuleProps = {
    actions: CustomAction[],
    beforeFormView?: RenderFormView,
    afterFormView?: RenderFormView,
    customButtonsList?: RenderCustomButtonsList,
    afterLoginButton?: () => React.ReactNode
    locale?: string
}

class Module extends React.Component<ModuleProps> {

    protected history: History;

    constructor(props: any) {
        super(props);
        this.history = createHistory();
        AuthManager.initialized();
        setInterval(() => {
            if (AuthManager.isLogged()) {
                AuthManager.refreshTokenTask();
            }
        }, 600000);
    }

    public componentWillMount() {
        const middleware = routerMiddleware(this.history);
        if (process.env.REACT_APP_ENV === 'dev') {
            const composeEnhances = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'.toString()] as typeof compose || compose;
            ReduxStorageUtils.initialize(createStore(CombineReducers, composeEnhances(applyMiddleware(thunk, middleware))));
        }
        else {
            ReduxStorageUtils.initialize(createStore(CombineReducers, applyMiddleware(thunk, middleware)));
        }
    }

    public render() {
        if (this.props.locale) {
            I18nUtils.setLocale(this.props.locale)
        }
        return (
            <Provider store={ReduxStorageUtils.getStorage()}>
                <Routing
                    history={this.history}
                    customActions={this.props.actions}
                    beforeFormView={this.props.beforeFormView}
                    afterFormView={this.props.afterFormView}
                    customButtonsList={this.props.customButtonsList}
                    afterLoginButton={this.props.afterLoginButton}
                />
            </Provider>
        );
    }

}

export default Module;
