import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {TaskLoginResponse} from "./TaskLogin";

export default class TaskLoginSaml extends BaseGenericRequest<{}, TaskLoginResponse> {

    private readonly tokenSaml;

    public constructor(tokenSaml: string) {
        super(METHOD.METHOD_POST, Urls.getUrlLoginSaml());
        this.tokenSaml = tokenSaml;
    }

    protected getRequest(): {} {
        return { tokenSaml: this.tokenSaml };
    }
}
