import React, { ReactNode } from "react";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ACTIONS,
    TR_BUTTON_DELETE
} from "../../../I18n/constants";
import '@fortawesome/fontawesome-free/css/all.css'
import { FieldList, FieldRelationForm } from "../../../model/StructureEntity";
import ShowField from "../../../components/field/ShowField";
import { Col, Container, Row } from "reactstrap";
import {AutosuggestFieldValues} from "./FormRelationsForm";

interface FormRelationsTableNewValuesProps {
    structure: FieldRelationForm,
    relationFormValues: any,
    newRelationFormValues: any,
    autosuggestFieldValues: AutosuggestFieldValues
    primaryColor?: string,
    iconsColor?: string,
    entity: string,
    relationKey: string,
    removeRelationFormValue: (relationKey: string, index: number) => void,
    removeNewRelationValueFromTable: (relationKey: string, index: number, data: any) => void,
    isEditScreen: boolean
}

interface StructureList {
    [dynamic: string]: FieldList
}

class FormRelationsTableNewValues extends React.Component<FormRelationsTableNewValuesProps> {
    public render(): ReactNode {
        if (!this.props.newRelationFormValues[this.props.relationKey]) {
            return <></>
        }

        if (this.props.newRelationFormValues[this.props.relationKey].length > 0) {
            const structureList = this.getStructureList();

            return (
                <Container fluid={true}>
                    <Row>
                        <Col md="12">
                            <div className="table-responsive">
                                <table className="table" style={{ borderRadius: '10px', backgroundColor: '#f5f4ef' }}>
                                    {this.renderHeader(structureList)}
                                    {this.renderContentTable(structureList)}
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
        }

        return <></>
    }

    public getStructureList(): StructureList {
        const structureList = {} as StructureList;
        if (this.props.structure.listStructure) {
            Object.keys(this.props.structure.listStructure).map((field) => {
                if (this.props.structure.listStructure![field].listableInExtended && field !== 'oid' && this.props.structure.fieldsToShow.includes(field)) {
                    structureList[field] = this.props.structure.listStructure![field];
                }
            });
        }
        return structureList;
    }

    public removeFormValue(index: number, data: any) {
        this.props.removeRelationFormValue(this.props.relationKey, index + this.props.relationFormValues[this.props.relationKey].length - 1)
        this.props.removeNewRelationValueFromTable(this.props.relationKey, index, data)
    }

    public renderHeader(structureList: StructureList): ReactNode {
        return (
            <thead className="text-primary">
                <tr style={{ color: this.props.primaryColor }}>
                    {
                        Object.keys(structureList).map((field) => {
                            return (
                                <th key={field} className={structureList[field].colClassesTable}>
                                    {structureList[field].label}
                                </th>
                            )
                        })
                    }
                    {this.headerActions()}
                </tr>
            </thead>
        );
    }

    public headerActions(): ReactNode {
        return <th className="list-actions-column">{I18nUtils.tr(TR_ACTIONS)}</th>;
    }

    public renderContentTable(structureList: StructureList): ReactNode {
        const data = this.props.newRelationFormValues[this.props.relationKey];
        return (
            <tbody>
                {
                    Object.keys(data).map((indexData, index) => {
                        return (
                            <tr key={indexData}>
                                {
                                    Object.keys(structureList).map((field) => {
                                        if(data[indexData][field] !== "" && structureList[field].type === 'PKINT') {
                                            const fieldValue = this.getFieldValueRelation(data, indexData, field, structureList);
                                            return (
                                                <td key={field + indexData} className={structureList[field].colClassesTable + ' ' + 'column-field-' + structureList[field].type.toLowerCase()}>
                                                    {ShowField(fieldValue, "VARCHAR", structureList[field].fieldsToShow)}
                                                </td>
                                            )
                                        }
                                        const fieldValue = FormRelationsTableNewValues.getFieldValue(data, indexData, field);
                                        return (
                                            <td key={field + indexData} className={structureList[field].colClassesTable + ' ' + 'column-field-' + structureList[field].type.toLowerCase()}>
                                                {ShowField(fieldValue, structureList[field].type, structureList[field].fieldsToShow)}
                                            </td>
                                        )
                                    })
                                }
                                {this.renderActionsColumn(index, data[indexData])}
                            </tr>
                        )
                    })
                }
            </tbody>
        )
    }

    private getFieldValueRelation(data: any, indexData:string, field:string, structureList: StructureList): string {
        if (structureList[field].autoSuggest) {
            return this.props.autosuggestFieldValues[this.props.relationKey][field][data[indexData][field]];
        }
        return (
            this.props.structure.formStructure[field].values.filter(
                (value) => {return value.id === data[indexData][field]}
            )["0"].description
        );
    }

    private static getFieldValue(data: any, indexData:string, field:string): string {
        if ('translations' in data[indexData] && field in data[indexData]['translations'][I18nUtils.getLocale()]) {
            return data[indexData]['translations'][I18nUtils.getLocale()][field];
        }
        return (field in data[indexData] ? data[indexData][field] : '');
    }

    public renderActionsColumn(index: number, data: any): ReactNode {
        const color = this.props.iconsColor;

        return (
            <td className='list-actions-column'>
                {
                    this.props.structure.canDeleteEntityRelation &&
                    <i className='fa fa-trash' style={{ color }} onClick={() => this.removeFormValue(index, data)} title={I18nUtils.tr(TR_BUTTON_DELETE)} />
                }

            </td>
        );
    }
}

export default FormRelationsTableNewValues;
