import * as React from 'react';
import {ReactNode} from 'react';
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_FORM_PASSWORD,
    TR_FORM_USER,
    TR_ACCESS, TR_LOGIN_ERROR
} from "../../I18n/constants";
import TaskLogin, {TaskLoginResponse} from "../../ws/login/TaskLogin";
import AuthManager from "../../utils/AuthManager";

interface LoginFormProps {
    onLogin: () => void,
    setError: (error: string) => void,
    setWaiting: (waiting: boolean) => void,
    primaryColor: string,
}

class LoginForm extends React.Component<LoginFormProps> {

    public state = { username: '', password: '' };

    public doLogin(): void {
        if (this.state.username !== '' && this.state.password !== '') {
            this.props.setWaiting(true);
            new TaskLogin(this.state.username, this.state.password)
                .onSuccess((response: TaskLoginResponse) => {
                    AuthManager.login(response.token, response.refresh_token, response.user.username);
                    this.props.onLogin();
                })
                .onFail(() => {
                    this.props.setError(I18nUtils.tr(TR_LOGIN_ERROR));
                    this.props.setWaiting(false);
                })
                .execute();
        }
    }

    public render(): ReactNode {

        return (
            <form onSubmit={ (e) => { e.preventDefault(); this.doLogin(); } }>
                <div className="form-group">
                    <label htmlFor="DiameterInput">{ I18nUtils.tr(TR_FORM_USER) }</label>
                    <input type="text"
                           name="username"
                           className="form-control"
                           value={this.state.username}
                           onChange={(e) => {this.setState({loginError: false}); this.setState({username: e.target.value})}}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="DiameterInput">{ I18nUtils.tr(TR_FORM_PASSWORD) }</label>
                    <input type="password" className="form-control"
                           name="password"
                           aria-label={ I18nUtils.tr(TR_FORM_PASSWORD) } aria-describedby="see-pass"
                           value={this.state.password}
                           onChange={(e) => {this.setState({loginError: false}); this.setState({password: e.target.value})}}
                    />
                </div>
                <div className="d-flex justify-content-center mt-2">
                    <button type="submit" className="btn btn-lg btn-custom" style={{backgroundColor: this.props.primaryColor}}>
                        { I18nUtils.tr(TR_ACCESS) }
                    </button>
                </div>
            </form>
        );
    }

}

export default LoginForm;

