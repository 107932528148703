import React, {ReactNode} from "react";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_NO, TR_YES} from "../../I18n/constants";
import moment from "moment";

const ShowField = (value: any, type: string, fieldsToShow: string[]): ReactNode => {
    if (value === '' && type !== 'BOOLEAN') {
        return <></>;
    }
    switch (type) {
        case 'DATETIME':
            return <>{printDateTime(value)}</>;
        case 'DATE':
            return <>{printDate(value)}</>;
        case 'COLOR':
            return (
                <>
                    <div className="show-color rounded" style={{backgroundColor: value}}/>
                    {value}
                </>
            );
        case 'IMAGE':
            return (
                <div className="field-image">
                    <a href={value} target="_blank">
                        <img src={value} alt={value.substring(value.lastIndexOf('/') + 1)}/>
                    </a>
                </div>
            );
        case 'FILE':
            return (
                <div className="field-file">
                    <a href={value} target="_blank">{value.substring(value.lastIndexOf('/') + 1)}</a>
                </div>
            );
        case 'HTML':
            return(
                <div className="post__content" dangerouslySetInnerHTML={{__html: value}}/>
            );
        case 'PKINT':
            return fieldsToShow.map((fieldToShow) => {
                return getValueToShow(fieldToShow.split('.'), value) + ' ';
            });
        case 'BOOLEAN':
            return value === true ? I18nUtils.tr(TR_YES) : I18nUtils.tr(TR_NO);
        default:
            return <>{value}</>

    }

};

export const getValueToShow = (fieldToShow: any[], value: any) => {
    if (value[fieldToShow[0]] !== null) {
        let valueToShow = value[fieldToShow[0]]
        if (fieldToShow.length === 1) {
            if (typeof valueToShow === 'undefined' && value.translations) {
                if(value.translations[I18nUtils.getLocale()]) {
                    valueToShow = value.translations[I18nUtils.getLocale()][fieldToShow[0]];
                }
            }
            else if (typeof valueToShow === 'string') {
                if (valueToShow.substr(0, 19).match(/\d{4}-[01]{1}\d{1}-[0-3]{1}\d{1} [0-2]{1}\d{1}:[0-6]{1}\d{1}:[0-6]{1}\d{1}/)) {
                    return printDateTime(valueToShow);
                } else if (valueToShow.match(/\d{4}-[01]{1}\d{1}-[0-3]{1}\d{1}/)) {
                    return printDate(valueToShow);
                }
            }
            else if (typeof valueToShow === 'boolean') {
                return valueToShow ? I18nUtils.tr(TR_YES) : I18nUtils.tr(TR_NO)
            }
            return valueToShow;
        }
        else {
            fieldToShow.shift();
            return getValueToShow(fieldToShow, valueToShow)
        }
    }
    return '';
};

const printDate = (value: string) => {
    const dateTime = new Date(value);
    return (
        ('0' + dateTime.getDate()).slice(-2)
        + '-' +
        ('0' + (dateTime.getMonth() + 1)).slice(-2)
        + '-' +
        dateTime.getFullYear()
    );
};

const printDateTime = (value: string) => {
    const dateTime = moment(value.substr(0, 19).replace(/\s/, 'T')).toDate();
    return (
        ('0' + dateTime.getDate()).slice(-2)
        + '-' +
        ('0' + (dateTime.getMonth() + 1)).slice(-2)
        + '-' +
        dateTime.getFullYear()
        + ' ' +
        ('0' + dateTime.getHours()).slice(-2)
        + ':' +
        ('0' + dateTime.getMinutes()).slice(-2)
        + ':' +
        ('0' + dateTime.getSeconds()).slice(-2)
    );
};

export default ShowField