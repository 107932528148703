import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";

export interface TaskImportDataEntityResponse {
    file: string,
}

interface UploadFiles {
    [dynamic: string]: {
        [dynamic: string]: string
    }
}

export default class TaskImportDataEntity extends BaseGenericRequest<{}, TaskImportDataEntityResponse> {

    private readonly file: File;
    private readonly uploadedFiles: UploadFiles;

    public constructor(entity: string, file: File, uploadedFiles: UploadFiles) {
        super(METHOD.METHOD_POST, generateRoute(Urls.getUrlGetEntityImport(), {entity}), false, true);
        this.file = file;
        this.uploadedFiles = uploadedFiles;
    }

    protected getRequest(): FormData {
        const data: FormData = new FormData();
        data.append('file', this.file);
        data.append('uploadedFiles', JSON.stringify(this.uploadedFiles));
        return data;
    }
}