import React, {ReactNode} from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import es from "date-fns/locale/es";
import 'react-datepicker/dist/react-datepicker.css';
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_TIME} from "../../../I18n/constants";

export interface DateFieldProps {
    value: string,
    type: string,
    disabled?: boolean,
    onChange: (value, description?) => void
}

class DateField extends React.Component<DateFieldProps> {

    public componentWillMount(): void {
        registerLocale("es", es);
    }

    public onChange(date) {
        let dateString = '';
        if (date) {
            const dateSelected = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
            const hourSelected = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
            switch (this.props.type) {
                case 'DATETIME':
                    dateString = dateSelected + ' ' + hourSelected;
                    break;
                case 'TIME':
                    dateString = hourSelected;
                    break;
                case 'DATE':
                    dateString = dateSelected;
                    break;

            }
        }
        this.props.onChange(dateString)
    }

    public getValue(): Date | null {
        if (this.props.value) {
            let value = this.props.value;
            switch (this.props.type) {
                case 'DATETIME':
                    break;
                case 'TIME':
                    if (value.length === 8) {
                        value = '1970-01-01 ' + value;
                    }
                    else if (value.length === 6) {
                        value = '1970-01-01 ' + value + '00';
                    }
                    break;
                case 'DATE':
                    break;

            }
            return moment(value).toDate();
        }
        return null;
    }


    public render(): ReactNode {

        const value = this.getValue();

        const {type} = this.props;
        return (
            <div>
                <DatePicker
                   className="form-control"
                   selected={value}
                   onChange={(date) => this.onChange(date)}
                   dateFormat={type === 'DATETIME' ? "dd/MM/yyyy HH:mm:ss" : type === 'TIME' ? "HH:mm:ss" : "dd/MM/yyyy"}
                   showTimeSelect={type !== 'DATE'}
                   timeFormat="HH:mm"
                   showTimeSelectOnly={type === 'TIME'}
                   disabled={this.props.disabled}
                   locale={I18nUtils.getLocale() === 'es' ? 'es' : 'en'}
                   timeCaption={I18nUtils.tr(TR_TIME)}
                />
            </div>
        );
    }
}

export default DateField;