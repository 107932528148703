import * as  React from "react";
import { ReactNode } from "react";
import { RouterProps } from "react-router";
import { Route, Router } from "react-router-dom";
import { ROUTE_ACTION, ROUTE_ENTITY_CREATE, ROUTE_ENTITY_EDIT, ROUTE_ENTITY_IMPORT, ROUTE_ENTITY_LIST, ROUTE_ENTITY_VIEW, ROUTE_RESET_PASSWORD, ROUTE_RESET_PASSWORD_REQUEST } from "./Routes";
import LoginScreen from "../modules/Login/LoginScreen";
import EntityScreen from "../modules/Entity/EntityScreen";
import ActionScreen from "../modules/Action/ActionScreen";
import LostPasswordScreen from "../modules/Login/LostPasswordScreen";
import ChangePasswordScreen from "../modules/Login/ChangePasswordScreen";
import { CustomAction } from '../model/CustomAction';
import {RenderCustomButtonsList, RenderFormView} from '../Module';

type RoutingProps = {
    customActions?: CustomAction[],
    beforeFormView?: RenderFormView,
    afterFormView?: RenderFormView,
    customButtonsList?: RenderCustomButtonsList,
    afterLoginButton?: () => React.ReactNode
}

export default class Routing extends React.Component<RouterProps & RoutingProps> {

    public render(): ReactNode {

        return (
            <Router history={this.props.history}>
                <>

                    {/*HEADER*/}
                    <Route path="/" exact
                           component={
                               () => <LoginScreen
                                   afterLoginButton={this.props.afterLoginButton}
                           />}
                    />

                    {/*ENTITY*/}
                    <Route path={ROUTE_ENTITY_LIST} exact
                           component={() => <EntityScreen
                               route={ROUTE_ENTITY_LIST}
                               beforeFormView={this.props.beforeFormView}
                               afterFormView={this.props.afterFormView}
                               customButtonsList={this.props.customButtonsList}
                           />}
                    />
                    <Route path={ROUTE_ENTITY_EDIT} exact
                           component={() => <EntityScreen
                               route={ROUTE_ENTITY_EDIT}
                               beforeFormView={this.props.beforeFormView}
                               afterFormView={this.props.afterFormView}
                               customButtonsList={this.props.customButtonsList}
                           />}
                    />
                    <Route path={ROUTE_ENTITY_VIEW} exact
                           component={() => <EntityScreen
                               route={ROUTE_ENTITY_VIEW}
                               beforeFormView={this.props.beforeFormView}
                               afterFormView={this.props.afterFormView}
                               customButtonsList={this.props.customButtonsList}
                           />}
                    />
                    <Route path={ROUTE_ENTITY_CREATE} exact
                           component={() => <EntityScreen
                               route={ROUTE_ENTITY_CREATE}
                               beforeFormView={this.props.beforeFormView}
                               afterFormView={this.props.afterFormView}
                               customButtonsList={this.props.customButtonsList}
                           />}
                    />
                    <Route path={ROUTE_ENTITY_IMPORT} exact
                           component={() => <EntityScreen
                               route={ROUTE_ENTITY_IMPORT}
                               beforeFormView={this.props.beforeFormView}
                               afterFormView={this.props.afterFormView}
                               customButtonsList={this.props.customButtonsList}
                           />}
                    />

                    {/*ACTION*/}
                    <Route path={ROUTE_ACTION} exact component={() => <ActionScreen customActions={this.props.customActions}/>}/>

                    {/*LOST PASSWORD*/}
                    <Route path={ROUTE_RESET_PASSWORD_REQUEST} exact component={LostPasswordScreen}/>
                    <Route path={ROUTE_RESET_PASSWORD} exact component={ChangePasswordScreen}/>

                </>
            </Router>
        );
    }

}
