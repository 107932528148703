
const validateRequired = (required: boolean, type: string, value: string, isEditScreen: boolean): boolean => {

    if (required && (!value || value.toString().trim() === '')) {
        return (type === 'PASSWORD' && isEditScreen);
    }

    return true;
};

export default validateRequired