import * as React from 'react';
import { ReactNode } from 'react';
import Modal from "react-bootstrap/Modal";
import BaseModal, { BaseModalProps } from '../../base/modal/BaseModal';
import I18nUtils from '../../I18n/I18nUtils';
import { TR_ARE_YOU_SURE_CLOSE_SESSION, TR_CANCEL, TR_CERRAR_SESION } from '../../I18n/constants';
import TaskLogout from '../../ws/login/TaskLogout';

interface LogoutModalProps {
    primaryColor: string,
    onLogout: () => void,
}

class LogoutModal extends BaseModal<BaseModalProps & LogoutModalProps> {

    public doLogout(): void {
        new TaskLogout()
            .onSuccess(() => {
                this.props.onLogout();
            })
            .onFail(() => {})
            .execute();
    }

    public render(): ReactNode {
        return (
            <Modal show={this.props.show} className="" dialogClassName="" onHide={() => this.props.onClose()}>
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {I18nUtils.tr(TR_CERRAR_SESION)}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label={ I18nUtils.tr(TR_CERRAR_SESION) } onClick={() => this.props.onClose()}>
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className="modal-body text-center">
                        <p className="mt-3">
                        {
                            I18nUtils.tr(TR_ARE_YOU_SURE_CLOSE_SESSION)
                        }
                        </p>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-custom" style={{backgroundColor: this.props.primaryColor}} onClick={() => this.doLogout()}>
                            {I18nUtils.tr(TR_CERRAR_SESION)}
                        </button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.props.onClose()}>
                            {I18nUtils.tr(TR_CANCEL)}
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

}

export default LogoutModal;

