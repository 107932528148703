import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import {Action} from "../../model/AppInfo";
import ActionActions from "./ActionActions";
import TaskExecuteAction from "../../ws/action/TaskExecuteAction";
import TaskGetContentAction from "../../ws/action/TaskGetContentAction";

const INITAL_STATE = {
    loadingAction: false,
    currentAction: {} as Action,
    loadingContentScreen: false,
};

const buildState = (state: any) => {
    return state;
};

export default ReducerBuilder.newBuilder(INITAL_STATE)

    .onEvent(ActionActions.setCurrentAction, (oldState, payload) => buildState({ ...oldState, currentAction: payload.action }))

    .onEvent(TaskExecuteAction.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, _) => buildState({...oldState, loadingAction: false}))
    .onEvent(TaskExecuteAction.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({...oldState, loadingAction: true}))
    .onEvent(TaskExecuteAction.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({...oldState, loadingAction: false}))

    .onEvent(TaskGetContentAction.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, _) => buildState({...oldState, loadingContentScreen: false}))
    .onEvent(TaskGetContentAction.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({...oldState, loadingContentScreen: true}))
    .onEvent(TaskGetContentAction.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({...oldState, loadingContentScreen: false}))

    .build();
