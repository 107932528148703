import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";

export interface TaskUploadFileResponse {
    file: string,
}

export default class TaskUploadFile extends BaseGenericRequest<{}, TaskUploadFileResponse> {

    private readonly entity: string;
    private readonly field: string;
    private readonly file: File;

    public constructor(entity: string, field: string, file: File) {
        super(METHOD.METHOD_POST, Urls.getUrlUploadFile(), false, true);
        this.entity = entity.toLowerCase();
        this.field = field;
        this.file = file;
    }

    protected getRequest(): FormData {
        const data: FormData = new FormData();
        data.append('entity', this.entity);
        data.append('field', this.field);
        data.append('file', this.file);
        return data;
    }
}