import React, {ReactNode} from "react";
import EntityReducer from "../EntityReducer";
import {connect} from "react-redux";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_ERROR_CREATE_FORM, TR_LIST_BACK, TR_OK_CREATE_FORM_FEMALE, TR_OK_CREATE_FORM_MALE} from "../../../I18n/constants";
import {Link} from "react-router-dom";
import {generateRoute} from "../../../utils/Router";
import {ROUTE_ENTITY_LIST} from "../../../routing/Routes";
import LoadingView from "../../../base/loading/LoadingView";
import EntityForm from "../Edit/EntityForm";
import {Card, CardBody, Col, Row} from "reactstrap";
import TaskCreateEntity from "../../../ws/entity/TaskCreateEntity";
import {RelationsValuesInterface} from "../../../model/StructureEntity";
import { FormRenderData } from '../../../Module';

const mapStateToProps = EntityReducer.autoMapToProps();

interface CreateScreenProps {
    languages: string[],
    primaryColor: string,
    beforeFormView?: (entity: string, data: FormRenderData) => React.ReactNode,
    afterFormView?: (entity: string, data: FormRenderData) => React.ReactNode,
}

class CreateScreen extends React.Component<CreateScreenProps & typeof mapStateToProps> {

    public state = {
        modified: false,
        error: false,
        errors: {} as any,
        keyForm: 1
    };

    public onSubmitForm(data: any, relations: RelationsValuesInterface) {
        this.setState({modified: false, error: false});
        if (data.translations) {
            Object.keys(data.translations).map((index) => delete data.translations[index].id);
        }
        new TaskCreateEntity(this.props.currentEntity.entity, data, relations)
            .onSuccess(() => {
                this.setState({modified: true, keyForm: this.state.keyForm + 1});
                document.getElementsByClassName("main-panel")[0].scrollTop = 0;
                setTimeout(() => this.setState({modified: false}), 10000);
            })
            .onFail((response) => {
                try {
                    const errors = JSON.parse(response);
                    this.setState({error: true, errors});
                } catch (e) {
                    this.setState({error: true, errors: {} as any});
                }
                document.getElementsByClassName("main-panel")[0].scrollTop = 0;
            })
            .execute();
    }

    public renderMessages(): ReactNode {
        return (
          <>
              <div className={`col-md-12 ${this.state.error ? '' : 'd-none'}`}>
                  <div className="alert alert-danger fade show">
                      {
                          Object.keys(this.state.errors).length > 0 ?
                              Object.keys(this.state.errors).map((field) => {
                                  if (this.props.structure.form[field]) {
                                      return <p key={field}>{this.props.structure.form[field].label + ': ' + this.state.errors[field]}</p>;
                                  }
                                  return <p key={field}>{this.state.errors[field]}</p>;
                              }) :
                              I18nUtils.tr(TR_ERROR_CREATE_FORM)
                      }
                  </div>
              </div>
              <div className={`col-md-12 ${this.state.modified ? '' : 'd-none'}`}>
                  <div className="alert alert-success fade show">
                      {
                          I18nUtils.tr(this.props.structure.moreInfo.genre === 'female' ? TR_OK_CREATE_FORM_FEMALE : TR_OK_CREATE_FORM_MALE)
                              .replace(':entity', this.props.structure.moreInfo.label.toLowerCase())
                      }
                  </div>
              </div>
          </>
        );
    }

    public getDefaultValues() {

        const data: any = {};
        Object.keys(this.props.structure.form).map((field) => {
            const fieldStructure = this.props.structure.form[field];
            if (fieldStructure.defaultValue) {
                if (fieldStructure.isTranslatable) {
                    if (!data.translations) {
                        data.translations = {};
                        this.props.languages.map((lang) => {
                            if (!data.translations[lang]) {
                                data.translations[lang] = {};
                                data.translations[lang].locale = lang;
                            }
                            data.translations[lang][field] = fieldStructure.defaultValue;
                        });
                    }

                }
                else {
                    data[field] = fieldStructure.defaultValue;
                }
            }
        });

        return data;
    }

    public render(): ReactNode {
        return (
            <>
                <Link to={generateRoute(ROUTE_ENTITY_LIST, {entity: this.props.currentEntity.entity})}>
                    <div className="btn btn-light button-back">
                        <i className="fa fa-arrow-left pr-2"/>
                        {I18nUtils.tr(TR_LIST_BACK)}
                    </div>
                </Link>
                {
                    this.props.loadingGetEntity ?
                        <LoadingView loading={true}/> :
                        <div>
                            <Row>
                                {this.renderMessages()}
                                <Col md="12">
                                    <Card>
                                        <CardBody>
                                            <LoadingView loading={this.props.loadingCreate}/>
                                            <EntityForm key={this.state.keyForm}
                                                entity={this.props.currentEntity.entity}
                                                structure={this.props.structure}
                                                data={this.getDefaultValues()}
                                                name={this.props.currentEntity.name}
                                                languages={this.props.languages}
                                                primaryColor={this.props.primaryColor}
                                                onSubmit={(data, relations: RelationsValuesInterface) => this.onSubmitForm(data, relations)}
                                                isEditScreen={false}
                                                beforeFormView={this.props.beforeFormView}
                                                afterFormView={this.props.afterFormView}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                }
            </>
        )
    }
}

export default connect(mapStateToProps)(CreateScreen) as React.ComponentType<CreateScreenProps>;