import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";

export default class TaskGetContentAction extends BaseGenericRequest<{}> {

    public constructor(slug: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.getUrlExecuteAction(), {slug}));
    }
}