import React, {ReactNode} from "react";
import {CofingColumns} from "../../../model/FiltersState";
import EntityActions from "../EntityActions";

interface CofingHiddenColumnsProps {
    entity: string,
    columns: CofingColumns,
    extendedResults: boolean,
    isActiveGalleryView: boolean
}


class CofingHiddenColumns extends React.Component<CofingHiddenColumnsProps> {

    public render(): ReactNode {

        if (this.props.extendedResults || this.props.isActiveGalleryView) {
            return null;
        }

        return (
            <div className="row" id="list-columns-config">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div>
                                {
                                    Object.keys(this.props.columns).map((column) => {
                                        return (
                                            <div key={column} className={this.props.columns[column].showByDefault ? 'active' : ''} onClick={() => EntityActions.changeHiddenColumn.asConnectedAction(this.props.entity, column)}>
                                                {this.props.columns[column].label}
                                                <i className={`fa ${this.props.columns[column].showByDefault ? 'fa-eye' : 'fa-eye-slash'}`}/>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default CofingHiddenColumns;