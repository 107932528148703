import React, { ReactNode } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import ScreenSidebar from "../../components/screen/ScreenSidebar";
import ScreenHeader from "../../components/screen/ScreenHeader";
import { ReducerBuilder } from "@cuatroochenta/co-redux-builders";
import AppInfoReducer from "../../components/screen/AppInfoReducer";
import EntityReducer from "./EntityReducer";
import { connect } from "react-redux";
import LoadingView from "../../base/loading/LoadingView";
import AuthManager from "../../utils/AuthManager";
import { RouteComponentProps, withRouter } from "react-router";
import {
    ROUTE_ENTITY_CREATE,
    ROUTE_ENTITY_EDIT,
    ROUTE_ENTITY_IMPORT,
    ROUTE_ENTITY_LIST,
    ROUTE_ENTITY_VIEW
} from "../../routing/Routes";
import { generateRoute } from "../../utils/Router";
import EntityActions from "./EntityActions";
import ListScreen from "./List/ListScreen";
import TaskGetStructureEntity from "../../ws/entity/TaskGetStructureEntity";
import TaskGetAppInfo from "../../ws/app/TaskGetAppInfo";
import { Action, Entity } from "../../model/AppInfo";
import ViewScreen from "./View/ViewScreen";
import EditScreen from "./Edit/EditScreen";
import CreateScreen from "./Create/CreateScreen";
import I18nUtils from "../../I18n/I18nUtils";
import { TR_CREATE, TR_IMPORT } from "../../I18n/constants";
import ImportScreen from "./Import/ImportScreen";
import ActionActions from "../Action/ActionActions";
import RelatedEntities from "./List/RelatedEntities";
import { FormRenderData, RenderCustomButtonsList, RenderFormView } from '../../Module';

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(EntityReducer.autoMapToProps(), AppInfoReducer.autoMapToProps());

interface EntityProps {
    entity: string,
    id: string
}

interface EntityScreenProps {
    route: string,
    beforeFormView?: RenderFormView,
    afterFormView?: RenderFormView,
    customButtonsList?: RenderCustomButtonsList,
}

type Props = EntityScreenProps & RouteComponentProps<EntityProps> & typeof mapStateToProps;

let ps;

class EntityScreen extends React.Component<Props> {

    public state = { waiting: true, canRender: true, error: {} as any, notfound: false, path: '' };

    public readonly mainPanel;

    constructor(props) {
        super(props);
        this.mainPanel = React.createRef();
    }

    public componentWillMount(): void {
        if (!AuthManager.isLogged()) {
            this.props.history.push('/');
            this.setState({ canRender: false });
        }
        else if (!this.props.appInfo || !this.props.appInfo.entities || this.props.appInfo.entities.length === 0) {
            new TaskGetAppInfo()
                .onSuccess((result: any) => {
                    this.setCurrentEntity(result.entities);
                    this.setState({ canRender: true });
                    document.title = result.name;
                })
                .onFail((error) => {
                    this.setState({ error })
                })
                .execute();
        }
        else {
            this.setCurrentEntity(this.props.appInfo.entities);
        }
    }

    public setCurrentEntity(entities: Entity[]): void {
        if (this.props.match.params.entity === this.props.structure.entity) {
            this.setState({ waiting: false });
        }
        else {
            const currentEntity = entities.find((entity) => entity.entity === this.props.match.params.entity);
            if (currentEntity) {
                EntityActions.setCurrentEntity.asConnectedAction(currentEntity);
                ActionActions.setCurrentAction.asConnectedAction({} as Action);
                new TaskGetStructureEntity(currentEntity.entity).execute();
                this.setState({ waiting: false });
            } else {
                this.setState({ canRender: false, notfound: true });
            }
        }
    }

    public componentDidMount() {
        if (this.state.canRender) {
            if (navigator.platform.indexOf("Win") > -1) {
                ps = new PerfectScrollbar(this.mainPanel.current);
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        }
    }

    public componentWillUnmount() {
        if (this.state.canRender && navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.body.classList.toggle("perfect-scrollbar-on");
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            this.props.history.location.pathname &&
            this.props.history.location.pathname !== this.state.path &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            this.mainPanel.current.scrollTop = 0;
            this.setState({ path: this.props.history.location.pathname })
        }
        if (this.props.match.params.entity !== prevProps.match.params.entity) {
            this.setCurrentEntity(this.props.appInfo.entities);
            document.getElementsByClassName("main-panel")[0].scrollTop = 0;
        }
        else if (this.props.match.params.id !== prevProps.match.params.id) {
            document.getElementsByClassName("main-panel")[0].scrollTop = 0;
        }
    }

    public renderHeader(route: string): ReactNode {

        const info = this.props.structure.moreInfo;
        let buttonCreate = false;
        let buttonImport = false;
        let title = '';
        if (info) {
            switch (route) {
                case ROUTE_ENTITY_VIEW:
                case ROUTE_ENTITY_EDIT:
                    title = info.label;
                    break;
                case ROUTE_ENTITY_CREATE:
                    title = I18nUtils.tr(TR_CREATE) + ' ' + info.label.toLowerCase();
                    break;
                case ROUTE_ENTITY_LIST:
                    title = info.pluralLabel;
                    buttonCreate = info.canCreate;
                    buttonImport = this.props.currentEntity.canImport;
                    break;
                case ROUTE_ENTITY_IMPORT:
                    title = I18nUtils.tr(TR_IMPORT) + ' ' + info.pluralLabel.toLowerCase();
                    break;
            }
        }
        return (
            <ScreenHeader
                title={title}
                buttonCreate={buttonCreate}
                buttonImport={buttonImport}
                entity={this.props.match.params.entity}
            />
        );
    }

    public renderContent(route: string): ReactNode {

        const canRender = this.props.currentEntity.entity && this.props.currentEntity.entity === this.props.match.params.entity && this.props.match.params.entity === this.props.structure.entity && !this.props.loadingStructure && this.props.structure.moreInfo;

        switch (route) {
            case ROUTE_ENTITY_VIEW:
                if (this.props.currentEntity.singleton) {
                    return (
                        <>
                            <div style={{marginBottom: "30px"}}>
                                <RelatedEntities />
                            </div>
                            <div>
                                {
                                    !canRender ?
                                        <LoadingView loading={true} /> :
                                        <ViewScreen
                                            key={this.props.match.params.id}
                                            id={parseInt(this.props.match.params.id, 0)}
                                            languages={this.props.appInfo.languages}
                                            primaryColor={this.props.appInfo.appStyles.primaryColor}
                                            iconsColor={this.props.appInfo.appStyles.iconsColor}
                                            beforeFormView={(entity: string, data: FormRenderData) => this.props.beforeFormView && this.props.beforeFormView(entity, 'view', data)}
                                            afterFormView={(entity: string, data: FormRenderData) => this.props.afterFormView && this.props.afterFormView(entity, 'view', data)}
                                        />
                                }
                            </div>
                        </>
                    );
                }
                return !canRender ?
                    <LoadingView loading={true} /> :
                    <ViewScreen
                        key={this.props.match.params.id}
                        id={parseInt(this.props.match.params.id, 0)}
                        languages={this.props.appInfo.languages}
                        primaryColor={this.props.appInfo.appStyles.primaryColor}
                        iconsColor={this.props.appInfo.appStyles.iconsColor}
                        beforeFormView={(entity: string, data: FormRenderData) => this.props.beforeFormView && this.props.beforeFormView(entity, 'view', data)}
                        afterFormView={(entity: string, data: FormRenderData) => this.props.afterFormView && this.props.afterFormView(entity, 'view', data)}
                    />;
            case ROUTE_ENTITY_EDIT:
                return !canRender ?
                    <LoadingView loading={true} /> :
                    <EditScreen
                        key={this.props.match.params.id}
                        id={parseInt(this.props.match.params.id, 0)}
                        languages={this.props.appInfo.languages}
                        primaryColor={this.props.appInfo.appStyles.primaryColor}
                        iconsColor={this.props.appInfo.appStyles.iconsColor}
                        beforeFormView={(entity: string, data: FormRenderData) => this.props.beforeFormView && this.props.beforeFormView(entity, 'edit', data)}
                        afterFormView={(entity: string, data: FormRenderData) => this.props.afterFormView && this.props.afterFormView(entity, 'edit', data)}
                    />;
            case ROUTE_ENTITY_CREATE:
                return !canRender ?
                    <LoadingView loading={true} /> :
                    <CreateScreen
                        languages={this.props.appInfo.languages}
                        primaryColor={this.props.appInfo.appStyles.primaryColor}
                        beforeFormView={(entity: string, data: FormRenderData) => this.props.beforeFormView && this.props.beforeFormView(entity, 'create', data)}
                        afterFormView={(entity: string, data: FormRenderData) => this.props.afterFormView && this.props.afterFormView(entity, 'create', data)}
                    />;
            case ROUTE_ENTITY_LIST:
                if (this.props.currentEntity.singleton && canRender) {
                    this.props.history.push(generateRoute(ROUTE_ENTITY_VIEW, { entity: this.props.currentEntity.entity.toLowerCase(), id: this.props.currentEntity.singletonId }));
                    return <></>
                }
                return (
                    <>
                        <RelatedEntities />
                        <div>
                            {
                                !canRender ?
                                    <LoadingView loading={true} /> :
                                    <ListScreen customButtonsList={this.props.customButtonsList} />
                            }
                        </div>
                    </>
                );
            case ROUTE_ENTITY_IMPORT:
                return !canRender ?
                    <LoadingView loading={true} /> :
                    <ImportScreen primaryColor={this.props.appInfo.appStyles.primaryColor} />;
            default:
                return <></>
        }
    }

    public render(): ReactNode {
        if (this.state.canRender) {
            const loading = this.props.loadingInfo || this.state.waiting;
            const { route } = this.props;
            return (
                <div className="wrapper">
                    <LoadingView loading={loading} />
                    {
                        this.props.appInfo.appStyles ? <ScreenSidebar /> : <></>
                    }
                    <div className="main-panel" ref={this.mainPanel}>
                        {
                            loading ?
                                <LoadingView loading={true} /> :
                                <>
                                    {this.renderHeader(route)}
                                    <div className="content">
                                        {this.renderContent(route)}
                                    </div>
                                </>
                        }
                    </div>
                </div>
            );
        }
        if (this.state.notfound) {
            this.props.history.push('/');
        }
        return <></>
    }
}

export default withRouter(connect(mapStateToProps)(EntityScreen) as any) as unknown as React.ComponentType<EntityScreenProps>;
