import React, {ReactNode} from "react";
import Table from "./Table";
import EntityReducer from "../EntityReducer";
import {connect} from "react-redux";
import TaskGetEntityList from "../../../ws/entity/TaskGetEntityList";
import LoadingView from "../../../base/loading/LoadingView";
import Paginator from "./Paginator";
import {Card, CardBody, Col, Row} from "reactstrap";
import TaskGetEntityListUploaded from "../../../ws/entity/TaskGetEntityListUploaded";
import {FiltersType} from "../../../model/ResultsList";
import Filters from "./Filters";
import EntityActions from "../EntityActions";
import {CofingColumns, FilterState} from "../../../model/FiltersState";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import AppInfoReducer from "../../../components/screen/AppInfoReducer";
import DeleteModal from "../Delete/DeleteModal";
import TaskExportList, {ExportListResponse} from "../../../ws/entity/TaskExportList";
import CofingHiddenColumns from "./CofingHiddenColumns";
import Gallery from "./Gallery";
import {RenderCustomButtonsList} from "../../../Module";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(AppInfoReducer.autoMapToProps(), EntityReducer.autoMapToProps());

interface ListScreenProps {
    customButtonsList?: RenderCustomButtonsList
}

type Props = ListScreenProps & typeof mapStateToProps;

class ListScreen extends React.Component<Props> {

    public componentWillMount(): void {
       this.getEntity();
    }

    public componentDidUpdate(prevProps: Readonly<typeof mapStateToProps>): void {
        if (this.props.structure.entity !== prevProps.structure.entity) {
            this.getEntity();
        }
        else if (this.props.filters[this.props.structure.entity] && this.props.filters[this.props.structure.entity].haveToUpdate) {
            this.updateList();
            EntityActions.setFilter.asConnectedAction(
                this.props.structure.entity,
                {...this.props.filters[this.props.structure.entity], haveToUpdate: false}
            );
        }
    }

    public getEntity() {
        if (!this.props.filters[this.props.structure.entity]) {
            new TaskGetEntityList(this.props.structure.entity).execute();
            EntityActions.setFilter.asConnectedAction(
                this.props.structure.entity,
                {
                    orderBy: this.props.structure.moreInfo.orderBy,
                    orderAsc: this.props.structure.moreInfo.orderAsc,
                    page: 1,
                    filters: {} as FiltersType,
                    haveToUpdate: false,
                    columns: this.getDefaultConfigColumns(),
                    extendedResults: false,
                    galleryView: this.props.structure.moreInfo.galleryView,
                    maxPerPageList: this.props.structure.moreInfo.maxPerPageList,
                    maxPerPageGallery: this.props.structure.moreInfo.maxPerPageGallery
                } as FilterState
            );
        }
        else {
            this.updateList()
        }
    }

    public getDefaultConfigColumns(): CofingColumns {
        const columns = {} as CofingColumns;
        Object.keys(this.props.structure.list).map((field) => {
            if (this.props.structure.list[field].listableInExtended) {
                columns[field] = {
                    label: this.props.structure.list[field].label,
                    showByDefault: this.props.structure.list[field].listableByDefault,
                    showInExpanded: this.props.structure.list[field].listableInExtended
                };
            }
        });
        return columns;
    }

    public changeOrder(orderBy: string, orderAsc: boolean) {
        EntityActions.setFilter.asConnectedAction(
            this.props.structure.entity,
            {...this.props.filters[this.props.structure.entity], orderBy, orderAsc, haveToUpdate: true}
        );
    }

    public changeFilters(filters: FiltersType, filtersAutoSuggest: FiltersType) {
        EntityActions.setFilter.asConnectedAction(
            this.props.structure.entity,
            {
                ...this.props.filters[this.props.structure.entity],
                filters,
                filtersAutoSuggest,
                page: 1,
                haveToUpdate: true
            }
        );
    }

    public changePage(page: number) {
        EntityActions.setFilter.asConnectedAction(
            this.props.structure.entity,
            {...this.props.filters[this.props.structure.entity], page, haveToUpdate: true}
        );
    }

    public changeMaxPerPage(num: number, update: boolean, isGalleryView: boolean) {
        EntityActions.setFilter.asConnectedAction(
            this.props.structure.entity,
            {
                ...this.props.filters[this.props.structure.entity],
                page: 1,
                haveToUpdate: update,
                [isGalleryView ? 'maxPerPageGallery' : 'maxPerPageList']: num
            }
        );
    }

    public updateList() {
        const filter = this.props.filters[this.props.structure.entity];
        new TaskGetEntityListUploaded(
            this.props.structure.entity,
            filter.page,
            filter.filters,
            filter.orderBy,
            filter.orderAsc ? 'ASC' : 'DESC',
            filter.galleryView ? filter.maxPerPageGallery : filter.maxPerPageList
        )
            .onFail(() => {
                if (filter.page > 1) {
                    this.changePage(filter.page - 1);
                }
            })
            .execute();
    }

    public exportList() {
        const filter = this.props.filters[this.props.structure.entity];
        new TaskExportList(
            this.props.structure.entity,
            filter.filters,
            filter.orderBy,
            filter.orderAsc ? 'ASC' : 'DESC'
        )
            .onSuccess((data: ExportListResponse) => {
                window.open(data.pdf);
            })
            .execute();
    }

    public showCustomButtons(): ReactNode {
        if (!this.props.customButtonsList) {
            return <></>;
        }

        const filter = this.props.filters ? this.props.filters[this.props.structure.entity] : null;

        if (filter) {
            return this.props.customButtonsList(
                this.props.structure.entity,
                filter.filters,
                filter.orderBy,
                filter.orderAsc ? 'ASC' : 'DESC'
            );
        }

        return  this.props.customButtonsList(this.props.structure.entity, {} as any, '', '');
    }

    public renderDeleteModal(): ReactNode {

        return (
            <DeleteModal
                entity={this.props.deletingEntity}
                onClose={() => {
                    EntityActions.closeDeleteModal.asConnectedAction()
                }}
                onDelete={() => {
                    EntityActions.closeDeleteModal.asConnectedAction();
                    this.updateList()
                }}
                show={this.props.showDeleteModal}
                id={this.props.deletingItem}
                name={this.props.structure.moreInfo.label}
                genre={this.props.structure.moreInfo.genre}
                primaryColor={this.props.appInfo.appStyles.primaryColor}
            />
        );

    }

    public renderFilters(filter: FilterState): ReactNode {

        return (
            <Filters
                changeFilter={(filters, filtersAutoSuggest) => this.changeFilters(filters, filtersAutoSuggest)}
                filters={filter.filters}
                filtersAutoSuggest={this.props.filters[this.props.structure.entity].filtersAutoSuggest}
                entity={this.props.structure.entity}
                primaryColor={this.props.appInfo.appStyles.primaryColor}
                structure={this.props.structure}
                extendedResults={filter.extendedResults}
                exportList={() => this.exportList()}
                canExport={this.props.structure.moreInfo.canExport}
                galleryView={this.props.structure.moreInfo.galleryView}
                isActiveGalleryView={filter.galleryView}
                showCustomButtons={() => this.showCustomButtons()}
            />
        );

    }

    public renderTable(filter: FilterState): ReactNode {

        return (
            <Card>
                <CardBody>
                    <LoadingView loading={this.props.uploadingList}/>
                    <Table
                        orderBy={filter.orderBy}
                        orderAsc={filter.orderAsc}
                        changeOrder={(orderBy, orderAsc) => this.changeOrder(orderBy, orderAsc)}
                        structure={this.props.structure}
                        results={this.props.list.results}
                        primaryColor={this.props.appInfo.appStyles.primaryColor}
                        iconsColor={this.props.appInfo.appStyles.iconsColor}
                        entity={this.props.structure.entity}
                        extendedResults={filter.extendedResults}
                        columns={filter.columns}
                        galleryView={filter.galleryView}
                    />
                    {
                        this.props.structure.moreInfo.galleryView ?
                            <>
                                <LoadingView loading={this.props.uploadingList}/>
                                <Gallery
                                    structure={this.props.structure}
                                    results={this.props.list.results}
                                    iconsColor={this.props.appInfo.appStyles.iconsColor}
                                    entity={this.props.structure.entity}
                                    galleryView={filter.galleryView}
                                    galleryViewBackgroundSize={this.props.structure.moreInfo.galleryViewBackgroundSize}
                                    galleryViewPaddingTop={this.props.structure.moreInfo.galleryViewPaddingTop}
                                    galleryViewColumnClasses={this.props.structure.moreInfo.galleryViewColumnClasses}
                                />
                            </> : <></>
                    }
                    <Paginator
                        currentPage={filter.page}
                        changePage={(page) => this.changePage(page)}
                        primaryColor={this.props.appInfo.appStyles.primaryColor}
                        pages={this.props.list.pages}
                        loading={this.props.uploadingList}
                        numResults={this.props.list.total}
                        maxPerPage={filter.galleryView ? filter.maxPerPageGallery : filter.maxPerPageList}
                        changeMaxPerPage={(num, update) => this.changeMaxPerPage(num, update, filter.galleryView)}
                    />
                </CardBody>
            </Card>
        );

    }

    public render(): ReactNode {

        const filter = this.props.filters[this.props.structure.entity];

        if (filter) {

            return (
                <>
                    <LoadingView loading={this.props.exporting}/>
                    <div className="content-list-screen">
                        { this.renderFilters(filter) }
                        <CofingHiddenColumns entity={this.props.structure.entity} columns={filter.columns} extendedResults={filter.extendedResults} isActiveGalleryView={filter.galleryView}/>
                        {
                            this.props.loadingList || !this.props.structure.list || !this.props.list ?
                                <LoadingView loading={true}/> :
                                <div className="wrapper-list-screen-table">
                                    <Row>
                                        <Col md="12">
                                            { this.renderTable(filter) }
                                        </Col>
                                    </Row>
                                    { this.renderDeleteModal() }
                                </div>
                        }
                    </div>
                </>
            );
        }
        return <LoadingView loading={true}/>

    }
}

export default connect(mapStateToProps)(ListScreen) as React.ComponentType<ListScreenProps>;