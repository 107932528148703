export const TR_CERRAR_SESION = 'TR_CERRAR_SESION';
export const TR_TIMEOUT_MESSAGE = 'TR_TIMEOUT_MESSAGE';
export const TR_NO_NET_MESSAGE = 'TR_NO_NET_MESSAGE';
export const TR_UNAUTHORIZED_ERROR = 'TR_UNAUTHORIZED_ERROR';
export const TR_INTERNAL_SERVER_ERROR = 'TR_INTERNAL_SERVER_ERROR';
export const TR_GENERIC_ERROR = 'TR_GENERIC_ERROR';
export const TR_GENERIC_ERROR_ENTITY = 'TR_GENERIC_ERROR_ENTITY';
export const TR_ACCESS = 'TR_ACCESS';
export const TR_FORM_USER = 'TR_FORM_USER';
export const TR_FORM_PASSWORD = 'TR_FORM_PASSWORD';
export const TR_LOGIN_ERROR = 'TR_LOGIN_ERROR';
export const TR_LOGIN_SAML_INCORRECTO = 'TR_LOGIN_SAML_INCORRECTO';
export const TR_USER_WITHOUT_ENTITIES = 'TR_USER_WITHOUT_ENTITIES';
export const TR_OK_REQUEST_PASSWORD = 'TR_OK_REQUEST_PASSWORD';
export const TR_EMAIL = 'TR_EMAIL';
export const TR_REQUEST = 'TR_REQUEST';
export const TR_FORGOT_PASSWORD = 'TR_FORGOT_PASSWORD';
export const TR_BACK = 'TR_BACK';
export const TR_OK_PASSWORD_CHANGED = 'TR_OK_PASSWORD_CHANGED';
export const TR_OK_PASSWORD_NOT_EQUAL = 'TR_OK_PASSWORD_NOT_EQUAL';
export const TR_REPEAT_PASSWORD = 'TR_REPEAT_PASSWORD';
export const TR_LOGIN = 'TR_LOGIN';
export const TR_RESULTS = 'TR_RESULTS';
export const TR_RESULTS_PER_PAGE = 'TR_RESULTS_PER_PAGE';
export const TR_NO_RESULTS_FOUND = 'TR_NO_RESULTS_FOUND';
export const TR_ACTIONS = 'TR_ACTIONS';
export const TR_PREVIOUS_PAGE = 'TR_PREVIOUS_PAGE';
export const TR_NEXT_PAGE = 'TR_NEXT_PAGE';
export const TR_BUTTON_EDIT = 'TR_BUTTON_EDIT';
export const TR_BUTTON_VIEW = 'TR_BUTTON_VIEW';
export const TR_BUTTON_DELETE = 'TR_BUTTON_DELETE';
export const TR_BUTTON_REMOVE = 'TR_BUTTON_REMOVE';
export const TR_IMPORT = 'TR_IMPORT';
export const TR_YES = 'TR_YES';
export const TR_FILTER = 'TR_FILTER';
export const TR_CLEAR_FILTER = 'TR_CLEAR_FILTER';
export const TR_VIEW_HIDDEN_COLUMNS = 'TR_VIEW_HIDDEN_COLUMNS';
export const TR_HIDE_HIDDEN_COLUMNS = 'TR_HIDE_HIDDEN_COLUMNS';
export const TR_DOWLOAD_LIST = 'TR_DOWLOAD_LIST';
export const TR_GALLERY_VIEW = 'TR_GALLERY_VIEW';
export const TR_LIST_VIEW = 'TR_LIST_VIEW';
export const TR_ERROR_FORM = 'TR_ERROR_FORM';
export const TR_REQUIRED_FIELD = 'TR_REQUIRED_FIELD';
export const TR_REQUIRED_FIELD_IMAGE = 'TR_REQUIRED_FIELD_IMAGE';
export const TR_REQUIRED_FIELD_FILE = 'TR_REQUIRED_FIELD_FILE';
export const TR_ERROR_FIELD = 'TR_ERROR_FIELD';
export const TR_ERROR_FIELD_EMAIL = 'TR_ERROR_FIELD_EMAIL';
export const TR_ERROR_FIELD_PHONE = 'TR_ERROR_FIELD_PHONE';
export const TR_ERROR_FIELD_COLOR = 'TR_ERROR_FIELD_COLOR';
export const TR_ERROR_FIELD_PASSWORD = 'TR_ERROR_FIELD_PASSWORD';
export const TR_ERROR_FIELD_INTEGER = 'TR_ERROR_FIELD_INTEGER';
export const TR_SAVE = 'TR_SAVE';
export const TR_ERROR_EDIT_FORM = 'TR_ERROR_EDIT_FORM';
export const TR_OK_EDIT_FORM_MALE = 'TR_OK_EDIT_FORM_MALE';
export const TR_OK_EDIT_FORM_FEMALE = 'TR_OK_EDIT_FORM_FEMALE';
export const TR_UPLOAD_FILE = 'TR_UPLOAD_FILE';
export const TR_UPLOAD_FILES = 'TR_UPLOAD_FILES';
export const TR_UPLOAD_IMAGE = 'TR_UPLOAD_IMAGE';
export const TR_UPLOAD_IMAGES = 'TR_UPLOAD_IMAGES';
export const TR_NO_IMAGE = 'TR_NO_IMAGE';
export const TR_NO_FILE = 'TR_NO_FILE';
export const TR_NO_SELECTED_FILE = 'TR_NO_SELECTED_FILE';
export const TR_LIST_BACK = 'TR_LIST_BACK';
export const TR_ERROR_UPLOAD_FILE = 'TR_ERROR_UPLOAD_FILE';
export const TR_ADD = 'TR_ADD';
export const TR_CREATE = 'TR_CREATE';
export const TR_ERROR_CREATE_FORM = 'TR_ERROR_CREATE_FORM';
export const TR_OK_CREATE_FORM_MALE = 'TR_OK_CREATE_FORM_MALE';
export const TR_OK_CREATE_FORM_FEMALE = 'TR_OK_CREATE_FORM_FEMALE';
export const TR_ADD_RELATION_ENTITY = 'TR_ADD_RELATION_ENTITY';
export const TR_DELETE = 'TR_DELETE';
export const TR_DELETE_MALE = 'TR_DELETE_MALE';
export const TR_DELETE_FEMALE = 'TR_DELETE_FEMALE';
export const TR_YES_DELETE = 'TR_YES_DELETE';
export const TR_NO = 'TR_NO';
export const TR_ERROR_DELETING = 'TR_ERROR_DELETING';
export const TR_ERROR_PERMISSION_IMPORT = 'TR_ERROR_PERMISSION_IMPORT';
export const TR_OK_IMPORT_MALE = 'TR_OK_IMPORT_MALE';
export const TR_OK_IMPORT_FEMALE = 'TR_OK_IMPORT_FEMALE';
export const TR_TO_IMPORT_CSV = 'TR_TO_IMPORT_CSV';
export const TR_IMPORTANT = 'TR_IMPORTANT';
export const TR_FIRST_IMPORT_FILES_CSV = 'TR_FIRST_IMPORT_FILES_CSV';
export const TR_DOWNLOAD_IMPORT_EXAMPLE = 'TR_DOWNLOAD_IMPORT_EXAMPLE';
export const TR_IMPORT_ERROR_EXT = 'TR_IMPORT_ERROR_EXT';
export const TR_FILES_FOR_COLUMN = 'TR_FILES_FOR_COLUMN';
export const TR_IMAGES_FOR_COLUMN = 'TR_IMAGES_FOR_COLUMN';
export const TR_UPLOAD_CSV_FILE = 'TR_UPLOAD_CSV_FILE';
export const TR_TIME = 'TR_TIME';
export const TR_CANCEL = 'TR_CANCEL';
export const TR_ARE_YOU_SURE_CLOSE_SESSION = 'TR_ARE_YOU_SURE_CLOSE_SESSION';
