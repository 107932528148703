import React, {ReactNode} from "react";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_NEXT_PAGE, TR_PREVIOUS_PAGE, TR_RESULTS, TR_RESULTS_PER_PAGE} from "../../../I18n/constants";

export interface PaginatorProps {
    currentPage: number,
    changePage: (page: number) => void,
    pages: number,
    primaryColor: string,
    loading: boolean,
    numResults: number,
    maxPerPage: number,
    changeMaxPerPage: (num: number, update: boolean) => void
}

class Paginator extends React.Component<PaginatorProps> {

    public state = {haveToUpdateScroll: false};

    public componentDidUpdate(prevProps: Readonly<PaginatorProps>): void {
        if (!this.props.loading && prevProps.loading && this.state.haveToUpdateScroll) {
            setTimeout(() => {
                // @ts-ignore
                let positionList = document.getElementsByClassName("wrapper-list-screen-table")[0].offsetTop;
                const heightList = document.getElementsByClassName("wrapper-list-screen-table")[0].clientHeight;
                if (heightList < window.innerHeight) {
                    positionList -= window.innerHeight - heightList;
                }
                else {
                    positionList -= 15;
                }
                document.getElementsByClassName("main-panel")[0].scrollTop = positionList;
                this.setState({haveToUpdateScroll: false});
            }, 200);
        }
    }

    public getFrom(max: number): number
    {
        let from = this.props.currentPage - max;
        if (from < 1) {
            from = 1;
        }
        else if (this.props.currentPage + max > this.props.pages) {
            from = from + this.props.currentPage + max  - this.props.pages;
        }
        return from;
    }

    public getTo(max: number) {
        let to = this.props.currentPage + max;
        if (this.props.currentPage - max < 1) {
            to = to - this.props.currentPage + max + 1;
        }
        return to;
    }

    public onChangePage(page: number) {
        this.setState({haveToUpdateScroll: true});
        this.props.changePage(page);
    }

    public renderPaginator(): ReactNode {

        if (this.props.pages === 1) {
            return <></>;
        }

        const items: ReactNode[] = [];

        const toXs = this.getTo(2);
        const fromXs = this.getFrom(2);
        const toMd = this.getTo(4);
        const fromMd = this.getFrom(4);
        const toLg = this.getTo(7);
        const fromLg = this.getFrom(7);
        const toXl = this.getTo(10);
        const fromXl = this.getFrom(10);

        for (let i = 1; i <= this.props.pages; i++) {
            const isActive = this.props.currentPage === i;
            const hideXs = i > toXs || i < fromXs;
            const hideMd = i > toMd || i < fromMd;
            const hideXl = i > toXl || i < fromXl;
            const hideLg = i > toLg || i < fromLg;

            if (i === fromXl && i > 1) {
                items.push (<li className={`page-item d-none d-xl-inline-block`} key={i + 'fromXl'}><a className="page-link disabled">...</a></li>);
            }
            if (i === fromLg && i > 1) {
                items.push (<li className={`page-item d-none d-lg-inline-block d-xl-none`} key={i + 'fromLg'}><a className="page-link disabled">...</a></li>);
            }
            if (i === fromMd && i > 1) {
                items.push (<li className={`page-item d-none d-none d-md-inline-block d-lg-none`} key={i + 'fromMd'}><a className="page-link disabled">...</a></li>);
            }
            if (i === fromXs && i > 1) {
                items.push (<li className={`page-item d-none d-sm-inline-block d-md-none`} key={i + 'fromXs'}><a className="page-link disabled">...</a></li>);
            }

            items.push (
                <li className={`page-item ${isActive ? 'active' : hideXl ? 'd-none' :  hideLg ? 'd-none d-xl-inline-block' : hideMd ? 'd-none d-lg-inline-block' : hideXs ? 'd-none d-sm-inline-block' : ''}`} key={i}>
                    <a
                        className="page-link"
                        style={isActive ? {backgroundColor: this.props.primaryColor, borderColor: this.props.primaryColor} : {}}
                        onClick={() => this.onChangePage(i)}
                    >
                        {i}
                    </a>
                </li>
            );

            if (i === toXl && i < this.props.pages) {
                items.push (<li className={`page-item d-none d-xl-inline-block`} key={i + 'toXl'}><a className="page-link disabled">...</a></li>);
            }
            if (i === toLg && i < this.props.pages) {
                items.push (<li className={`page-item d-none d-lg-inline-block d-xl-none`} key={i + 'toLg'}><a className="page-link disabled">...</a></li>);
            }
            if (i === toMd && i < this.props.pages) {
                items.push (<li className={`page-item d-none d-none d-md-inline-block d-lg-none`} key={i + 'toMd'}><a className="page-link disabled">...</a></li>);
            }
            if (i === toXs && i < this.props.pages) {
                items.push (<li className={`page-item d-none d-sm-inline-block d-md-none`} key={i + 'toXs'}><a className="page-link disabled">...</a></li>);
            }
        }

        return (
            <ul className="pagination justify-content-center">
                <li className={`page-item ${this.props.currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => this.onChangePage(this.props.currentPage - 1)}>
                        {I18nUtils.tr(TR_PREVIOUS_PAGE)}
                    </a>
                </li>
                { items }
                <li className={`page-item ${this.props.currentPage === this.props.pages ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => this.onChangePage(this.props.currentPage + 1)}>
                        {I18nUtils.tr(TR_NEXT_PAGE)}
                    </a>
                </li>
            </ul>
        );
    }


    public onKeyPressMaxPerPage(e) {

        const charCode = (e.which) ? e.which : e.keyCode;
        return !(charCode > 31 && (charCode < 48 || charCode > 57));

    }


    public onChangeMaxPerPage(e) {

        const value = e.target.value ? parseInt(e.target.value, 10) : 0;
        this.props.changeMaxPerPage(value, false);
        setTimeout(() => {
            if (value === this.props.maxPerPage) {
                this.props.changeMaxPerPage(this.props.maxPerPage > 0 ? this.props.maxPerPage : 1, true);
            }
        }, 500);
    }



    public render(): ReactNode {

        if (!this.props.pages || this.props.numResults === 0) {
            return <></>
        }

        return (
            <>
                {this.renderPaginator()}
                <div className="text-center">
                    {this.props.numResults} {I18nUtils.tr(TR_RESULTS)}.
                    <input className="input-max-per-page"
                           value={this.props.maxPerPage}
                           onKeyPress={(e) => this.onKeyPressMaxPerPage(e)}
                           onChange={(e) => this.onChangeMaxPerPage(e)}
                    />
                    {I18nUtils.tr(TR_RESULTS_PER_PAGE)}.
                </div>
            </>
        );

    }
}

export default Paginator;