import React, {ReactNode} from "react";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_ERROR_UPLOAD_FILE, TR_NO_FILE, TR_NO_IMAGE, TR_NO_SELECTED_FILE, TR_UPLOAD_FILE, TR_UPLOAD_IMAGE} from "../../../I18n/constants";
import LoadingView from "../../../base/loading/LoadingView";
import TaskUploadFile from "../../../ws/entity/TaskUploadFile";
import ShowField from "../ShowField";

export interface UploadFieldProps {
    value: string,
    entity: string,
    field: string,
    type: string,
    disabled?: boolean,
    onChange: (value, description?) => void,
    removeFile: () => void,
    isEditScreen: boolean
}

class UploadField extends React.Component<UploadFieldProps> {

    public state = { file: {} as FileList, loading: false, error: '' };

    public onChange(file: File | null): void {
        this.setState({file});
        if (file) {
            this.setState({loading: true});
            new TaskUploadFile(this.props.entity, this.props.field, file)
                .onSuccess((result) => {
                    this.props.onChange(result.file);
                    this.setState({error: '', loading: false});
                })
                .onFail((response) => {
                    try {
                        const error = JSON.parse(response);
                        this.setState({error, loading: false});
                    } catch (e) {
                        this.setState({error: I18nUtils.tr(TR_ERROR_UPLOAD_FILE), loading: false});
                    }
                })
                .execute()
        }
    }

    public removeFile() {
        this.setState({error: '', loading: false, file: {}});
        this.props.removeFile();
        const file = document.getElementById(`${this.props.field}-uploadedfile`) as HTMLInputElement
        if (file) {
            file.value = ''
        }
    }

    public render(): ReactNode {
        return (
            <div className="upload-file">
                <LoadingView loading={this.state.loading}/>
                <div>
                    <div className={`alert alert-danger fade show ${this.state.error ? '' : 'd-none'}`}>{this.state.error}</div>
                    {
                        this.props.value ?
                            ShowField(this.props.value, this.props.type, []) :
                            this.props.disabled ? <label>{I18nUtils.tr(this.props.type === 'IMAGE' ? TR_NO_IMAGE : TR_NO_FILE)}</label> : ''
                    }
                    {
                        this.props.disabled ?
                            null :
                            <div className="btn btn-sm">
                                {I18nUtils.tr(this.props.type === 'IMAGE' ? TR_UPLOAD_IMAGE : TR_UPLOAD_FILE)}
                                <i className="fas fa-cloud-upload-alt mr-2" aria-hidden="true"/>
                                <input type="file" id={`${this.props.field}-uploadedfile`}
                                       className="form-control"
                                       onChange={(e) => this.onChange(e.target.files ? e.target.files[0] : null)}
                                       disabled={this.props.disabled}
                                       title={
                                            this.props.value ?
                                            this.props.value.substring(this.props.value.lastIndexOf('/') + 1) :
                                            I18nUtils.tr(TR_NO_SELECTED_FILE)
                                       }
                                />
                            </div>
                    }
                    {
                        (this.props.value && this.props.isEditScreen) ?
                            <div className="btn btn-sm" onClick={() => this.removeFile()}>
                                <i className="fas fa-times mr-2" aria-hidden="true"/>
                            </div>
                        : null
                    }
                </div>
            </div>
        );
    }
}

export default UploadField;