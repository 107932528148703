import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";

export default class TaskGetEntity extends BaseGenericRequest<{}> {

    public constructor(entity: string, id: number, needsTranslation: boolean) {
        super(
            METHOD.METHOD_GET,
            generateRoute(
                needsTranslation ? Urls.getUrlGetEntityTranslations() : Urls.getUrlGetEntity(),
                {entity, id}
            )
        );
    }
}