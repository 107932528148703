import React, {ReactNode} from "react";
import {StructureEntity} from "../../../model/StructureEntity";
import ShowFieldForm from "../../../components/field/ShowFieldForm";

interface FormFieldsProps {
    formStructure: StructureEntity['form'],
    initialDescriptionAutoSuggest: string[],
    disabled?: boolean,
    changeField: (value: string, field: string, lang?: string|null, description?: string) => void,
    removeFile?: (field: string) => void,
    addFieldError: (error: boolean, field: string) => void,
    fields: any,
    renderRequiredErrors: boolean,
    renderErrors: boolean,
    entity: string,
    isEditScreen: boolean,
}

class FormFields extends React.Component<FormFieldsProps> {

    public removeFile(field: string) {
        if (this.props.removeFile) {
            this.props.removeFile(field);
        }
    }

    public render(): ReactNode {
        const {formStructure} = this.props;
        const structureForm: any = Object.keys(formStructure).filter((field) => !formStructure[field].isTranslatable);

        return structureForm.map((key) => {
            const field = formStructure[key];
            let value = '';
            if (typeof this.props.fields[key] !== 'undefined' && this.props.fields[key] !== null) {
                if (field.type === 'DATETIME' || field.type === 'DATE') {
                    value = this.props.fields[key].substr(0, 19);
                } else {
                    value = this.props.fields[key];
                }
            }

            const colClasses = (field.colClasses ? field.colClasses + ' ' : '') + (field.colCustomClasses ? field.colCustomClasses : '');

            return (
                <>
                    { this.renderSeparator(field.separator) }
                    <div key={key} className={colClasses ? colClasses : 'col-md-12'} style={field.style}>
                        <div className="form-group">
                            <label>{field.label}{field.required ? '*' : ''}</label>
                            <ShowFieldForm
                                value={value}
                                initialDescriptionAutoSuggest={this.props.initialDescriptionAutoSuggest[key] ? this.props.initialDescriptionAutoSuggest[key] : ''}
                                field={key}
                                entity={this.props.entity}
                                size={field.size}
                                type={field.type}
                                changeField={(val, field, lang, description) => { this.props.changeField(val, field, lang, description) }}
                                removeFile={(fieldVal) => this.removeFile(fieldVal)}
                                addFieldError={(error) => { this.props.addFieldError(error, key) }}
                                required={field.required}
                                renderRequiredErrors={this.props.renderRequiredErrors}
                                renderErrors={this.props.renderErrors}
                                values={field.values}
                                disabled={this.props.disabled ? true : field.disabled}
                                isEditScreen={this.props.isEditScreen}
                                allFields={this.props.fields}
                            />
                        </div>
                    </div>
                </>
            )
        })
    }

    public renderSeparator(separator: string): ReactNode
    {
        if (separator) {
            return <div className="form-separator col-12">{separator}</div>
        }

        return <></>;
    }
}

export default FormFields;
