import React, {ReactNode} from "react";
import {FieldFValue} from "../../../model/StructureEntity";

export interface SelectorFieldProps {
    value: string,
    values: FieldFValue[],
    disabled?: boolean,
    onEnterSubmit?: () => void,
    onChange: (value, description?) => void
}


class SelectorField extends React.Component<SelectorFieldProps> {

    public componentDidUpdate(prevProps: Readonly<SelectorFieldProps>): void {
        if (this.props.value !== prevProps.value && this.props.onEnterSubmit) {
            this.props.onEnterSubmit();
        }
    }

    public render(): ReactNode {

        return (
            <select
                   className="form-control"
                   value={this.props.value}
                   onChange={(e) => this.props.onChange(e.target.value)}
                   disabled={this.props.disabled}
            >
                <option value=""/>
                {
                    this.props.values.map((option) => {
                        return (
                            <option key={option.id} value={option.id}>
                                {option.description}
                            </option>
                        )
                    })
                }
            </select>
        );
    }
}

export default SelectorField;