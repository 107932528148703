import * as React from 'react';
import { ReactNode} from 'react';
import BaseModal, {BaseModalProps} from "../../../base/modal/BaseModal";
import Modal from "react-bootstrap/Modal";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_CERRAR_SESION,
    TR_DELETE_MALE,
    TR_DELETE_FEMALE, TR_YES_DELETE, TR_NO, TR_DELETE, TR_ERROR_DELETING
} from "../../../I18n/constants";
import TaskDeleteEntity from "../../../ws/entity/TaskDeleteEntity";
import LoadingView from "../../../base/loading/LoadingView";

interface DeleteModalProps {
    id: number,
    entity: string,
    name: string,
    genre: string,
    primaryColor: string,
    onDelete: () => void
}

class DeleteModal extends BaseModal<BaseModalProps & DeleteModalProps> {

    public state = {error: false, errorText: '', loading: false};

    public componentDidUpdate(prevProps: Readonly<BaseModalProps & DeleteModalProps>) {
        if (this.props.show !== prevProps.show) {
            this.setState({error: false, errorText: ''})
        }
    }

    public delete(): void {
        this.setState({loading: true, error: false, errorText: ''});
        new TaskDeleteEntity(this.props.entity, this.props.id)
            .onSuccess(() => {
                this.setState({loading: false});
                this.props.onDelete();
            })
            .onFail((errorText) => this.setState({error: true, errorText, loading: false}))
            .execute();
    }

    public render(): ReactNode {
        return (
            <Modal show={this.props.show} className="deleteModal" dialogClassName="modal-dialog-centered" onHide={() => this.props.onClose()}>
                <LoadingView loading={this.state.loading}/>
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {I18nUtils.tr(TR_DELETE).replace(':entity', this.props.name.toLowerCase())}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label={ I18nUtils.tr(TR_CERRAR_SESION) } onClick={() => this.props.onClose()}>
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className="modal-body text-center">
                        <div className={`col-md-12 mt-3 ${this.state.error ? '' : 'd-none'}`}>
                            <div className="alert alert-danger fade show">
                                {this.state.errorText === '' ? I18nUtils.tr(TR_ERROR_DELETING) : this.state.errorText}
                            </div>
                        </div>
                        <p className="mt-3">
                        {
                            I18nUtils.tr(this.props.genre === 'female' ? TR_DELETE_FEMALE : TR_DELETE_MALE)
                                .replace(':entity', this.props.name.toLowerCase())
                        }
                        </p>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-custom" style={{backgroundColor: this.props.primaryColor}} onClick={() => this.delete()}>
                            {I18nUtils.tr(TR_YES_DELETE)}
                        </button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.props.onClose()}>
                            {I18nUtils.tr(TR_NO)}
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

}

export default DeleteModal;

