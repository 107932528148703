import * as React from 'react';
import { Component, ReactNode } from 'react';
import {connect} from "react-redux";
import { Container, Nav, Navbar, NavbarBrand, NavItem, } from "reactstrap";
import I18nUtils from "../../I18n/I18nUtils";
import { TR_ADD, TR_CERRAR_SESION, TR_IMPORT } from "../../I18n/constants";
import '@fortawesome/fontawesome-free/css/all.css'
import AuthManager from "../../utils/AuthManager";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTE_ENTITY_CREATE, ROUTE_ENTITY_IMPORT } from "../../routing/Routes";
import { generateRoute } from "../../utils/Router";
import LogoutModal from '../../modules/Login/LogoutModal';
import AppInfoReducer from './AppInfoReducer';

interface ScreenHeaderProps {
    title: string,
    buttonCreate: boolean,
    buttonImport: boolean,
    entity?: string
}

const mapStateToProps = AppInfoReducer.autoMapToProps();

class ScreenHeader extends Component<ScreenHeaderProps & RouteComponentProps & typeof mapStateToProps> {

    private readonly sidebarToggle;

    public state = {
        isOpen: false,
        dropdownOpen: false,
        color: "transparent",
        path: "",
        logoutModal: false,
    };

    public constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.dropdownToggle = this.dropdownToggle.bind(this);
        this.sidebarToggle = React.createRef();
    }

    public toggle() {
        if (this.state.isOpen) {
            this.setState({
                color: "transparent"
            });
        } else {
            this.setState({
                color: "dark"
            });
        }
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    public dropdownToggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    public openSidebar() {
        document.documentElement.classList.toggle("nav-open");
        this.sidebarToggle.current.classList.toggle("toggled");
    }

    public updateColor() {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({
                color: "dark"
            });
        } else {
            this.setState({
                color: "transparent"
            });
        }
    }

    public componentDidMount(): void {
        window.addEventListener('resize', this.updateColor.bind(this));
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateColor.bind(this));
    }

    public componentDidUpdate(): void {
        if (
            window.innerWidth < 993 &&
            this.props.history.location.pathname &&
            this.props.history.location.pathname !== this.state.path &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            this.sidebarToggle.current.classList.toggle("toggled");
            this.setState({ path: this.props.history.location.pathname })
        }
    }

    public renderActionButtons(): ReactNode {

        return (
            <>
                {
                    this.props.buttonCreate ?
                        <Link to={generateRoute(ROUTE_ENTITY_CREATE, { entity: this.props.entity })} className="btn create-btn">
                            {I18nUtils.tr(TR_ADD)}
                        </Link> :
                        <></>
                }
                {
                    this.props.buttonImport ?
                        <Link to={generateRoute(ROUTE_ENTITY_IMPORT, { entity: this.props.entity })} className="btn create-btn">
                            {I18nUtils.tr(TR_IMPORT)}
                        </Link> :
                        <></>
                }
            </>
        );

    }

    public render(): ReactNode {

        return (
            <>
                <Navbar
                    color={this.state.color}
                    expand="lg"
                    className={"navbar-absolute fixed-top " + (this.state.color === "transparent" ? "navbar-transparent " : "")}
                >
                    <Container fluid>
                        <div className="navbar-wrapper">
                            <div className="navbar-toggle">
                                <button
                                    type="button"
                                    ref={this.sidebarToggle}
                                    className="navbar-toggler"
                                    onClick={() => this.openSidebar()}
                                >
                                    <span className="navbar-toggler-bar bar1"/>
                                    <span className="navbar-toggler-bar bar2"/>
                                    <span className="navbar-toggler-bar bar3"/>
                                </button>
                            </div>
                            <NavbarBrand href="/">{this.props.title}</NavbarBrand>
                            {this.renderActionButtons()}
                        </div>
                        <Nav navbar>
                            <NavItem>
                                <div className={'flex flex-row align-items-center'}>
                                    <span className={'nav__username'}>{AuthManager.getUsername()}</span>
                                    <a
                                        className="nav-link btn-magnify"
                                        onClick={() => this.setState({logoutModal: true})}
                                        title={I18nUtils.tr(TR_CERRAR_SESION)}
                                    >
                                        <i className="fas fa-unlock-alt"/>
                                    </a>
                                </div>
                            </NavItem>

                        </Nav>
                    </Container>
                </Navbar>
                <LogoutModal
                    show={this.state.logoutModal}
                    onClose={() => this.setState({logoutModal: false})}
                    primaryColor={this.props.appInfo.appStyles.primaryColor}
                    onLogout={() => AuthManager.logout()}
                />
            </>
        );

    }

}


// @ts-ignore
export default connect(mapStateToProps)(withRouter(ScreenHeader)) as React.ComponentType<ScreenHeaderProps>;
