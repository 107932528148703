import React, {ReactNode} from "react";
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import EntityReducer from "../EntityReducer";
import AppInfoReducer from "../../../components/screen/AppInfoReducer";
import {connect} from "react-redux";
import {generateRoute} from "../../../utils/Router";
import {ROUTE_ACTION, ROUTE_ENTITY_LIST} from "../../../routing/Routes";
import {Action, Entity} from "../../../model/AppInfo";
import {Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.css'
import ActionReducer from "../../Action/ActionReducer";

interface itemMenu {
    type: string,
    name: string,
    label: string,
    labelInMenu: string,
    icon: string,
    slug: string,
    superior: string
}

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(EntityReducer.autoMapToProps(), AppInfoReducer.autoMapToProps(), ActionReducer.autoMapToProps());

class RelatedEntities extends React.Component<typeof mapStateToProps> {

    public state = {entities: [] as Entity[], actions: [] as Action[], superior: {} as itemMenu};

    public componentWillMount(): void {
        this.getEntities();
    }

    public componentDidUpdate(prevProps: Readonly<typeof mapStateToProps>): void {
        if (this.props.currentEntity !== prevProps.currentEntity) {
            this.getEntities();
        }
    }

    public getEntities() {
        const superior = {} as itemMenu;
        if (this.props.currentEntity.name) {
            superior.type = 'entity';
            superior.icon = this.props.currentEntity.icon;
            superior.labelInMenu = this.props.currentEntity.labelInMenu;
            superior.name = this.props.currentEntity.entity;
            superior.label = this.props.currentEntity.name;
            superior.slug = this.props.currentEntity.entity;
            superior.superior = this.props.currentEntity.superior;
        }
        else if (this.props.currentAction.name) {
            superior.type = 'action';
            superior.icon = this.props.currentAction.icon;
            superior.label = this.props.currentAction.label;
            superior.labelInMenu = this.props.currentAction.labelInMenu;
            superior.name = this.props.currentAction.name;
            superior.slug = this.props.currentAction.slug;
            superior.superior = this.props.currentAction.superior;
        }

        if (superior.superior) {
            const entities = this.props.appInfo.entities.filter((entity) => entity.superior === superior.superior);
            const actions = this.props.appInfo.actions.filter((action) => action.superior === superior.superior);

            const entitySuperior = this.props.appInfo.entities.find((entity) => entity.entity === superior.superior);
            if (entitySuperior) {
                superior.type = 'entity';
                superior.icon = entitySuperior.icon;
                superior.label = entitySuperior.name;
                superior.labelInMenu = entitySuperior.labelInMenu;
                superior.name = entitySuperior.entity;
                superior.slug = entitySuperior.entity;
                superior.superior = '';
            }
            else {
                const actionSuperior = this.props.appInfo.actions.find((action) => action.name === superior.superior);
                if (actionSuperior) {
                    superior.type = 'action';
                    superior.icon = actionSuperior.icon;
                    superior.label = actionSuperior.label;
                    superior.labelInMenu = actionSuperior.labelInMenu;
                    superior.name = actionSuperior.name;
                    superior.slug = actionSuperior.slug;
                    superior.superior = actionSuperior.superior;
                }
            }

            this.setState({entities, actions, superior });


        }
        else {
            const entities = this.props.appInfo.entities.filter((entity) => entity.superior === superior.name);
            const actions = this.props.appInfo.actions.filter((action) => action.superior === superior.name);
            this.setState({entities, actions, superior });
        }

    }

    public renderSuperiorLink(): ReactNode {
        const {superior} = this.state;
        const link = superior.type === 'entity' ? generateRoute(ROUTE_ENTITY_LIST, {entity: superior.slug}) : generateRoute(ROUTE_ACTION, {action: superior.slug});
        const active = (this.props.currentEntity.entity === superior.name || this.props.currentAction.name === superior.name) && !superior.labelInMenu;
        return (
            <Link
                to={link}
                className={`navbar-brand ${active ? 'active' : ''}`}
                style={active ? {backgroundColor: this.props.appInfo.appStyles.primaryColor} : {}}
            >
                <i className={`mr-2 ${superior.icon}`}/>
                {this.state.superior.labelInMenu ? this.state.superior.labelInMenu : this.state.superior.label}
            </Link>
        );
    }

    public render(): ReactNode {
        const {entities} = this.state;
        const {actions} = this.state;
        const {superior} = this.state;

        if (superior.name && (entities.length > 0 || actions.length > 0)) {
            return (
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="shadow rounded navbarEntities">
                    {this.renderSuperiorLink()}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="mr-auto">
                            {
                                entities.map((entity) => {
                                    if (entity.entity === superior.name && !superior.labelInMenu) {
                                        return null;
                                    }
                                    return (
                                        <Link to={generateRoute(ROUTE_ENTITY_LIST, {entity: entity.entity})} key={entity.entity}
                                              className={`nav-link ${this.props.currentEntity.entity === entity.entity ? 'active' : ''}`}
                                              style={this.props.currentEntity.entity === entity.entity ? {backgroundColor: this.props.appInfo.appStyles.primaryColor} : {}}
                                        >
                                            {entity.name}
                                        </Link>
                                    )
                                })
                            }
                            {
                                actions.map((action) => {
                                    if (action.name === superior.name && !superior.labelInMenu) {
                                        return null;
                                    }
                                    return (
                                        <Link to={generateRoute(ROUTE_ACTION, {action: action.slug})} key={action.name}
                                              className={`nav-link ${this.props.currentAction.name === action.name ? 'active' : ''}`}
                                              style={this.props.currentAction.name === action.name ? {backgroundColor: this.props.appInfo.appStyles.primaryColor} : {}}
                                        >
                                            {action.label}
                                        </Link>
                                    )
                                })
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            );
        }
        return <></>
    }

}

export default connect(mapStateToProps)(RelatedEntities) as React.ComponentType;