import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";

export default class TaskResetPassword extends BaseGenericRequest<{}> {

    private readonly token;
    private readonly password;

    public constructor(token: string, password: string) {
        super(METHOD.METHOD_POST, Urls.getUrlResetPassword());
        this.token = token;
        this.password = password;
    }

    protected getRequest(): {} {
        return {token: this.token, password: this.password};
    }
}