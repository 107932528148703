import React, {ReactNode} from "react";
import {FiltersType} from "../../../model/ResultsList";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_CLEAR_FILTER,
    TR_DOWLOAD_LIST,
    TR_FILTER, TR_GALLERY_VIEW,
    TR_HIDE_HIDDEN_COLUMNS, TR_LIST_VIEW,
    TR_VIEW_HIDDEN_COLUMNS
} from "../../../I18n/constants";
import ShowFieldForm from "../../../components/field/ShowFieldForm";
import {StructureEntity} from "../../../model/StructureEntity";
import EntityActions from "../EntityActions";

interface FiltersProps {
    changeFilter: (filters: FiltersType, filtersAutoSuggest: FiltersType) => void,
    filters: FiltersType,
    filtersAutoSuggest: FiltersType,
    entity: string,
    primaryColor: string,
    structure: StructureEntity,
    extendedResults: boolean,
    exportList: () => void,
    galleryView: boolean,
    isActiveGalleryView: boolean,
    canExport: boolean,
    showCustomButtons?:  () => void
}

class Filters extends React.Component<FiltersProps> {

    public state = {filters: {} as FiltersType, filtersAutoSuggest: {} as FiltersType};

    public componentWillMount(): void {
        this.setState({filters: this.props.filters})
    }

    public componentDidUpdate(prevProps: Readonly<FiltersProps>): void {
        if (prevProps.filters !== this.props.filters) {
            this.setState({filters: this.props.filters});
        }
    }

    public changeField(value: string, searchFor: string, type: string, description?: string|null): void {

        if (type === 'DATETIME' && value.substr(11) === '00:00:00') {
            value = value.substr(0, 10);
        }
        else if (type === 'TIME' && value.substr(6) === '00') {
            value = value.substr(0, 6);
        }

        this.setState({filters: {...this.state.filters, [searchFor]: value}});

        if (type === 'AUTOSUGGEST') {
            this.setState({filtersAutoSuggest: {...this.state.filtersAutoSuggest, [searchFor]: description ? description : ''}});
        }
    }

    public renderExtendDownloadResultsButtons(classMarginTop: string): ReactNode {
        return (
          <>
              <button
                  className={`btn btn-dark pull-right ${classMarginTop} ${this.props.isActiveGalleryView ? 'd-none' : ''}`}
                  onClick={() => EntityActions.setExtendedResults.asConnectedAction(this.props.entity, !this.props.extendedResults) }
                  title={I18nUtils.tr(this.props.extendedResults ? TR_HIDE_HIDDEN_COLUMNS : TR_VIEW_HIDDEN_COLUMNS)}
              >
                  <i className={this.props.extendedResults ? 'fa fa-eye-slash' : 'fa fa-eye'}/>
              </button>
              {
                  this.props.canExport ?
                      <button
                          className={`btn btn-dark pull-right ${classMarginTop}`}
                          onClick={() => this.props.exportList()}
                          title={I18nUtils.tr(TR_DOWLOAD_LIST)}
                      >
                          <i className='fa fa-download'/>
                      </button> : <></>
              }
              {
                  this.props.showCustomButtons &&
                  <>{this.props.showCustomButtons()}</>
              }
              {
                  this.props.galleryView ?
                      <button
                          className={`btn btn-dark pull-right ${classMarginTop}`}
                          onClick={() => EntityActions.setGalleryView.asConnectedAction(this.props.entity, !this.props.isActiveGalleryView) }
                          title={I18nUtils.tr(this.props.isActiveGalleryView ? TR_LIST_VIEW : TR_GALLERY_VIEW)}
                      >
                          <i className={this.props.isActiveGalleryView ? 'fa fa-list' : 'fa fa-th'}/>
                      </button> : <></>
              }
          </>
        );
    }



    public renderFilters(): ReactNode {

        const {filter} = this.props.structure;

        return (
            <div className="card filter-card">
                <div className="card-body">
                    <div className="row">
                        {
                            Object.keys(filter).map((key) => {
                                const fieldFilter = filter[key];
                                const searchValue = this.state.filters[fieldFilter.searchFor];
                                return (
                                    <div key={key} className={fieldFilter.colClassesFilter ? fieldFilter.colClassesFilter : 'col-md-12'}>
                                        <div className="form-group">
                                            <label>{fieldFilter.label}</label>
                                            <ShowFieldForm
                                                entity={this.props.entity}
                                                initialDescriptionAutoSuggest={this.props.filtersAutoSuggest && this.props.filtersAutoSuggest[fieldFilter.searchFor] ? this.props.filtersAutoSuggest[fieldFilter.searchFor] : ''}
                                                value={searchValue ? searchValue : ''}
                                                field={fieldFilter.searchFor}
                                                fieldKey={key}
                                                size={fieldFilter.size}
                                                type={fieldFilter.type}
                                                changeField={(value, _, description) => { this.changeField(value, fieldFilter.searchFor, fieldFilter.type, description) }}
                                                required={false}
                                                renderErrors={false}
                                                renderRequiredErrors={false}
                                                values={fieldFilter.values}
                                                isEditScreen={false}
                                                onEnterSubmit={() => this.props.changeFilter(this.state.filters, this.state.filtersAutoSuggest)}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="row">
                        <div className="col">
                            <button
                                className="btn btn-custom mt-4"
                                style={{backgroundColor: this.props.primaryColor}}
                                onClick={() => this.props.changeFilter(this.state.filters, this.state.filtersAutoSuggest)}
                            >
                                <i className='fa fa-search d-inline-block d-sm-none'/>
                                <span className="d-none d-sm-inline-block">
                                    {I18nUtils.tr(TR_FILTER)}
                                </span>
                            </button>
                            <button
                                className="btn btn-dark mt-4"
                                onClick={() => this.props.changeFilter({} as FiltersType, {} as FiltersType) }
                            >
                                <i className='fa fa-broom d-inline-block d-sm-none'/>
                                <span className="d-none d-sm-inline-block">
                                    {I18nUtils.tr(TR_CLEAR_FILTER)}
                                </span>
                            </button>
                            {this.renderExtendDownloadResultsButtons('mt-4')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }



    public render(): ReactNode {
        if (!this.props.structure.filter) {
            return <></>
        }

        if (Object.keys(this.props.structure.filter).length > 0) {
            return this.renderFilters();
        }

        return (
            <div className="row">
                <div className="col">
                    {this.renderExtendDownloadResultsButtons('')}
                </div>
            </div>
        );
    }
}

export default Filters;