import {ReduxStorageUtils} from "@cuatroochenta/co-redux-builders";
import { reducer as FormReducer } from "redux-form";
import AppInfoReducer from "../components/screen/AppInfoReducer";
import EntityReducer from "../modules/Entity/EntityReducer";
import ActionReducer from "../modules/Action/ActionReducer";

(FormReducer as any).reactotronKey = "form";

export default ReduxStorageUtils.combineReducers([
    /* GENERAL */
    FormReducer as any,
    AppInfoReducer,
    EntityReducer,
    ActionReducer
]);
