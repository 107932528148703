import { METHOD } from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";

export default class TaskLogout extends BaseGenericRequest<{}>{

    public constructor() {
        super(METHOD.METHOD_GET, Urls.getUrlLogout());
    }

    protected getRequest(): {} {
        return {};
    }
}