import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";
import {FieldsToModify} from "../../model/FieldToModify";

export interface TaskGetCustomFormRequest {
    entity: any,
    modifiedFields: any,
    modifiedFormFields: any
}

export default class TaskGetCustomFormEntity extends BaseGenericRequest<TaskGetCustomFormRequest, FieldsToModify> {

    private readonly data;
    private readonly modifiedFields;
    private readonly modifiedFormFields;

    public constructor(
        entity: string,
        data: any,
        dataRelations: any,
        dataFormRelations: any,
        modifiedFields: any,
        modifiedFormFields: any,
        oid?: number
    ) {
        super(METHOD.METHOD_POST, generateRoute(Urls.getUrlGetCustomOptions(), {entity}));
        this.data = Object.assign({}, data);
        this.modifiedFields = modifiedFields;
        this.modifiedFormFields = modifiedFormFields;

        if (oid) {
            this.data.oid = oid;
        }

        this.data.dataRelations = dataRelations;
        this.data.dataFormRelations = dataFormRelations;
    }

    protected getRequest(): TaskGetCustomFormRequest {
        return {entity: this.data, modifiedFields: this.modifiedFields, modifiedFormFields: this.modifiedFormFields};
    }
}