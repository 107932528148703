import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";
import {FiltersType, ResultsList} from "../../model/ResultsList";

export interface UploadListRequest {
    filters: FiltersType,
    order: string,
    orderby: string,
    maxPerPage: number
}

export default class TaskGetEntityListUploaded extends BaseGenericRequest<UploadListRequest, ResultsList> {

    private readonly filters;
    private readonly order;
    private readonly orderby;
    private readonly maxPerPage;

    public constructor(entity: string, page: number, filters: FiltersType, orderby: string, order: string, maxPerPage: number) {
        super(METHOD.METHOD_POST, generateRoute(Urls.getUrlGetEntityList(), {entity, page}));
        this.filters = filters;
        this.orderby = orderby;
        this.order = order;
        this.maxPerPage = maxPerPage;
    }

    protected getRequest(): UploadListRequest {
        return {filters: this.filters, orderby: this.orderby, order: this.order, maxPerPage: this.maxPerPage};
    }
}