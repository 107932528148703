import React, {ReactNode} from "react";
import InputTextField from "./types/InputTextField";
import {FieldFormProps} from "../../model/FieldFormProps";
import SelectorField from "./types/SelectorField";
import DateField from "./types/DateField";
import ColorField from "./types/ColorField";
import TextareaField from "./types/TextareaField";
import normalize from "./normalize";
import validate from "./validate";
import RenderErrorsField from "./RenderErrorsField";
import BooleanField from "./types/BooleanField";
import UploadField from "./types/UploadField";
import HtmlField from "./types/HtmlField";
import AutoSuggestField from "./types/AutoSuggestField";
import InputCheckboxField from "./types/InputCheckboxField";
import AppInfoReducer from "../screen/AppInfoReducer";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import validateRequired from "./validateRequired";

const mapStateToProps = AppInfoReducer.autoMapToProps();


class ShowFieldForm extends React.Component<FieldFormProps & typeof mapStateToProps> {

    public state = {error: false, modified: false};

    public componentWillMount(): void {
        this.checkError(this.props.value, false);
    }

    public onChange(value: string) {
        value = normalize(this.props.type, value, this.props.value, this.props.size);
        this.props.changeField(value, this.props.field);
        setTimeout(() => {
            if (this.props.value === value) {
                this.checkError(value, true);
            }
        }, 500);
    }

    public removeFile() {
        if (this.props.removeFile) {
            this.setState({modified: true});
            this.props.removeFile(this.props.field);
        }
    }

    public checkError(value, modified: boolean) {
        if (this.props.addFieldError) {
            const error = !validate(this.props.type, value);
            this.setState({modified, error});
            const completeError = error || !validateRequired(this.props.required, this.props.type, this.props.value, this.props.isEditScreen);
            this.props.addFieldError(completeError, this.props.field);
        }
    }

    public onChangeAutoSuggest(value: string, description: string) {
        this.props.changeField(value, this.props.field, null, description);
        if (this.props.addFieldError) {
            const error = this.props.required && (value.toString().trim() === '' && description !== '');
            this.setState({modified: true, error});
            this.props.addFieldError(error, this.props.field);
        }
    }

    public renderField(): ReactNode {
        switch (this.props.type) {
            case 'COLOR':
                return <ColorField {...this.props} onChange={(value) => this.onChange(value)}/>;
            case 'DATETIME':
            case 'DATE':
            case 'TIME':
                return <DateField {...this.props} onChange={(value) => this.onChange(value)}/>;
            case 'PKINT':
                return <SelectorField {...this.props} onChange={(value) => this.onChange(value)}/>;
            case 'TEXT':
               return <TextareaField {...this.props} onChange={(value) => this.onChange(value)}/>;
            case 'BOOLEAN':
               return <BooleanField {...this.props} primaryColor={this.props.appInfo.appStyles.primaryColor} onChange={(value) => this.onChange(value)}/>;
            case 'CHECKBOX':
               return <InputCheckboxField {...this.props} onChange={(value) => this.onChange(value)}/>;
            case 'FILE':
            case 'IMAGE':
               return <UploadField {...this.props} onChange={(value) => this.onChange(value)} removeFile={() => this.removeFile()}/>;
            case 'HTML':
               return <HtmlField {...this.props} onChange={(value) => this.onChange(value)}/>;
            case 'AUTOSUGGEST':
               return <AutoSuggestField {...this.props} onChange={(value, description) => this.onChangeAutoSuggest(value, description)}/>;
            default:
               return <InputTextField {...this.props} onChange={(value) => this.onChange(value)}/>;
        }
    }

    public render(): ReactNode {
        return (
            <>
                {this.renderField()}
                <RenderErrorsField
                    value={this.props.value}
                    type={this.props.type}
                    error={this.state.error}
                    required={this.props.required}
                    renderErrors={this.props.renderErrors}
                    renderRequiredErrors={this.props.renderRequiredErrors}
                    modified={this.state.modified}
                    isEditScreen={this.props.isEditScreen}
                />
            </>
        );
    }


}

export default withRouter(connect(mapStateToProps)(ShowFieldForm) as any) as unknown as React.ComponentType<FieldFormProps>;
