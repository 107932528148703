import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";
import {FieldFValue} from "../../model/StructureEntity";

export interface GetAutoSuggestOptionsRequest {
    field: string,
    search: string
    allFields: any
    exclude: AutoSuggestExclude
}

export interface AutoSuggestExclude {
    excludeIds: number[],
    tableField: string|null,
    tableId: number|null
}

export default class TaskGetAutoSuggestOptions extends BaseGenericRequest<GetAutoSuggestOptionsRequest, FieldFValue[]> {

    private readonly field;
    private readonly search;
    private readonly exclude;
    private readonly allFields;

    public constructor(entity: string, field: string, search: string, exclude: AutoSuggestExclude, allFields?: any) {
        super(METHOD.METHOD_POST, generateRoute(Urls.getUrlGetAutosuggestOptions(), {entity}));
        this.field = field;
        this.search = search;
        this.exclude = exclude;
        this.allFields = allFields ? allFields : null;
    }

    protected getRequest(): GetAutoSuggestOptionsRequest {
        return {
            field: this.field,
            search: this.search,
            exclude: this.exclude,
            allFields: this.allFields
        };
    }
}