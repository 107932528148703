import * as React from 'react';
import {Component, ReactNode} from 'react';
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import AppInfoReducer from "./AppInfoReducer";
import {connect} from "react-redux";
import ConfigModule from "../../ConfigModule";
import {generateRoute} from "../../utils/Router";
import {ROUTE_ACTION, ROUTE_ENTITY_LIST, ROUTE_ENTITY_VIEW} from "../../routing/Routes";
import '@fortawesome/fontawesome-free/css/all.css'
import {ReducerBuilder} from "@cuatroochenta/co-redux-builders";
import EntityReducer from "../../modules/Entity/EntityReducer";
import ActionReducer from "../../modules/Action/ActionReducer";

let ps;

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(AppInfoReducer.autoMapToProps(), EntityReducer.autoMapToProps(), ActionReducer.autoMapToProps());

class ScreenSidebar extends Component<typeof mapStateToProps> {

    public state = { linkHover: -1, heightWrapperImg: 0};

    private readonly sidebar;

    public constructor(props) {
        super(props);
        this.sidebar = React.createRef();
    }

    public componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }

    public componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    public renderEntitiesSidebar(): ReactNode {
        const {currentEntity} = this.props;
        const {currentAction} = this.props;

        return this.props.appInfo.entities.filter((entity) => entity.showInMenu).map((prop, key) => {
            const path = prop.singleton ? generateRoute(ROUTE_ENTITY_VIEW, {entity: prop.entity.toLowerCase(), id: prop.singletonId}) : generateRoute(ROUTE_ENTITY_LIST, {entity: prop.entity});
            const isActive = currentEntity && (currentEntity.entity === prop.entity || ((currentEntity.superior === prop.entity && !currentEntity.showInMenu) || (currentAction.superior === prop.entity && !currentAction.showInMenu)) );
            const color = isActive || this.state.linkHover === key ? this.props.appInfo.appStyles.activeLinkColorSidebar : this.props.appInfo.appStyles.linkColorSidebar;
            return this.renderLinkSidebar(isActive, key, path, color, prop.icon, prop.labelInMenu, prop.name);
        });
    }

    public renderActionsSidebar(first: boolean): ReactNode {
        const {currentEntity} = this.props;
        const {currentAction} = this.props;

        return this.props.appInfo.actions.filter((action) => action.showInMenu && action.isFirstItemMenu === first).map((prop, key) => {
            const path = generateRoute(ROUTE_ACTION, {action: prop.slug});
            const isActive = currentAction && (currentAction.name === prop.name || ((currentEntity.superior === prop.name && !currentEntity.showInMenu) || (currentAction.superior === prop.name && !currentAction.showInMenu)) );
            const color = isActive || this.state.linkHover === key ? this.props.appInfo.appStyles.activeLinkColorSidebar : this.props.appInfo.appStyles.linkColorSidebar;
            return this.renderLinkSidebar(isActive, key, path, color, prop.icon, prop.labelInMenu, prop.name);
        });
    }

    public renderLinkSidebar(isActive: boolean, key: number, path: string, color: string, icon: string, labelInMenu: string, name: string): ReactNode {
        return (
            <li className={isActive ? 'active' : ''} key={key} onMouseEnter={() => this.setState({linkHover: key})} onMouseLeave={() => this.setState({linkHover: -1})}>
                <NavLink to={path} className="nav-link" activeClassName="active" style={{color}}>
                    <i className={icon} style={{color}}/>
                    <p>{labelInMenu ? labelInMenu : name}</p>
                </NavLink>
            </li>
        );
    }

    public onLogoImgLoad() {
        // @ts-ignore
        this.setState({ heightWrapperImg: document.getElementById('logo-sidebar').offsetHeight + 20});
    }


    public render(): ReactNode {

        return (
            <div style={{backgroundColor: this.props.appInfo.appStyles.bgColorSidebar}}
                 className="sidebar"
            >
                <div className="logo" id="logo-sidebar">
                    <div className="logo-img text-center mt-2">
                        <img src={ConfigModule.BASE_IMAGENES + this.props.appInfo.logoMenu} alt="react-logo" onLoad={ () => this.onLogoImgLoad()}/>
                    </div>
                    <hr style={{borderColor: this.props.appInfo.appStyles.linkColorSidebar}}/>
                </div>
                <div className="sidebar-wrapper" ref={this.sidebar} style={{height: 'calc(100vh - ' + this.state.heightWrapperImg + 'px)'}}>
                    <Nav>
                        {this.renderActionsSidebar(true)}
                        {this.renderEntitiesSidebar()}
                        {this.renderActionsSidebar(false)}
                    </Nav>
                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps)(ScreenSidebar) as React.ComponentType;
