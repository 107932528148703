import * as React from 'react';
import {Component, ReactNode} from 'react';
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import TaskResetPasswordRequest from "../../ws/login/TaskResetPasswordRequest";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_BACK,
    TR_EMAIL,
    TR_OK_REQUEST_PASSWORD,
    TR_REQUEST,
} from "../../I18n/constants";
import AuthManager from "../../utils/AuthManager";
import TaskGetAppInfo from "../../ws/app/TaskGetAppInfo";
import {AppInfo} from "../../model/AppInfo";
import AppInfoReducer from "../../components/screen/AppInfoReducer";
import {connect} from "react-redux";
import LoadingView from "../../base/loading/LoadingView";
import ConfigModule from '../../ConfigModule';

const mapStateToProps = AppInfoReducer.autoMapToProps();

class LostPasswordScreen extends Component<RouteComponentProps & typeof mapStateToProps> {

    public state = { email: '', message: '', error: '', waiting: true, passwordSend: false };

    public componentDidMount(): void {

        if (AuthManager.isLogged()) {
            this.props.history.push('/');
        }
        else if (this.props.loadingInfo) {
            new TaskGetAppInfo()
                .onSuccess(() => {

                })
                .onSuccess((result: AppInfo) => {
                    if (result.allowResetPassword) {
                        document.title = result.name;
                        this.setState({waiting: false});
                    }
                    else {
                        this.props.history.push('/');
                    }
                })
                .execute();
        }
        else {
            if (this.props.appInfo.allowResetPassword) {
                this.setState({waiting: false});
            }
            else {
                this.props.history.push('/');
            }
        }

    }

    public rememberPassword() {
        if (this.state.email !== '') {
            this.setState({waiting: true})
            new TaskResetPasswordRequest(this.state.email)
                .onSuccess(() => this.setState({message: I18nUtils.tr(TR_OK_REQUEST_PASSWORD), waiting: false, passwordSend: true}))
                .onFail((response) => {
                    try {
                        const errors = JSON.parse(response);
                        this.setState({error: errors[0], waiting: false});
                    } catch (e) {
                        this.setState({error: response, waiting: false});
                    }
                })
                .execute();
        }
    }

    public render(): ReactNode {

        if (!this.props.appInfo.appStyles) {
            return <div className="wrapper"><LoadingView loading={true}/></div>
        }

        return (
            <div className="wrapper" id="lost-password">
                <div className="d-flex justify-content-center h-100 align-items-center">
                    <div className="card login-card">
                        <div className="card-body">
                            <div className="d-flex justify-content-center">
                                <div className="brand_logo_container">
                                    <img
                                        src={ConfigModule.BASE_IMAGENES + this.props.appInfo.logo}
                                        className="brand_logo" alt="Logo"/>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center form_container">
                                <div>
                                    <div className={`error-message mb-3 ${this.state.error ? '' : 'd-none'}`}>
                                        { this.state.error }
                                    </div>
                                    <div className={`success-message mb-3 ${this.state.message ? '' : 'd-none'}`}>
                                        { this.state.message }
                                    </div>
                                    <LoadingView loading={this.state.waiting}/>
                                    <form onSubmit={ (e) => { e.preventDefault(); this.rememberPassword() } }>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="mail">{ I18nUtils.tr(TR_EMAIL) }</label>
                                                    <input type="email"
                                                           className="form-control"
                                                           value={this.state.email}
                                                           onChange={(e) => {this.setState({email: e.target.value, message: '', error: ''})}}
                                                           onKeyPress={(e) => { e.key === 'Enter' ? this.rememberPassword() : {} }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-2">
                                            <button type="submit" className="btn btn-lg btn-custom" style={{backgroundColor: this.props.appInfo.appStyles.primaryColor}}>
                                                { I18nUtils.tr(TR_REQUEST) }
                                            </button>
                                        </div>
                                    </form>
                                    <div className="lost-password-link">
                                        <Link to="">
                                            {I18nUtils.tr(TR_BACK)}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(connect(mapStateToProps)(LostPasswordScreen) as any) as React.ComponentType<{}>;

