import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import AuthManager from "../../utils/AuthManager";
import { AppInfo } from '../../model/AppInfo';

export default class TaskGetAppInfo extends BaseGenericRequest<{}, AppInfo> {

    public constructor() {
        super(METHOD.METHOD_GET,  Urls.getUrlAppInfo(AuthManager.isLogged()));
    }
}