
const normalize = (type: string, value: string, prevValue: string, size: number) => {
    if (!value) {
        return value;
    }
    switch (type) {
        case 'PHONE':
            const regPhone = /^[\+\d]?(?:[\d-.\s()]*)$/;
            if (!regPhone.test(value) && regPhone.test(prevValue)) {
                value = prevValue;
            }
            break;
        case 'INTEGER':
            const regInt = /^-?\d+$/;
            if (!regInt.test(value) && regInt.test(prevValue)) {
                value = prevValue;
            }
            break;
        case 'FLOAT':
            const regFloat = /^\d*\.?\d*$/;
            if (!regFloat.test(value) && regFloat.test(prevValue)) {
                value = prevValue;
            }
            break;
    }
    if (size && value.length > size) {
        if (prevValue.length <= size) {
            value = prevValue;
        }
    }
    return value;
}

export default normalize
