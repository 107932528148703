import React from "react";

const preventEnterSubmit = (event: React.KeyboardEvent<HTMLInputElement>, onEnterSubmit?: () => void) => {
    switch (event.keyCode) {
        case 13: {
            event.preventDefault();
            if (onEnterSubmit) {
                onEnterSubmit();
            }
        }
    }
};

export default preventEnterSubmit;