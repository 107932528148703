import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";

export default class TaskDeleteEntity extends BaseGenericRequest<{}> {

    public constructor(entity: string, id: number) {
        super(METHOD.METHOD_DELETE, generateRoute(Urls.getUrlGetEntityDelete(), {entity, id}));
    }
}