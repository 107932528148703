
const validate = (type: string, value: string): boolean => {
    if (!value || value === '') {
        return true;
    }
    switch (type) {
        case 'PHONE':
            const regPhone = /^[\+\d]?(?:[\d-.\s()]*)$/;
            return regPhone.test(value);
        case 'EMAIL':
            const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return regEmail.test(value);
        case 'PASSWORD':
            const regPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
            return regPassword.test(value);
        case 'COLOR':
            const regColor = /^#([0-9a-f]{3}){1,2}$/;
            return regColor.test(value);
        case 'INTEGER':
            const regInt = /^-?\d+$/;
            return regInt.test(value);
        case 'FLOAT':
            const regFloat = /^\d*\.?\d*$/;
            return regFloat.test(value);
    }
    return true;
}

export default validate
