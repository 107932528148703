import React, { ReactNode } from "react";
import { FieldFValue, StructureEntity } from "../../../model/StructureEntity";
import { Tab, Tabs } from "react-bootstrap";
import FormFields from "./FormFields";
import FormTranslatableFields from "./FormTranslatableFields";
import FormRelationsTable from "./FormRelationsTable";
import I18nUtils from "../../../I18n/I18nUtils";
import { TR_ADD, TR_BUTTON_DELETE } from "../../../I18n/constants";
import FormRelationsTableNewValues from "./FormRelationsTableNewValues";
import AutoSuggestField from "../../../components/field/types/AutoSuggestField";
import {AutoSuggestExclude} from "../../../ws/entity/TaskGetAutoSuggestOptions";

interface FormRelationsFormProps {
    structure: StructureEntity,
    data: any,
    entity: string,
    oid?: number,
    relationFormValues: any,
    initialDescriptionAutoSuggest: { [key: string]: any[] },
    onChangeRelationFormValue: (relationKey: string, index: number, field: string, value: string, lang?: string) => void,
    addFieldError: (relationKey: string, index: number, field: string, error: boolean, lang?: string) => void,
    addRelationFormValue: (relationKey: string) => void,
    addTableRelationFormValue: (relationKey: string, value: any) => void,
    removeRelationFormValue: (relationKey: string, index: number) => void,
    disabled?: boolean,
    isEditScreen: boolean,
    languages: string[],
    renderRequiredErrors: boolean,
    primaryColor?: string,
    iconsColor?: string,
    removeFile: (field: string) => void
}

export interface AutosuggestFieldValues {
    [dynamic: string]: {//nombre de la relación
        [dynamic: string] : {//nombre del campo
            [dynamic: number] : string//id : descripción
        }
    }
}

class FormRelationsForm extends React.Component<FormRelationsFormProps> {

    public state = {
        tab: '',
        canRender: false,
        relationsTabs: [] as string[],
        showAdd: false,
        newRelationValues: {},
        newRelationFormValues: {},
        data: this.props.data,
        autosuggestExcludeIds: [] as number[],
        autosuggestFieldValues: {} as AutosuggestFieldValues,
    };

    public componentWillMount(): void {
        const { formRelations } = this.props.structure;
        const relationsTabs = Object.keys(formRelations).filter((key) => formRelations[key].type === 'FORM' || formRelations[key].type === 'TABLE');
        let newRelationValues = {}
        let newRelationFormValues = {}
        Object.keys(formRelations).map((relation) => {
            newRelationValues[relation] = [];
            newRelationFormValues[relation] = [];
        });

        this.setState({
            relationsTabs,
            canRender: !!relationsTabs,
            tab: relationsTabs ? this.props.structure.formRelations[0] : '',
            newRelationValues: newRelationValues,
            newRelationFormValues: newRelationFormValues
        });
    }

    public componentDidUpdate(_: Readonly<FormRelationsFormProps>, prevState: Readonly<{ errors: any, data: any }>) {
        if(this.props.data != prevState.data) {
            this.setState({
                newRelationFormValues: {},
                data: this.props.data,
                autosuggestExcludeIds: []
            });
        }
    }

    public renderForm(relationKey: string, _values: FieldFValue[], entityRelation: string, _canEditEntityRelation: boolean, _canShowEntityRelation: boolean): ReactNode {

        const valuesForm: any[] = this.props.relationFormValues[relationKey] || [];
        const initialDescriptionAutoSuggest = this.props.initialDescriptionAutoSuggest[relationKey] || [];

        return (
            <>
                {valuesForm.map((_values, index: number) => (
                    <React.Fragment key={index}>
                        <div className="col-md-12">
                            <div className="card shadow p-3">
                                <div className="card-body">
                                    <div className="row">
                                        <FormFields
                                            entity={entityRelation}
                                            formStructure={this.props.structure.formRelations[relationKey].formStructure}
                                            initialDescriptionAutoSuggest={initialDescriptionAutoSuggest[index] || []}
                                            changeField={(value, field) => {
                                                this.props.onChangeRelationFormValue(relationKey, index, field, value)
                                            }}
                                            addFieldError={(error, field) => {
                                                this.props.addFieldError(relationKey, index, field, error)
                                            }}
                                            fields={this.props.relationFormValues[relationKey][index]}
                                            disabled={this.props.disabled}
                                            renderRequiredErrors={this.props.renderRequiredErrors}
                                            renderErrors={true}
                                            isEditScreen={this.props.isEditScreen}
                                            removeFile={(field) => this.props.removeFile(relationKey + '.' + field + '.' + index)}
                                        />
                                        <FormTranslatableFields
                                            entity={this.props.entity}
                                            formStructure={this.props.structure.formRelations[relationKey].formStructure}
                                            initialDescriptionAutoSuggest={initialDescriptionAutoSuggest[index] || []}
                                            changeField={(value, field, lang) => {
                                                this.props.onChangeRelationFormValue(relationKey, index, field, value, lang)
                                            }}
                                            addFieldError={(error, field, lang) => {
                                                this.props.addFieldError(relationKey, index, field, error, lang)
                                            }}
                                            fields={this.props.relationFormValues[relationKey][index]}
                                            languages={this.props.languages}
                                            disabled={this.props.disabled}
                                            renderRequiredErrors={this.props.renderRequiredErrors}
                                            renderErrors={true}
                                            isEditScreen={this.props.isEditScreen}
                                        />
                                        {!this.props.disabled &&
                                            <div className="col-md-12">
                                                <button
                                                    type="button"
                                                    className="btn btn-custom float-right"
                                                    style={{ backgroundColor: this.props.primaryColor }}
                                                    onClick={() => this.props.removeRelationFormValue(relationKey, index)}
                                                >
                                                    <i className={'fa fa-trash'} />
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                {!this.props.disabled &&
                    <div className="col-md-12">
                        <button
                            type="button"
                            className="btn btn-custom float-right"
                            style={{ backgroundColor: this.props.primaryColor }}
                            onClick={() => this.props.addRelationFormValue(relationKey)}
                        >
                            <i className={'fa fa-plus'} />
                        </button>
                    </div>
                }
            </>
        )

    }

    public renderTable(relationKey: string, _values: FieldFValue[], entityRelation: string, entityRelationCamelcase: string, _canEditEntityRelation: boolean, _canShowEntityRelation: boolean): ReactNode {
        return (
            <>
                <FormRelationsTable
                    relationKey={relationKey}
                    data={this.props.data}
                    relationFormValues={this.props.relationFormValues}
                    structure={this.props.structure.formRelations[relationKey]}
                    primaryColor={this.props.primaryColor}
                    iconsColor={this.props.iconsColor}
                    entityRelation={entityRelation}
                    entityRelationCamelcase={entityRelationCamelcase}
                    entity={this.props.entity}
                    parentEntity={this.props.structure.formRelations[relationKey].filterRelationName ?? this.props.entity}
                    oid={this.props.oid}
                    removeRelationFormValue={this.props.removeRelationFormValue}
                    addTableRelationFormValue={this.props.addTableRelationFormValue}
                    isEditScreen={this.props.isEditScreen} />
                <FormRelationsTableNewValues
                    relationKey={relationKey}
                    relationFormValues={this.props.relationFormValues}
                    newRelationFormValues={this.state.newRelationFormValues}
                    autosuggestFieldValues={this.state.autosuggestFieldValues}
                    structure={this.props.structure.formRelations[relationKey]}
                    primaryColor={this.props.primaryColor}
                    iconsColor={this.props.iconsColor}
                    entity={entityRelation}
                    removeRelationFormValue={this.props.removeRelationFormValue}
                    removeNewRelationValueFromTable={this.removeNewRelationValueFromTable.bind(this)}
                    isEditScreen={this.props.isEditScreen} />
                {
                    this.state.showAdd &&
                    <div>
                        {this.renderTableRelationForm(relationKey, entityRelation)}
                    </div>
                }
                {
                    !this.props.disabled && this.props.structure.formRelations[relationKey].canEdit && this.renderTableAddRelation(relationKey)
                }
            </>
        )
    }

    public renderTableAddRelation(relationKey: string) {
        if (this.props.structure.formRelations[relationKey].addsAsAutosuggest) {
            let exclude = {excludeIds: this.state.autosuggestExcludeIds} as AutoSuggestExclude;
            if(this.props.oid) {
                exclude = {
                    excludeIds: this.state.autosuggestExcludeIds,
                    tableField: relationKey + '.' + this.props.structure.entity,
                    tableId: this.props.oid
                }
            }
            return <div className="col-md-12">
                <AutoSuggestField
                    value={''}
                    initialDescriptionAutoSuggest={''}
                    entity={this.props.entity}
                    field={relationKey}
                    onChange={(id, description, object) => this.autosuggestOnChange(relationKey, {id, description, object})}
                    emptyWhenSelected={true}
                    exclude={exclude}/>
            </div>
        }

        return <div className="col-md-12">
            <button
                type="button"
                className="btn btn-custom float-right"
                style={{ backgroundColor: this.props.primaryColor }}
                onClick={() => this.addNewRelationValues(relationKey)}
            >
                <i className={'fa fa-plus'} />
            </button>
        </div>
    }

    public autosuggestOnChange(relationKey, value:FieldFValue) {
        if(value.object) {
            this.props.addTableRelationFormValue(relationKey, value.object)
            let newRelationFormValues = this.state.newRelationFormValues;
            if(!newRelationFormValues[relationKey]) {
                newRelationFormValues[relationKey] = [];
            }
            newRelationFormValues[relationKey].push(value.object);
            this.setState({
                newRelationFormValues: newRelationFormValues,
                autosuggestExcludeIds: [...this.state.autosuggestExcludeIds, value.id]
            });
        }
    }

    public addNewRelationValues(relationKey) {
        const relationStructure = this.props.structure.formRelations[relationKey].formStructure;
        const data = {}
        Object.keys(relationStructure).map((field) => {
            if (!relationStructure[field].isTranslatable) {
                data[field] = '';
            }
        });
        let updatedRelationValues = { ...this.state.newRelationValues };
        updatedRelationValues[relationKey].push(data);

        this.setState({ showAdd: true, newRelationValues: updatedRelationValues });
    }

    public onChangeRelationFormValue = (relationKey: string, index: number, field: string, value: any, lang?: string|null, description?: string) => {
        if (!this.props.disabled) {
            if (this.props.structure.formRelations[relationKey].formStructure[field].type === 'BOOLEAN' && value === '') {
                value = false;
            }
            if (!lang) {
                if(value !== "" && description) {
                    let autosuggestFieldValues = this.state.autosuggestFieldValues;
                    if (!(relationKey in autosuggestFieldValues)) {
                        autosuggestFieldValues = {...autosuggestFieldValues, [relationKey]: {}}
                    }
                    if (!(field in autosuggestFieldValues[relationKey])) {
                        autosuggestFieldValues[relationKey] = {...autosuggestFieldValues[relationKey], [field] : {}}
                    }
                    autosuggestFieldValues[relationKey][field][value] = description;
                    this.setState({autosuggestFieldValues});
                }
                let updatedNewRelationValues = { ...this.state.newRelationValues }
                updatedNewRelationValues[relationKey][index][field] = value;
                this.setState({ newRelationValues: updatedNewRelationValues })
            } else {
                let translations;
                if (!this.state.newRelationValues[relationKey]![index].translations || this.state.newRelationValues[relationKey]![index].constructor === Array) {
                    translations = {};
                } else {
                    translations = this.state.newRelationValues[relationKey][index].translations;
                }

                if (!translations[lang]) {
                    translations[lang] = {};
                    translations[lang].locale = lang;
                }
                translations[lang][field] = value;

                let updatedNewRelationValues = { ...this.state.newRelationValues }
                updatedNewRelationValues[relationKey][index]['translations'] = translations;
                this.setState({ newRelationValues: updatedNewRelationValues })
            }
        }
    }

    public removeNewRelationValueFromTable(relationKey: string, index: number, data: any) {
        if("oid" in data) {
            this.setState({
                autosuggestExcludeIds: this.state.autosuggestExcludeIds.filter(
                    (id) => {
                        return id != data.oid
                    })
            });
        }
        let updatedRelationValues = { ...this.state.newRelationFormValues };
        updatedRelationValues[relationKey].splice(index, 1);
        this.setState({ newRelationFormValues: updatedRelationValues });
    }

    public removeNewRelationFormValue(relationKey: string, index: number, addTableFromValues?: boolean) {
        if (addTableFromValues) {
            this.props.addTableRelationFormValue(relationKey, this.state.newRelationValues[relationKey][index]);
            this.addNewRelationFormValues(relationKey, this.state.newRelationValues[relationKey][index]);
        }
        let updatedRelationValues = { ...this.state.newRelationValues };
        updatedRelationValues[relationKey].splice(index, 1);
        this.setState({ newRelationValues: updatedRelationValues });
    }

    public addNewRelationFormValues(relationKey: string, value: any) {
        let updatedRelationValues = { ...this.state.newRelationFormValues };
        if(!(relationKey in updatedRelationValues)) {
            updatedRelationValues[relationKey] = [];
        }
        updatedRelationValues[relationKey].push(value);
        this.setState({ newRelationFormValues: updatedRelationValues });
    }

    public renderTableRelationForm(relationKey: string, entityRelation: string): ReactNode {

        const initialDescriptionAutoSuggest = this.props.initialDescriptionAutoSuggest[relationKey] || [];

        return (
            <>
                {this.state.newRelationValues[relationKey].map((_values, index: number) => (
                    <React.Fragment key={index}>
                        <div className="col-md-12">
                            <div className="card shadow p-3">
                                <div className="card-body">
                                    <div className="row">
                                        <FormFields
                                            entity={entityRelation}
                                            formStructure={this.props.structure.formRelations[relationKey].formStructure}
                                            initialDescriptionAutoSuggest={initialDescriptionAutoSuggest[index] || []}
                                            changeField={(value, field, lang?, description?) => {
                                                this.onChangeRelationFormValue(relationKey, index, field, value, lang, description)
                                            }}
                                            addFieldError={(error, field) => {
                                                this.props.addFieldError(relationKey, index, field, error)
                                            }}
                                            fields={this.state.newRelationValues[relationKey][index]}
                                            disabled={this.props.disabled}
                                            renderRequiredErrors={this.props.renderRequiredErrors}
                                            renderErrors={true}
                                            isEditScreen={this.props.isEditScreen}
                                        />
                                        <FormTranslatableFields
                                            entity={this.props.entity}
                                            formStructure={this.props.structure.formRelations[relationKey].formStructure}
                                            initialDescriptionAutoSuggest={initialDescriptionAutoSuggest[index] || []}
                                            changeField={(value, field, lang) => {
                                                this.onChangeRelationFormValue(relationKey, index, field, value, lang)
                                            }}
                                            addFieldError={(error, field, lang) => {
                                                this.props.addFieldError(relationKey, index, field, error, lang)
                                            }}
                                            fields={this.state.newRelationValues[relationKey][index]}
                                            languages={this.props.languages}
                                            disabled={this.props.disabled}
                                            renderRequiredErrors={this.props.renderRequiredErrors}
                                            renderErrors={true}
                                            isEditScreen={this.props.isEditScreen}
                                        />
                                        <div className="col-md-12">
                                            <button type="button" className="btn btn-custom" style={{ backgroundColor: this.props.primaryColor }}
                                                onClick={() => this.removeNewRelationFormValue(relationKey, index, true)}
                                            >
                                                {I18nUtils.tr(TR_ADD)}
                                            </button>
                                            <button type="button" className="btn btn-custom" style={{ backgroundColor: this.props.primaryColor }}
                                                onClick={() => this.removeNewRelationFormValue(relationKey, index)}
                                            >
                                                {I18nUtils.tr(TR_BUTTON_DELETE)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ))
                }
            </>
        )
    }

    public render(): ReactNode {

        if (!this.state.canRender) {
            return <></>
        }

        const { formRelations } = this.props.structure;

        return (
            <div className="col-md-12" id="relations-form-tabs">
                <Tabs id="relations" activeKey={this.state.tab} onSelect={(tab) => this.setState({ tab })}>
                    {
                        this.state.relationsTabs.map((relationKey) => {
                            const relation = formRelations[relationKey];
                            if (relation.type !== 'FORM' && relation.type !== 'TABLE') {
                                return <></>;
                            }
                            if (relation.type === 'TABLE') {
                                return (
                                    <Tab key={relationKey} eventKey={relationKey} title={relation.pluralLabel}>
                                        <div className="row">
                                            {this.renderTable(relationKey, relation.values, relation.entityRelation, relation.entityRelationCamelcase, relation.canEditEntityRelation, relation.canShowEntityRelation)}
                                        </div>
                                    </Tab>
                                )
                            }
                            return (
                                <Tab key={relationKey} eventKey={relationKey} title={relation.pluralLabel}>
                                    <div className="row">
                                        {this.renderForm(relationKey, relation.values, relation.entityRelation, relation.canEditEntityRelation, relation.canShowEntityRelation)}
                                    </div>
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            </div>
        );

    }
}

export default FormRelationsForm;
