import cookie from "react-cookies";
import TaskRefreshToken, {TaskRefreshTokenResponse} from "../ws/login/TaskRefreshToken";

export default class AuthManager {

    private static AUTH_TOKEN = 'AUTH_TOKEN';
    private static REFRESH_TOKEN = 'REFRESH_TOKEN';
    private static USERNAME = 'USERNAME';

    private static authToken: string;
    private static refreshToken: string;
    private static _username: string;
    private static expires: Date;

    public static initialized(){
        this.authToken = cookie.load(this.AUTH_TOKEN);
        this.refreshToken = cookie.load(this.REFRESH_TOKEN);
        this._username = cookie.load(this.USERNAME);
    }

    public static getAuthToken(): string {
        return this.authToken;
    }

    public static getUsername(): string {
        return this._username;
    }

    public static login(authToken: string, refreshToken: string, username: string) {
        this.authToken = authToken;
        this.refreshToken = refreshToken;
        this._username = username;
        this.expires = new Date();
        let expires = this.expires.getHours();
        expires += 1;
        this.expires.setHours( expires );
        cookie.save(this.AUTH_TOKEN, this.authToken, { path: '/', expires: this.expires });
        cookie.save(this.REFRESH_TOKEN, this.refreshToken, { path: '/', expires: this.expires });
        cookie.save(this.USERNAME, this._username, { path: '/', expires: this.expires });
    }

    public static logout() {
        cookie.save(this.AUTH_TOKEN, '', { path: '/', expires: 0 });
        cookie.save(this.REFRESH_TOKEN, '', { path: '/', expires: 0 });
        cookie.save(this.USERNAME, '', { path: '/', expires: 0 });
        window.location.href = window.location.href;
    }

    public static isLogged(): boolean {
        return this.authToken !== null && this.authToken !== undefined && this.authToken !== '';
    }

    public static refreshTokenTask(): void {
        new TaskRefreshToken(this.refreshToken)
            .onSuccess((response: TaskRefreshTokenResponse) => {
                AuthManager.login(response.token, response.refresh_token, this._username)
            })
            .onFail(() => AuthManager.logout())
            .execute()
    }

};
