import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";

export default class TaskResetPasswordRequest extends BaseGenericRequest<{}> {

    private readonly email;

    public constructor(email: string) {
        super(METHOD.METHOD_POST, Urls.getUrlResetPasswordRequest());
        this.email = email;
    }

    protected getRequest(): {} {
        return {email: this.email};
    }
}