import {METHOD} from "@cuatroochenta/co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import { TaskLoginResponse } from './TaskLogin';

export default class TaskLoginGoogle extends BaseGenericRequest<{}, TaskLoginResponse> {

    private readonly tokenId;
    private readonly googleId;

    public constructor(tokenId: string, googleId: string) {
        super(METHOD.METHOD_POST, Urls.getUrlLoginGoogle());
        this.tokenId = tokenId;
        this.googleId = googleId;
    }

    protected getRequest(): {} {
        return { tokenId: this.tokenId, googleId: this.googleId };
    }
}
