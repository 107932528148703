import React, {ReactNode} from "react";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ACTIONS,
    TR_BUTTON_DELETE,
    TR_BUTTON_EDIT,
    TR_BUTTON_VIEW,
    TR_NO_RESULTS_FOUND
} from "../../../I18n/constants";
import '@fortawesome/fontawesome-free/css/all.css'
import {Link} from "react-router-dom";
import {generateRoute} from "../../../utils/Router";
import {ROUTE_ENTITY_EDIT, ROUTE_ENTITY_VIEW} from "../../../routing/Routes";
import ShowField from "../../../components/field/ShowField";
import {FieldList, StructureEntity} from "../../../model/StructureEntity";
import {ResultsListField} from "../../../model/ResultsList";
import EntityActions from "../EntityActions";
import {CofingColumns} from "../../../model/FiltersState";

interface TableProps {
    orderBy: string,
    orderAsc: boolean,
    changeOrder: (orderBy: string, orderAsc: boolean) => void,
    structure: StructureEntity,
    results: ResultsListField[],
    primaryColor: string,
    iconsColor: string,
    entity: string,
    extendedResults: boolean,
    columns: CofingColumns,
    galleryView: boolean
}

interface StructureList {
    [dynamic: string]: FieldList
}

class Table extends React.Component<TableProps> {

    public getStructureList(): StructureList {
        const structureList = {} as StructureList;
        if (this.props.extendedResults) {
            Object.keys(this.props.structure.list).map((field) => {
                if (this.props.structure.list[field].listableInExtended) {
                    structureList[field] = this.props.structure.list[field];
                }
            });
        }
        else {
            Object.keys(this.props.columns).map((field) => {
                if (this.props.columns[field].showByDefault) {
                    structureList[field] = this.props.structure.list[field];
                }
            });
        }
        return structureList;
    }

    public renderHeader(structureList: StructureList): ReactNode {
        return (
            <thead className="text-primary">
                <tr style={{color: this.props.primaryColor}}>
                    {
                        Object.keys(structureList).map((field) => {
                            const {orderBy} = structureList[field];
                            return (
                                <th key={field} className={structureList[field].colClassesTable}>
                                    <span style={{cursor: orderBy === '' ? 'default' : 'pointer'}} onClick={() => this.changeOrder(orderBy)}>
                                        {structureList[field].label}
                                        {
                                            this.props.orderBy === orderBy ?
                                                <i className={`fa fa-caret-${this.props.orderAsc ? 'down' : 'up'}`}/>
                                                : ''
                                        }
                                    </span>
                                </th>)
                        })
                    }
                    { this.headerActions() }
                </tr>
            </thead>
        );
    }

    public headerActions(): ReactNode {
        return <th className="list-actions-column">{I18nUtils.tr(TR_ACTIONS)}</th>;
    }

    public changeOrder(field) {
        if (field !== '') {
            this.props.changeOrder(field, this.props.orderBy === field ? !this.props.orderAsc : true);
        }
    }

    public renderContentTable(structureList: StructureList): ReactNode {
        const data = this.props.results;
        return (
            <tbody>
            {
                Object.keys(data).map((indexData) => {
                    return (
                        <tr key={indexData}>
                            {
                                Object.keys(structureList).map((field) => {
                                    const fieldValue = data[indexData][field] || data[indexData][field] === 0 ? data[indexData][field] : '';
                                    return (
                                        <td key={field + indexData} className={structureList[field].colClassesTable + ' ' + 'column-field-' + structureList[field].type.toLowerCase()}>
                                            { ShowField(fieldValue, structureList[field].type, structureList[field].fieldsToShow) }
                                        </td>
                                    )
                                })
                            }
                            {this.renderActionsColumn(data[indexData])}
                        </tr>
                    )
                })
            }

            </tbody>
        );
    }

    public renderActionsColumn(row: any): ReactNode {

        const id = row.oid;
        const color = this.props.iconsColor;

        return (
            <td className='list-actions-column'>
                {
                    row.__permissions.show &&
                        <Link to={generateRoute(ROUTE_ENTITY_VIEW, {entity: this.props.entity.toLowerCase(), id})} title={I18nUtils.tr(TR_BUTTON_VIEW)}>
                            <i className='fa fa-eye' style={{color}}/>
                        </Link>
                }
                {
                    row.__permissions.edit &&
                        <Link to={generateRoute(ROUTE_ENTITY_EDIT, {entity: this.props.entity.toLowerCase(), id})} title={I18nUtils.tr(TR_BUTTON_EDIT)}>
                            <i className='fa fa-edit' style={{color}}/>
                        </Link>
                }
                {
                    row.__permissions.delete &&
                        <i className='fa fa-trash' style={{color}} onClick={() => EntityActions.showDeleteModal.asConnectedAction(this.props.entity, id)} title={I18nUtils.tr(TR_BUTTON_DELETE)}/>
                }

            </td>
        );

    }

    public render(): ReactNode {

        if (!this.props.structure.list || !this.props.results) {
            return <></>
        }

        if (Object.keys(this.props.results).length > 0) {
            const structureList = this.getStructureList();
            return (
                <div className={`table-responsive ${this.props.galleryView ? 'd-none' : ''}`}>
                    <table className="table">
                        {this.renderHeader(structureList)}
                        {this.renderContentTable(structureList)}
                    </table>
                </div>
            )
        }

        return <span>{I18nUtils.tr(TR_NO_RESULTS_FOUND)}</span>


    }
}

export default Table;
